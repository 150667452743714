/* eslint-disable */

import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button, CircularProgress } from "@mui/material";
import RegistrationForm from "../components/PreScreening/RegistrationForm";
import RequiredDocument from "../components/PreScreening/RequiredDocument";
import ModalPreScreening from "../components/PreScreening/Modal/ModalPreScreening";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import {
  AdditionalInfoFormAPI,
  SaleForceUploadFile,
  SharePointFolder,
} from "../services/apigateway";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
// import helper from "../services/helper";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      style={{
        color: completed || active ? "#fff" : "black",
        backgroundColor: "white",
        border: "1px solid #b5b5b5",
      }}
    >
      {completed ? <CheckIcon /> : <>{props.icon}</>}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Required Document"];

export default function PreScreeningScreen() {
  const { id } = useParams();

  const [activeStep, setActiveStep] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [showTableFirstYear, setShowTableFirstYear] = React.useState(false);
  const [showTableSecondYear, setShowTableSecondYear] = React.useState(false);
  const [showTableThirdYear, setShowTableThirdYear] = React.useState(false);
  const [showTableCOI, setShowTableCOI] = React.useState(false);
  const [showTableMOA, setShowTableMOA] = React.useState(false);
  const [showTableVAT, setShowTableVAT] = React.useState(false);
  const [showTableShareholder, setShowTableShareholder] = React.useState(false);
  const [showTableMoE, setShowTableMoE] = React.useState(false);
  const [showTableOther, setShowTableOther] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const [requestId, setRequestId] = React.useState("");
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [objAdditionalForm, setObjAdditionalForm] = React.useState({});
  const [selectedOther, setSelectedOther] = React.useState([]);
  const navigate = useNavigate();

  const [pathsSharePoint, setPathsSharePoint] = React.useState([]);
  React.useEffect(() => {
    const element = document.querySelector(".MuiStepLabel-iconContainer");
    if (element) {
      const elementText = document.querySelector(".css-1am1g1a");
      if (elementText) {
        elementText.textContent = "3";
      }
    }
    // Call your RequestForm function here
    const fetchData = async () => {
      try {
        setLoading(true);
        const additionalInfo = await AdditionalInfoFormAPI.getRequestForm(id);
        setObjAdditionalForm(additionalInfo);

        // const userInfo = helper.Auth.getTokenData()
        if (additionalInfo.CustomerId) {
          // const result = await SharePointFolder.createMultipleFoldersByAccount({
          //   accountId: additionalInfo.CustomerId,
          // });
          // if (result.status === "success") {
          const response = await SharePointFolder.getPathByAccount(
            additionalInfo.CustomerId
          );
          const filesSharePoint = response.responseData;

          const fin = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Financial"
          );
          const coi = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_COI"
          );
          const moa = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_MOA"
          );
          const shr = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_List_Sharehold"
          );
          const vat = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_VAT_Reg"
          );
          const moe = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Reg_by_MoE"
          );
          const oth = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Others"
          );

          setPathsSharePoint({
            Account_Common_CTR_Financial: fin.Path || "",
            Account_Common_CTR_COI: coi.Path || "",
            Account_Common_CTR_MOA: moa.Path || "",
            Account_Common_CTR_List_Sharehold: shr.Path || "",
            Account_Common_CTR_VAT_Reg: vat.Path || "",
            Account_Common_CTR_Reg_by_MoE: moe.Path || "",
            Account_Common_CTR_Others: oth.Path || "",
          });
          // } else {
          //   console.error("Error create folders:", result);
          // }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const uploadFileToSaleForceAttachment = async (file) => {
    try {
      let CustomerId = objAdditionalForm.CustomerId;
      const uploadResponse = await SaleForceUploadFile.uploadFile(CustomerId, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToFinancial = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_Financial;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadFileToCOI = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_COI;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadFileToMOA = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_MOA;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadFileToVAT = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_VAT_Reg;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadFileToShareholders = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_List_Sharehold;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadToFileToMoE = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_Reg_by_MoE;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadToFileToOther = async (file) => {
    try {
      if (file) {
        const namefolder = pathsSharePoint.Account_Common_CTR_Others;
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload response:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadFiles = async () => {
    //e.preventDefault();
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FinancialSecond = document.getElementById("FinancialSecond");
      const FinancialThird = document.getElementById("FinancialThird");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");
      const FileMoE = document.getElementById("FileMoE");
      const FileOther = document.getElementById("FileOther2");

      const selectedFilesFirst = FinancialFirst.files[0];
      const selectedFilesSecond = FinancialSecond.files[0];
      const selectedFilesThird = FinancialThird.files[0];
      const selectedFilesCOI = FileCOI.files[0];
      const selectedFilesMOA = FileMOA.files[0];
      const selectedFilesVAT = FileVAT.files[0];
      const selectedFileMoE = FileMoE ? FileMoE.files[0] : [];
      const selectedFileShareholder = FileShareholder.files[0];
      const selectedFileOther = FileOther.files;

      // for (const file of selectedFilesFirst) {
      //   await uploadFileToFinancial(file);
      // }
      await uploadFileToFinancial(selectedFilesFirst);

      // for (const file of selectedFilesSecond) {
      //   await uploadFileToFinancial(file);
      // }
      await uploadFileToFinancial(selectedFilesSecond);

      // for (const file of selectedFilesThird) {
      //   await uploadFileToFinancial(file);
      // }
      await uploadFileToFinancial(selectedFilesThird);

      // for (const file of selectedFilesCOI) {
      //   await uploadFileToCOI(file);
      // }

      await uploadFileToCOI(selectedFilesCOI);

      // for (const file of selectedFilesMOA) {
      //   await uploadFileToMOA(file);
      // }

      await uploadFileToMOA(selectedFilesMOA);

      // for (const file of selectedFileShareholder) {
      //   await uploadFileToShareholders(file);
      // }
      await uploadFileToShareholders(selectedFileShareholder);

      // for (const file of selectedFilesVAT) {
      //   await uploadFileToVAT(file);
      // }
      await uploadFileToVAT(selectedFilesVAT);

      // for (const file of selectedFileMoE) {
      //   await uploadToFileToMoE(file);
      // }
      await uploadToFileToMoE(selectedFileMoE);

      for (const file of selectedFileOther) {
        await uploadToFileToOther(file);
      }

      if (FinancialFirst.files[0]) {
        let typeFilesFS1 = FinancialFirst.files[0].name.split(".").pop();
        const selectedFilesFS1_SF = new File(
          [FinancialFirst.files[0]],
          "FS1" + "." + typeFilesFS1,
          { type: FinancialFirst.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS1_SF);
      }

      if (selectedFilesSecond.files[0]) {
        let typeFilesFS2 = selectedFilesSecond.files[0].name.split(".").pop();
        const selectedFilesFS2_SF = new File(
          [selectedFilesSecond.files[0]],
          "FS2" + "." + typeFilesFS2,
          { type: selectedFilesSecond.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS2_SF);
      }

      if (selectedFilesThird.files[0]) {
        let typeFilesFS3 = selectedFilesThird.files[0].name.split(".").pop();
        const selectedFilesFS3_SF = new File(
          [selectedFilesThird.files[0]],
          "FS3" + "." + typeFilesFS3,
          { type: selectedFilesThird.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS3_SF);
      }

      if (FileCOI.files[0]) {
        let typeFilesCOI = FileCOI.files[0].name.split(".").pop();
        const selectedFilesCOI_SF = new File(
          [FileCOI.files[0]],
          "COI" + "." + typeFilesCOI,
          { type: FileCOI.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesCOI_SF);
      }

      if (FileMOA.files[0]) {
        let typeFilesMOA = FileMOA.files[0].name.split(".").pop();
        const selectedFilesMOA_SF = new File(
          [FileMOA.files[0]],
          "MOA" + "." + typeFilesMOA,
          { type: FileMOA.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesMOA_SF);
      }

      if (FileVAT.files[0]) {
        let typeFilesVAT = FileVAT.files[0].name.split(".").pop();

        const selectedFilesVAT_SF = new File(
          [FileVAT.files[0]],
          "VAT" + "." + typeFilesVAT,
          { type: FileVAT.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesVAT_SF);
      }

      if (FileMoE) {
        if (FileMoE.files[0]) {
          let typeFilesMoE = FileMoE.files[0].name.split(".").pop();
          const selectedFileMoE_SF = new File(
            [FileMoE.files[0]],
            "MOE" + "." + typeFilesMoE,
            { type: FileMoE.files[0].type }
          );
          await uploadFileToSaleForceAttachment(selectedFileMoE_SF);
        }
      }

      // Resetting input values after successful upload
      FinancialFirst.value = "";
      FinancialSecond.value = "";
      FinancialThird.value = "";
      FileCOI.value = "";
      FileMOA.value = "";
      FileVAT.value = "";
      FileShareholder.value = "";
      if (FileMoE) {
        FileMoE.value = "";
      }
      FileOther.value = "";

      setShowTableFirstYear(false);
      setShowTableSecondYear(false);
      setShowTableThirdYear(false);
      setShowTableCOI(false);
      setShowTableMOA(false);
      setShowTableVAT(false);
      setShowTableShareholder(false);
      setShowTableMoE(false);
      setShowTableOther(false);

      // } else {
      //   console.error('No file selected.');
      // }
    } catch (error) {
      console.error("Error in handling file upload:", error);
    }
  };

  const updateFiles = async () => {
    //e.preventDefault();
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FinancialSecond = document.getElementById("FinancialSecond");
      const FinancialThird = document.getElementById("FinancialThird");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");
      const FileMoE = document.getElementById("FileMoE");
      const FileOther = document.getElementById("FileOther2");

      // if (
      //   // FinancialFirst.files.length > 0 ||
      //   // FinancialSecond.files.length > 0 ||
      //   // FinancialThird.files.length > 0 ||
      //   // FileCOI.files.length > 0 ||
      //   // FileMOA.files.length > 0 ||
      //   // FileVAT.files.length > 0 ||
      //   // FileShareholder.files.length > 0 ||
      //   // FileMoE.files.length > 0 ||
      //   // FileOther.files.length > 0

      //   FinancialFirst.files.length > 0 &&
      //   // FinancialSecond.files.length > 0 &&
      //   // FinancialThird.files.length > 0 &&
      //   FileCOI.files.length > 0 &&
      //   FileMOA.files.length > 0 &&
      //   FileVAT.files.length > 0 &&
      //   FileShareholder.files.length > 0
      //   // &&
      //   // FileMoE.files.length > 0
      //   // ||
      //   // FileOther.files.length > 0
      // ) {
      const selectedFilesFirst = FinancialFirst.files[0];
      const selectedFilesSecond = FinancialSecond.files[0];
      const selectedFilesThird = FinancialThird.files[0];
      const selectedFilesCOI = FileCOI.files[0];
      const selectedFilesMOA = FileMOA.files[0];
      const selectedFilesVAT = FileVAT.files[0];
      const selectedFileShareholder = FileShareholder.files[0];
      const selectedFileMoE = FileMoE ? FileMoE.files[0] : [];
      const selectedFileOther = FileOther.files;

      // for (const file of selectedFilesFirst) {
      //   await uploadFileToFinancial(file);
      // }
      await uploadFileToFinancial(selectedFilesFirst);

      // for (const file of selectedFilesSecond) {
      //   await uploadFileToFinancial(file);
      // }
      await uploadFileToFinancial(selectedFilesSecond);

      // for (const file of selectedFilesThird) {
      //   await uploadFileToFinancial(file);
      // }
      await uploadFileToFinancial(selectedFilesThird);

      // for (const file of selectedFilesCOI) {
      //   await uploadFileToCOI(file);
      // }

      await uploadFileToCOI(selectedFilesCOI);

      // for (const file of selectedFilesMOA) {
      //   await uploadFileToMOA(file);
      // }

      await uploadFileToMOA(selectedFilesMOA);

      // for (const file of selectedFileShareholder) {
      //   await uploadFileToShareholders(file);
      // }
      await uploadFileToShareholders(selectedFileShareholder);

      // for (const file of selectedFilesVAT) {
      //   await uploadFileToVAT(file);
      // }
      await uploadFileToVAT(selectedFilesVAT);

      // for (const file of selectedFileMoE) {
      //   await uploadToFileToMoE(file);
      // }
      await uploadToFileToMoE(selectedFileMoE);

      for (const file of selectedFileOther) {
        await uploadToFileToOther(file);
      }

      if (FinancialFirst.files[0]) {
        let typeFilesFS1 = FinancialFirst.files[0].name.split(".").pop();
        const selectedFilesFS1_SF = new File(
          [FinancialFirst.files[0]],
          "FS1" + "." + typeFilesFS1,
          { type: FinancialFirst.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS1_SF);
      }

      if (selectedFilesSecond.files[0]) {
        let typeFilesFS2 = selectedFilesSecond.files[0].name.split(".").pop();
        const selectedFilesFS2_SF = new File(
          [selectedFilesSecond.files[0]],
          "FS2" + "." + typeFilesFS2,
          { type: selectedFilesSecond.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS2_SF);
      }

      if (selectedFilesThird.files[0]) {
        let typeFilesFS3 = selectedFilesThird.files[0].name.split(".").pop();
        const selectedFilesFS3_SF = new File(
          [selectedFilesThird.files[0]],
          "FS3" + "." + typeFilesFS3,
          { type: selectedFilesThird.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS3_SF);
      }

      if (FileCOI.files[0]) {
        let typeFilesCOI = FileCOI.files[0].name.split(".").pop();
        const selectedFilesCOI_SF = new File(
          [FileCOI.files[0]],
          "COI" + "." + typeFilesCOI,
          { type: FileCOI.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesCOI_SF);
      }

      if (FileMOA.files[0]) {
        let typeFilesMOA = FileMOA.files[0].name.split(".").pop();
        const selectedFilesMOA_SF = new File(
          [FileMOA.files[0]],
          "MOA" + "." + typeFilesMOA,
          { type: FileMOA.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesMOA_SF);
      }

      if (FileVAT.files[0]) {
        let typeFilesVAT = FileVAT.files[0].name.split(".").pop();

        const selectedFilesVAT_SF = new File(
          [FileVAT.files[0]],
          "VAT" + "." + typeFilesVAT,
          { type: FileVAT.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesVAT_SF);
      }

      if (FileMoE) {
        if (FileMoE.files[0]) {
          let typeFilesMoE = FileMoE.files[0].name.split(".").pop();
          const selectedFileMoE_SF = new File(
            [FileMoE.files[0]],
            "MOE" + "." + typeFilesMoE,
            { type: FileMoE.files[0].type }
          );
          await uploadFileToSaleForceAttachment(selectedFileMoE_SF);
        }
      }

      // Resetting input values after successful upload
      FinancialFirst.value = "";
      FinancialSecond.value = "";
      FinancialThird.value = "";
      FileCOI.value = "";
      FileMOA.value = "";
      FileVAT.value = "";
      FileShareholder.value = "";
      if (FileMoE) {
        FileMoE.value = "";
      }
      FileOther.value = "";

      setShowTableFirstYear(false);
      setShowTableSecondYear(false);
      setShowTableThirdYear(false);
      setShowTableCOI(false);
      setShowTableMOA(false);
      setShowTableVAT(false);
      setShowTableShareholder(false);
      setShowTableMoE(false);
      setShowTableOther(false);

      // } else {
      //   console.error('No file selected.');
      // }
    } catch (error) {
      console.error("Error in handling file upload:", error);
    }
  };

  const renderFormPreScreening = (page) => {
    switch (page) {
      // case 0:
      //   return (
      // <RegistrationForm
      //   formData={(data) => submitRequestFormByIds(data)}
      //   formDataDraft={(data) => updateRequestFormByIds(data)}
      //   formDataDraftNext={(data) => updateRequestFormByIdAndNextStep(data)}
      //   formId={id}
      // />
      //   );
      case 0:
        return (
          <RequiredDocument
            formData={(data) => submitRequiredDocument(data)}
            formDataDraft={(data) => updateRequiredDocument(data)}
            formId={id}
            showTableFirstYear={showTableFirstYear}
            setShowTableFirstYear={setShowTableFirstYear}
            showTableSecondYear={showTableSecondYear}
            setShowTableSecondYear={setShowTableSecondYear}
            showTableThirdYear={showTableThirdYear}
            setShowTableThirdYear={setShowTableThirdYear}
            showTableCOI={showTableCOI}
            setShowTableCOI={setShowTableCOI}
            showTableMOA={showTableMOA}
            setShowTableMOA={setShowTableMOA}
            showTableVAT={showTableVAT}
            setShowTableVAT={setShowTableVAT}
            showTableShareholder={showTableShareholder}
            setShowTableShareholder={setShowTableShareholder}
            showTableMoE={showTableMoE}
            setShowTableMoE={setShowTableMoE}
            showTableOther={showTableOther}
            setShowTableOther={setShowTableOther}
            setObjAdditionalForm={setObjAdditionalForm}
            objAdditionalForm={objAdditionalForm}
            setSelectedOther={setSelectedOther}
            selectedOther={selectedOther}
          />
        );
      default:
        return <></>;
    }
  };

  const updateRequestFormByIds = async (data, nextStep) => {
    let dataUpdate = data;
    console.log(dataUpdate);
    Swal.fire({
      title: "Do you want to save form?",
      icon: "question",
      position: "center",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      cancelButtonColor: "red",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await AdditionalInfoFormAPI.updateRequestForm(dataUpdate);
        console.log("updateRequestForm :: ", res);
        setLoading(false);
        if (res.error_description) {
          Swal.fire({
            title: res.error_description,
            icon: "error",
            // showConfirmButton: false,
            // timer: 1200,
          });
        } else {
          Swal.fire({
            title: "Saved!",
            icon: "success",
            showConfirmButton: false,
            timer: 1200,
          });
        }
      }
      setLoading(false);
    });
    document.getElementById("check-form-data-register-save-draft").click();
  };

  const updateRequestFormByIdAndNextStep = async (data) => {
    let dataUpdate = data;
    Swal.fire({
      title: "Do you want to submit form?",
      icon: "question",
      position: "center",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      cancelButtonColor: "red",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await AdditionalInfoFormAPI.updateRequestForm(dataUpdate);
        console.log("updateRequestForm :: ", res);
        setLoading(false);
        if (res.error_description) {
          Swal.fire({
            title: res.error_description,
            icon: "error",
            // showConfirmButton: false,
            // timer: 1200,
          });
        } else {
          setActiveStep(activeStep + 1);
          Swal.fire({
            title: "Saved!",
            icon: "success",
            showConfirmButton: false,
            timer: 1200,
          });
        }
      }
      setLoading(false);
    });
    document.getElementById("check-form-data-register-save-draft-next").click();
  };

  const submitRequestFormByIds = async (data) => {
    let dataFinal = data;
    if (dataFinal) {
      Swal.fire({
        title: "Do you want to submit form?",
        icon: "question",
        position: "center",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        cancelButtonColor: "red",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const res = await AdditionalInfoFormAPI.submitRequestForm(dataFinal);
          console.log("submitRequestForm :: ", res);
          setLoading(false);
          if (res) {
            if (res.error_description) {
              Swal.fire({
                title: res.error_description,
                icon: "error",
                // showConfirmButton: false,
                // timer: 1200,
              });
            } else {
              // setRequestId(res.RequestFormHeaderId);
              setRegistrationNumber(res.RegistrationNumber);
              // setOpenModal(true);
              setActiveStep(activeStep + 1);
            }
          }
        }
        setLoading(false);
      });
    }
    document.getElementById("check-form-data-register").click();
  };

  let formSubmitted = false;
  const submitRequiredDocument = async (data) => {
    let dataFinal = data;

    if (dataFinal && !formSubmitted) {
      setLoading(true);
      const res = await AdditionalInfoFormAPI.submitRequestForm(dataFinal);
      console.log("submitRequiredDocument :: ", res);
      setLoading(false);
      if (res) {
        if (res.error_description) {
          Swal.fire({
            title: res.error_description,
            icon: "error",
            // showConfirmButton: false,
            // timer: 1200,
          });
        } else {
          // setRequestId(res.RequestFormHeaderId);
          setRegistrationNumber(res.RegistrationNumber);
          setOpenModal(true);
          formSubmitted = true; // Set the flag to true after successful submission
        }
      }
      setLoading(false);
    }

    if (!formSubmitted) {
      document.getElementById("check-form-data-register").click();
    }
  };

  const handleUploadFiles = async function (e) {
    // e.preventDefault();
    // if (checkSizeFilesValidate()) {
    //   Swal.fire({
    //     title: "Warning",
    //     text: " All files size uploaded not over 20MB.",
    //     icon: "warning",
    //     position: "center",
    //     showDenyButton: false,
    //     showCancelButton: false,
    //     confirmButtonText: "OK",
    //   });
    // } else {
    try {
      const allFilesExist = await checkFilesExist();
      if (!allFilesExist) {
        return;
      }
      const FinancialFirst = document.getElementById("FinancialFirst");
      // const FinancialSecond = document.getElementById('FinancialSecond');
      // const FinancialThird = document.getElementById('FinancialThird');
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");

      console.log(objAdditionalForm.RequestItems[0]?.Product?.toLowerCase());
      console.log(objAdditionalForm.RequestItems[0]?.Type);
      console.log(objAdditionalForm.Documents);

      // const FileMoE = document.getElementById('FileMoE');
      // const FileOther = document.getElementById('FileOther2');
      // Check if any files are selected for upload
      if (
        // (FinancialFirst.files.length > 0 ||
        //   objAdditionalForm.Documents?.FinancialStatement.FirstYear) &&
        // // FinancialSecond.files.length > 0 &&
        // // FinancialThird.files.length > 0 &&
        // (FileCOI.files.length > 0 ||
        //   objAdditionalForm.Documents?.CertificateOfIncorporation) &&
        // (FileMOA.files.length > 0 ||
        //   objAdditionalForm.Documents?.MemorandumOfAssociation) &&
        // (FileVAT.files.length > 0 ||
        //   objAdditionalForm.Documents?.CertificateOfVATRegistration) &&
        // (FileShareholder.files.length > 0 ||
        //   objAdditionalForm.Documents?.ListOfShareholders)
        (!(
          objAdditionalForm.RequestItems[0]?.Type === "Customer" ||
          (objAdditionalForm.RequestItems[0]?.Type === "Supplier" &&
            objAdditionalForm.RequestItems[0]?.Product?.toLowerCase() ===
              "crude")
        ) ||
          objAdditionalForm.Documents?.FinancialStatement.FirstYear.Filename) &&
        objAdditionalForm.Documents?.CertificateOfIncorporation.Filename &&
        objAdditionalForm.Documents?.MemorandumOfAssociation.Filename &&
        (!(
          objAdditionalForm.RequestItems[0]?.Type === "Customer" ||
          (objAdditionalForm.RequestItems[0]?.Type === "Supplier" &&
            objAdditionalForm.RequestItems[0]?.Product?.toLowerCase() ===
              "crude")
        ) ||
          objAdditionalForm.Documents?.CertificateOfVATRegistration.Filename) &&
        objAdditionalForm.Documents?.ListOfShareholders.Filename
      ) {
        // If files are selected, initiate the file upload process and handle submission
        Swal.fire({
          title: "Do you want to submit form?",
          icon: "question",
          position: "center",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          cancelButtonColor: "red",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            await uploadFiles();
            setLoading(false);
            document.getElementById("check-form-data-register").click();
          }
        });
      } else {
        Swal.fire({
          title: "Please attach required documents",
          icon: "error",
          position: "center",
        });
      }
    } catch (error) {
      console.error("Error in handling form submission:", error);
    }
    // }
  };

  let formUpdateFiles = false;
  const updateRequiredDocument = async (data) => {
    //setLoading(true);
    let dataUpdate = data;

    if (dataUpdate && !formUpdateFiles) {
      const res = await AdditionalInfoFormAPI.updateRequestForm(dataUpdate);
      if (res) {
        console.log("updateRequiredDocument :: ", res);
        setLoading(false);
        //setOpenModal(true);
        formUpdateFiles = true; // Set the flag to true after successful submission
      }
      //setLoading(false);
    }

    if (!formUpdateFiles) {
      document.getElementById("check-form-data-register-save-draft").click();
    }
  };

  const checkSizeFilesValidate = () => {
    let maxSize = 20;
    let mbConvert = 1048576;
    let arrayFileBuffer = [];
    const FinancialFirst = document.getElementById("FinancialFirst").files;
    const FinancialSecond = document.getElementById("FinancialSecond").files;
    const FinancialThird = document.getElementById("FinancialThird").files;
    const fileSelectedCOI = document.getElementById("FileCOI").files;
    const fileSelectedMOA = document.getElementById("FileMOA").files;
    const fileSelectedVAT = document.getElementById("FileVAT").files;
    const fileSelectedShareholders =
      document.getElementById("FileShareholder").files;
    const fileInputRefMoE = document.getElementById("FileMoE").files;
    const attachmentsOther1 = document.getElementById("FileOther1").files;
    const attachmentsOther2 = document.getElementById("FileOther2").files;

    // check all files upload
    for (let i = 0; i < FinancialFirst.length; i++) {
      arrayFileBuffer.push(FinancialFirst[i]);
    }
    for (let i = 0; i < FinancialSecond.length; i++) {
      arrayFileBuffer.push(FinancialSecond[i]);
    }
    for (let i = 0; i < FinancialThird.length; i++) {
      arrayFileBuffer.push(FinancialThird[i]);
    }
    for (let i = 0; i < fileSelectedCOI.length; i++) {
      arrayFileBuffer.push(fileSelectedCOI[i]);
    }
    for (let i = 0; i < fileSelectedMOA.length; i++) {
      arrayFileBuffer.push(fileSelectedMOA[i]);
    }
    for (let i = 0; i < fileSelectedVAT.length; i++) {
      arrayFileBuffer.push(fileSelectedVAT[i]);
    }
    for (let i = 0; i < fileSelectedShareholders.length; i++) {
      arrayFileBuffer.push(fileSelectedShareholders[i]);
    }
    for (let i = 0; i < fileInputRefMoE.length; i++) {
      arrayFileBuffer.push(fileInputRefMoE[i]);
    }
    for (let i = 0; i < attachmentsOther1.length; i++) {
      arrayFileBuffer.push(attachmentsOther1[i]);
    }
    for (let i = 0; i < attachmentsOther2.length; i++) {
      arrayFileBuffer.push(attachmentsOther2[i]);
    }

    let sizeAllFiles = 0;
    arrayFileBuffer.forEach((item) => {
      sizeAllFiles += item.size;
    });

    return sizeAllFiles / mbConvert > maxSize;
  };

  const checkFilesExist = async () => {
    const FinancialFirst = document.getElementById("FinancialFirst");
    const FinancialSecond = document.getElementById("FinancialSecond");
    const FinancialThird = document.getElementById("FinancialThird");
    const FileCOI = document.getElementById("FileCOI");
    const FileMOA = document.getElementById("FileMOA");
    const FileVAT = document.getElementById("FileVAT");
    const FileShareholder = document.getElementById("FileShareholder");
    const FileMoE = document.getElementById("FileMoE");
    const FileOther = document.getElementById("FileOther2");

    // console.log('FinancialFirst >> ', FinancialFirst);
    //   console.log('FinancialSecond >> ', FinancialSecond);
    //   console.log('FinancialThird >> ', FinancialThird);
    //   console.log('FileCOI >> ', FileCOI);
    //   console.log('FileMOA >> ', FileMOA);
    //   console.log('FileVAT >> ', FileVAT);
    //   console.log('FileShareholder >> ', FileShareholder);
    //   console.log('FileMoE >> ', FileMoE);
    //   console.log('FileOther >> ', FileOther);

    let isFinancialFirstExists = true;
    let isFinancialSecondExists = true;
    let isFinancialThirdExists = true;
    let isFileCOIExists = true;
    let isFileMOAExists = true;
    let isFileVATExists = true;
    let isFileShareholderExists = true;
    let isFileMoEExists = true;
    let isFileOtherExists = true;

    const filesNotExist = [];
    if (FinancialFirst.files.length > 0) {
      isFinancialFirstExists = await isFileExists(FinancialFirst.files[0]);
      if (!isFinancialFirstExists) {
        filesNotExist.push(FinancialFirst.files[0].name);
      }
    }
    if (FinancialSecond.files.length > 0) {
      isFinancialSecondExists = await isFileExists(FinancialSecond.files[0]);
      if (!isFinancialSecondExists) {
        filesNotExist.push(FinancialSecond.files[0].name);
      }
    }
    if (FinancialThird.files.length > 0) {
      isFinancialThirdExists = await isFileExists(FinancialThird.files[0]);
      if (!isFinancialThirdExists) {
        filesNotExist.push(FinancialThird.files[0].name);
      }
    }
    if (FileCOI.files.length > 0) {
      isFileCOIExists = await isFileExists(FileCOI.files[0]);
      if (!isFileCOIExists) {
        filesNotExist.push(FileCOI.files[0].name);
      }
    }
    if (FileMOA.files.length > 0) {
      isFileMOAExists = await isFileExists(FileMOA.files[0]);
      if (!isFileMOAExists) {
        filesNotExist.push(FileMOA.files[0].name);
      }
    }
    if (FileVAT.files.length > 0) {
      isFileVATExists = await isFileExists(FileVAT.files[0]);
      if (!isFileVATExists) {
        filesNotExist.push(FileVAT.files[0].name);
      }
    }
    if (FileShareholder.files.length > 0) {
      isFileShareholderExists = await isFileExists(FileShareholder.files[0]);
      if (!isFileShareholderExists) {
        filesNotExist.push(FileShareholder.files[0].name);
      }
    }
    if (FileMoE) {
      if (FileMoE.files.length > 0) {
        isFileMoEExists = await isFileExists(FileMoE.files[0]);
        if (!isFileMoEExists) {
          filesNotExist.push(FileMoE.files[0].name);
        }
      }
    }

    if (FileOther.files.length > 0) {
      for (let i = 0; i < FileOther.files.length; i++) {
        isFileOtherExists &= await isFileExists(FileOther.files[i]);
        if (!isFileOtherExists) {
          filesNotExist.push(FileOther.files[i].name);
        }
      }
    }

    if (filesNotExist.length > 0) {
      Swal.fire({
        title: "Warning",
        text: "Files are not exists: " + filesNotExist.join(", "),
        icon: "warning",
        position: "center",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    }

    return !(filesNotExist.length > 0);
  };

  const isFileExists = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("abort", function (event) {
        resolve(false);
      });
      reader.addEventListener("error", function (event) {
        resolve(false);
      });
      reader.addEventListener("loadend", function (event) {
        resolve(true);
      });
      reader.readAsDataURL(file);
    });
  };

  const handleUpdateFiles = async function (e) {
    // if (checkSizeFilesValidate()) {
    //   Swal.fire({
    //     title: "Warning",
    //     text: " All files size uploaded not over 20MB.",
    //     icon: "warning",
    //     position: "center",
    //     showDenyButton: false,
    //     showCancelButton: false,
    //     confirmButtonText: "OK",
    //   });
    // } else {
    try {
      const allFilesExist = await checkFilesExist();
      if (!allFilesExist) {
        return;
      }

      const FinancialFirst = document.getElementById("FinancialFirst");
      // const FinancialSecond = document.getElementById('FinancialSecond');
      // const FinancialThird = document.getElementById('FinancialThird');
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");
      // const FileMoE = document.getElementById('FileMoE');
      // const FileOther = document.getElementById('FileOther2');

      // Check if any files are selected for upload
      // if (
      //   (FinancialFirst.files.length > 0 ||
      //     objAdditionalForm.Documents?.FinancialStatement.FirstYear) &&
      //   // FinancialSecond.files.length > 0 &&
      //   // FinancialThird.files.length > 0 &&
      //   (FileCOI.files.length > 0 ||
      //     objAdditionalForm.Documents?.CertificateOfIncorporation) &&
      //   (FileMOA.files.length > 0 ||
      //     objAdditionalForm.Documents?.MemorandumOfAssociation) &&
      //   (FileVAT.files.length > 0 ||
      //     objAdditionalForm.Documents?.CertificateOfVATRegistration) &&
      //   (FileShareholder.files.length > 0 ||
      //     objAdditionalForm.Documents?.ListOfShareholders)
      // &&
      // FileMoE.files.length > 0
      // ||
      // FileOther.files.length > 0
      // ) {
      // If files are selected, initiate the file upload process and handle submission
      setLoading(true);
      Swal.fire({
        title: "Do you want to save draft form?",
        icon: "question",
        position: "center",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: "Cancel",
        cancelButtonColor: "red",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await updateFiles();
          document
            .getElementById("check-form-data-register-save-draft")
            .click();
          setLoading(false);

          setSelectedOther([]);
          Swal.fire({
            title: "Saved!",
            icon: "success",
            showConfirmButton: false,
            timer: 1200,
          });
        }
        setLoading(false);
      });
      // }
    } catch (error) {
      console.error("Error in handling form submission:", error);
    }
    // }
  };

  return (
    <>
      <Stack sx={{ width: "100%", margin: 5 }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* button */}
        <Box>{renderFormPreScreening(activeStep)}</Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <Backdrop sx={{ color: "#fff", zIndex: 2 }} open={true}>
              <span
                style={{
                  justifyContent: "center",
                  position: "fixed",
                  top: "40%",
                }}
              >
                <Box
                  sx={{ p: 2, m: 2, justifyContent: "center", display: "flex" }}
                >
                  <CircularProgress
                    lo
                    style={{
                      width: 100,
                      height: 100,
                      color: "rgba(184,0,200,1)",
                    }}
                  />
                </Box>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <label style={{ fontSize: 22, fontWeight: "bold" }}>
                    {" "}
                    Please wait...
                  </label>
                </Box>
              </span>
            </Backdrop>
          )}
          <Button
            sx={{ p: 1, m: 1 }}
            variant="outlined"
            color="error"
            //onClick={() => setActiveStep(activeStep > 0 ? activeStep - 1 : 0)}
            onClick={() => {
              Swal.fire({
                title: "Do you want to leave this page?",
                icon: "question",
                position: "center",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/main");
                }
              });
            }}
          >
            Cancel
          </Button>
          {activeStep >= 0 ? (
            <Button
              sx={{ p: 1, m: 1 }}
              variant="outlined"
              disabled={loading}
              //onClick={() => updateRequestFormByIds()}
              onClick={() => {
                handleUpdateFiles();
              }}
            >
              Save
            </Button>
          ) : null}
          <Button
            sx={{ p: 1, m: 1 }}
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (activeStep === 0) {
                // updateRequestFormByIdAndNextStep();
                handleUploadFiles();
                // setActiveStep(activeStep + 1); // Move to the next step
              } else if (activeStep === 1) {
                handleUploadFiles();
              } else {
                setActiveStep(activeStep + 1); // Move to the next step
              }
            }}
            // onClick={() => {
            //   if (activeStep > steps.length - 2) {
            //     submitRequestFormByIds();
            //     handleSubmit();
            //     //setActiveStep(activeStep + 1);
            //   } else {
            //     setActiveStep(activeStep + 1);
            //   }
            // }}
            type="submit"
          >
            {activeStep < 0 ? "Next" : "Submit"}
          </Button>
        </Box>
      </Stack>
      <ModalPreScreening
        request={registrationNumber}
        open={openModal}
        onHide={() => setOpenModal(false)}
        style={{ zIndex: 9999 }}
      />
    </>
  );
}
