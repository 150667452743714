import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button } from "@mui/material";
import SelfEvaluation from "../components/RegisterForm/SelfEvaluation";
import KYC from "../components/RegisterForm/KYC";
import ModalRegistrationForm from "../components/RegisterForm/Modal/ModalRegistrationForm";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import RequirementDocumentForm from "../components/CounterRegistration/RequirementDocumentForm";
import IdentificationForm from "../components/CounterRegistration/IdentificationForm";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      style={{
        color: completed || active ? "#fff" : "black",
        backgroundColor: "white",
        border: "1px solid #b5b5b5",
      }}
    >
      {completed ? <CheckIcon /> : <>{props.icon}</>}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Identification", "Self-Evaluation", "KYC", "Required Document"];

export default function CounterPartyRegistration() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  
  const renderFormRegister = (page) => {
    switch (page) {
      case 0:
        return <IdentificationForm submitForm={handleSubmitIndentification} />;
      case 1:
        return <SelfEvaluation />;
      case 2:
        return <KYC />;
      case 3:
        return <RequirementDocumentForm />;
      default:
        return <></>;
    }
  };

  const handleSubmitIndentification = (data) => {
    setActiveStep(
      activeStep > steps.length - 2 ? activeStep : activeStep + 1
    )
  };
  return (
    <>
      <Stack sx={{ width: "100%", margin: 5 }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* button */}
        <Box>{renderFormRegister(activeStep)}</Box>
        {activeStep === 0 ? (
          <></>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ p: 1, m: 1 }}
              variant="outlined"
              color="error"
              onClick={() => setActiveStep(activeStep > 0 ? activeStep - 1 : 0)}
            >
              Cancel
            </Button>
            {activeStep > 0 ? (
              <Button
                sx={{ p: 1, m: 1 }}
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Save draft has been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }}
              >
                Save Draft
              </Button>
            ) : null}
            <Button
              sx={{ p: 1, m: 1 }}
              variant="contained"
              onClick={() =>
                setActiveStep(
                  activeStep > steps.length - 2 ? activeStep : activeStep + 1
                )
              }
            >
              {activeStep < 4 ? "Next" : "Confirm"}
            </Button>
          </Box>
        )}
      </Stack>

      {/* modal registration-complete form */}
      <ModalRegistrationForm
        open={openModal}
        onHide={() => setOpenModal(false)}
      />
    </>
  );
}
