import * as React from "react";
import { useNavigate } from 'react-router-dom';

import helper from '../services/helper';

export default function LogoutScreen() {
  const navigate = useNavigate();
  helper.Auth.Logout();
  navigate('/');

  return (<></>);
}
