import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import helper from "../services/helper";
import Footer from "./Footer";
import Logo from "../assets/img/thaioil.png";

const UnAuthGuard = ({ component }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const isLogin = helper.Auth.isLOGIN();
    if (isLogin) {
      window.location.href = "/main";
    } else {
      setLoading(false);
    }
  }, [component]);

  if (loading) return <></>;

  return (
    <React.Fragment>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl" disableGutters>
          <Toolbar disableGutters>
            <img
              src={Logo}
              alt="Thaioil Group"
              className="logo-header-no-login"
            />
          </Toolbar>
        </Container>
      </AppBar>
      <div className="App">
        <div className="App-header">{component}</div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default UnAuthGuard;
