import * as React from "react";
import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import TextInput from "../TextInput";
import { useEffect } from "react";

export default function SelfEvaluation(props) {
  const [objSelfEvaluation, setObjSelfEvaluation] = useState({
    antiMoney1: props.data ? props.data.antiMoney1 : "1",
    antiMoney2: props.data ? props.data.antiMoney2 : "1",
    antiMoney3: props.data ? props.data.antiMoney3 : "1",
    humanRights1: props.data ? props.data.humanRights1 : "1",
    humanRights2: props.data ? props.data.humanRights2 : "1",
    humanRights3: props.data ? props.data.humanRights3 : "1",
    antiMoneyReason1: props.data ? props.data.antiMoneyReason1 : "1",
  });

  useEffect(() => {
    if (
      objSelfEvaluation.antiMoney1 !== "" &&
      objSelfEvaluation.antiMoney2 !== "" &&
      objSelfEvaluation.antiMoney3 !== "" &&
      objSelfEvaluation.humanRights1 !== "" &&
      objSelfEvaluation.humanRights2 !== "" &&
      objSelfEvaluation.humanRights3 !== ""
    ) {
      return props.selfData(objSelfEvaluation);
    }

    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [objSelfEvaluation]);

  console.log(objSelfEvaluation.antiMoney1);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {/* anit-money */}
          <Box sx={{ width: "100%", p: 1 }}>
            <Box sx={{ backgroundColor: "#dbd9d9" }}>
              <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                Anti Money Laundering
              </Typography>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                1. Are any of your company, company's directors, shareholders or
                beneficial owners violated to the anti-money laundering laws?
              </Typography>
              <FormControl
                sx={{ ml: 2 }}
                defaultValue={objSelfEvaluation.antiMoney1}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.antiMoney1 = e.target.value;
                  if(obj.antiMoney1 === "0"){
                    obj.antiMoneyReason1 = ""
                  }
                  setObjSelfEvaluation(obj);
                }}
              >
                <RadioGroup
                  name="radio-group-self1"
                  value={objSelfEvaluation.antiMoney1}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <TextInput
              sx={{ width: '70%' }}
                placeholder="If Yes, please specify the reason of the default"
                value={objSelfEvaluation.antiMoneyReason1}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.antiMoneyReason1 = e.target.value;
                  setObjSelfEvaluation(obj);
                }}
                disabled={objSelfEvaluation.antiMoney1 === "0" ? true : false}
              />
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                2. Anti Money Laundering Questions
              </Typography>
              <FormControl
                sx={{ ml: 2 }}
                defaultValue={objSelfEvaluation.antiMoney2}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.antiMoney2 = e.target.value;
                  setObjSelfEvaluation(obj);
                }}
              >
                <RadioGroup
                  name="radio-group-self2"
                  value={objSelfEvaluation.antiMoney2}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                3. Anti Money Laundering Questions
              </Typography>
              <FormControl
                sx={{ ml: 2 }}
                defaultValue={objSelfEvaluation.antiMoney3}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.antiMoney3 = e.target.value;
                  setObjSelfEvaluation(obj);
                }}
              >
                <RadioGroup
                  name="radio-group-self3"
                  value={objSelfEvaluation.antiMoney3}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          {/* human-rights */}
          <Box sx={{ width: "100%", p: 1 }}>
            <Box sx={{ backgroundColor: "#dbd9d9" }}>
              <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                Human Rights
              </Typography>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                1. Human Rights Questions
              </Typography>
              <FormControl
                sx={{ ml: 2 }}
                defaultValue={objSelfEvaluation.humanRights1}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.humanRights1 = e.target.value;
                  setObjSelfEvaluation(obj);
                }}
              >
                <RadioGroup
                  name="radio-group-self4"
                  value={objSelfEvaluation.humanRights1}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                2. Human Rights Questions
              </Typography>
              <FormControl
                sx={{ ml: 2 }}
                defaultValue={objSelfEvaluation.humanRights2}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.humanRights2 = e.target.value;
                  setObjSelfEvaluation(obj);
                }}
              >
                <RadioGroup
                  name="radio-group-self5"
                  value={objSelfEvaluation.humanRights2}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                3. Human Rights Questions
              </Typography>
              <FormControl
                sx={{ ml: 2 }}
                defaultValue={objSelfEvaluation.humanRights3}
                onChange={(e) => {
                  let obj = Object.assign({}, objSelfEvaluation);
                  obj.humanRights3 = e.target.value;
                  setObjSelfEvaluation(obj);
                }}
              >
                <RadioGroup
                  name="radio-group-self6"
                  value={objSelfEvaluation.humanRights3}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
