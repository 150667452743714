import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

// lightning design
import symbols from "../styles/thirdparty/salesforce/icons/utility-sprite/svg/symbols.svg";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";

import "../styles/ResetPassword.css";

import { CTRUserAPI } from "../services/apigateway";

export function ForgotPasswordScreen() {
  const navigate = useNavigate();
  const [isValidEmailPattern, setIsValidEmailPattern] = React.useState(false);

  const handleEmailChanged = function (e) {
    const email = e.currentTarget.value;
    setIsValidEmailPattern(/^\w+.*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(email));
  };

  const submitEmailResetPassword = async function (e) {
    e.preventDefault();

    const ctrUser = await CTRUserAPI.findUsernameByEmail({
      email: document.getElementById("email").value,
    });

    if (!ctrUser || ctrUser.length === 0) {
      Swal.fire({
        title: "Invalid email<br/>Not found email address",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    }

    const resetResult = await CTRUserAPI.resetPassword({
      email: document.getElementById("email").value,
    });

    if (resetResult.status === "SUCCESS") {
      navigate("/email-reset-password");
      return;
    }

    Swal.fire({
      title: "An error occurred<br/>Please try again",
      icon: "error",
      showConfirmButton: true,
    });
  };

  return (
    <div className="ForgotPasswordScreen">
      <div
        className="
                slds-large-size_1-of-3
                slds-medium-size_1-of-2
                slds-size_1-of-1
                slds-p-around_xx-small
                box-absolute-center"
      >
        <h1 className="box-caption">Forgot Your Password</h1>
        <div className="box">
          <p>Having trouble loggin in?</p>
          <ul>
            <li>Usernames are in the form of an email address.</li>
            <li>Passwords are case sensitive.</li>
          </ul>
          <p>To reset your password, enter your username.</p>
          <form onSubmit={submitEmailResetPassword}>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="email">
                Email Address
              </label>
              <div className="slds-form-element__control">
                <input
                  type="text"
                  id="email"
                  placeholder="Email Address"
                  className="slds-input"
                  onChange={handleEmailChanged}
                  required
                />
              </div>
            </div>
            <div className="slds-grid slds-gutters">
              <div className="slds-col">
                <Link
                  className="slds-button slds-button_outline-brand slds-size_1-of-1"
                  to="/"
                >
                  Cancel
                </Link>
              </div>
              <div className="slds-col">
                <button
                  className="slds-button slds-button_brand slds-size_1-of-1"
                  type="submit"
                  disabled={!isValidEmailPattern}
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
          <p className="slds-text-align_center slds-m-bottom_none remark">
            Any other problems, please contact
            <br />
            <a href="mailto:pttdigital-callcenter@thaioilgroup.com">
              <svg className="icon-email" aria-hidden="true">
                <use xlinkHref={`${symbols}#email`}></use>
              </svg>
              pttdigital-callcenter@thaioilgroup.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export function InvalidResetPasswordEmailScreen() {
  const navigate = useNavigate();

  return (
    <div className="InvalidResetPasswordEmailScreen">
      <div
        className="
                slds-large-size_1-of-3
                slds-medium-size_1-of-2
                slds-size_1-of-1
                slds-p-around_xx-small
                box-absolute-center
            "
      >
        <h1 className="box-caption">Invalid Email Address</h1>
        <div className="box">
          <p>Invalid Email Address</p>
          <p>
            TODO: Write message to inform which email does not register to the
            system.
          </p>
          <p>
            If you still can't log in, contact
            <a href="mailto:pttdigital-callcenter@thaioilgroup.com">
              <svg className="icon-email" aria-hidden="true">
                <use xlinkHref={`${symbols}#email`}></use>
              </svg>
              pttdigital-callcenter@thaioilgroup.com
            </a>
          </p>
          <div className="slds-grid slds-gutters">
            <div className="slds-col">
              <button
                className="slds-button slds-button_brand slds-size_1-of-1"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ErrorResetPasswordScreen() {
  const navigate = useNavigate();

  return (
    <div className="ErrorResetPasswordScreen">
      <div
        className="
                slds-large-size_1-of-3
                slds-medium-size_1-of-2
                slds-size_1-of-1
                slds-p-around_xx-small
                box-absolute-center"
      >
        <h1 className="box-caption">Reset Password Error</h1>
        <div className="box">
          <p>Reset Password Error (Unknown Error)</p>
          <p>
            TODO: Write message to inform unknown error occur while reset the
            password.
          </p>
          <p>
            If you still can't log in, contact
            <a href="mailto:pttdigital-callcenter@thaioilgroup.com">
              <svg className="icon-email" aria-hidden="true">
                <use xlinkHref={`${symbols}#email`}></use>
              </svg>
              pttdigital-callcenter@thaioilgroup.com
            </a>
          </p>
          <div className="slds-grid slds-gutters">
            <div className="slds-col">
              <button
                className="slds-button slds-button_brand slds-size_1-of-1"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function EmailResetPasswordScreen() {
  return (
    <div className="EmailResetPasswordScreen">
      <div
        className="
                slds-large-size_1-of-3
                slds-medium-size_1-of-2
                slds-size_1-of-1
                slds-p-around_xx-small
                box-absolute-center"
      >
        <h1 className="box-caption">Check Your Email</h1>
        <div className="box">
          <p>
            We've sent you an email with a link to finish resetting your
            password.
          </p>
          <p>Can't find an email? Try checking you spam folder.</p>
          <p className="slds-text-align_center remark">
            If you still can't log in, have us{" "}
            <Link to="/forgot-password">resend the email</Link> or contact
            <br />
            <a href="mailto:pttdigital-callcenter@thaioilgroup.com">
              <svg className="icon-email" aria-hidden="true">
                <use xlinkHref={`${symbols}#email`}></use>
              </svg>
              pttdigital-callcenter@thaioilgroup.com
            </a>
          </p>
          <div className="slds-grid slds-gutters slds-p-top_large">
            <div className="slds-col">
              <Link
                className="slds-button slds-button_brand slds-size_1-of-1"
                to="/"
              >
                Return to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function VerifySecurityQuestionScreen() {
  const navigate = useNavigate();
  // const [id, setId] = React.useState("");
  //   const [email, setEmail] = React.useState("");
  //   const [securityQuestion, setSecurityQuestion] = React.useState("");
  //   const [isBlankSecurityAnswer, setIsBlankSecurityAnswer] =
  //     React.useState(true);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    (async function () {
      const ctrUser = await CTRUserAPI.findUsernameByResetKey({
        secret: urlParams.get("secret"),
      });

      if (!ctrUser || ctrUser.length === 0) {
        navigate("/invalid-url");
        return;
      }

      const result = await CTRUserAPI.verifySecurityQuestion({
        secret: urlParams.get("secret"),
      });

      navigate("/change-password", {
        state: {
          email: ctrUser[0].Email__c,
          id: ctrUser[0].Id,
          isValid: true,
          lastChangeDate: result.lastChangeDate, // || new Date(),
          secret: urlParams.get("secret"),
        },
      });

      //   setId(ctrUser[0].Id);
      //   setEmail(ctrUser[0].Email__c);
      //   setSecurityQuestion(ctrUser[0].SecurityQuestion__c);
    })();
  });

  //   const submitSecurityAnswer = async function (e) {
  //     e.preventDefault();

  //     const urlParams = new URLSearchParams(window.location.search);
  //     const result = await CTRUserAPI.verifySecurityQuestion({
  //       secret: urlParams.get("secret"),
  //       securityQuestion: document.getElementById("question").value,
  //       securityAnswer: document.getElementById("answer").value,
  //     });

  //     if (!result || !result.isValid) {
  //       navigate("/error-reset-password");
  //       return;
  //     }

  //     navigate("/change-password", {
  //       state: {
  //         email: document.getElementById("email").value,
  //         id: id,
  //         isValid: true,
  //         lastChangeDate: result.lastChangeDate,
  //         secret: urlParams.get("secret"),
  //         securityQuestion: document.getElementById("question").value,
  //         securityAnswer: document.getElementById("answer").value,
  //       },
  //     });
  //   };

  return (
    <></>
    // <div className="VerifySecurityQuestionScreen">
    //   <div
    //     className="
    //             slds-large-size_1-of-3
    //             slds-medium-size_1-of-2
    //             slds-size_1-of-1
    //             slds-p-around_xx-small
    //             box-absolute-center"
    //   >
    //     <h1 className="box-caption">Answer Your Security Question</h1>
    //     <div className="box">
    //       <p>
    //         To verify your identity, answer the security question associated
    //         with your account.
    //       </p>
    //       <form onSubmit={submitSecurityAnswer}>
    //         <div className="slds-form-element">
    //           <label className="slds-form-element__label" htmlFor="email">
    //             Email Address
    //           </label>
    //           <div className="slds-form-element__control">
    //             <input
    //               type="text"
    //               id="email"
    //               value={email}
    //               className="slds-input"
    //               disabled
    //             />
    //           </div>
    //         </div>
    //         <div className="slds-form-element">
    //           <label className="slds-form-element__label" htmlFor="question">
    //             Question
    //           </label>
    //           <div className="slds-form-element__control">
    //             <input
    //               type="text"
    //               id="question"
    //               value={securityQuestion}
    //               className="slds-input"
    //               disabled
    //             />
    //           </div>
    //         </div>
    //         <div className="slds-form-element">
    //           <label
    //             className="slds-form-element__label required"
    //             htmlFor="answer"
    //           >
    //             Answer
    //           </label>
    //           <div className="slds-form-element__control">
    //             <input
    //               type="text"
    //               id="answer"
    //               placeholder=""
    //               className="slds-input"
    //               onChange={(e) =>
    //                 setIsBlankSecurityAnswer(!e.currentTarget.value)
    //               }
    //               required
    //             />
    //           </div>
    //         </div>
    //         <div className="slds-grid slds-gutters">
    //           <div className="slds-col">
    //             <button
    //               className="slds-button slds-button_brand slds-size_1-of-1"
    //               disabled={isBlankSecurityAnswer}
    //             >
    //               Continue
    //             </button>
    //           </div>
    //         </div>
    //       </form>
    //       <p class="slds-text-align_center remark">
    //         If you can't still log in, try the following: Contact the
    //         administrator for assistance.
    //         <br />
    //         <a href="mailto:pttdigital-callcenter@thaioilgroup.com">
    //           <svg className="icon-email" aria-hidden="true">
    //             <use xlinkHref={`${symbols}#email`}></use>
    //           </svg>
    //           pttdigital-callcenter@thaioilgroup.com
    //         </a>
    //       </p>
    //     </div>
    //   </div>
    // </div>
  );
}

export function ChangePasswordScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [lastChangeDate] = React.useState(
    location.state.lastChangeDate
      ? moment(location.state.lastChangeDate).format("DD/MM/YYYY hh:mm:ss")
      : null
  );
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false);

  const [hasMinimumCharacters, setHasMinimumCharacters] = React.useState(false);
  const [hasAtLeastOneLetter, setHasAtLeastOneLetter] = React.useState(false);
  const [hasAtLeastOneNumber, setHasAtLeastOneNumber] = React.useState(false);
  const [isValidConfirmPassword, setIsValidConfirmPassword] =
    React.useState(true);

  React.useEffect(() => {
    (async function () {
      if (!location.state || !location.state.isValid) {
        navigate("/invalid-url");
        return;
      }
      const ctrUser = await CTRUserAPI.findUsernameByResetKey({
        secret: location.state.secret,
      });
      if (!ctrUser || ctrUser.length === 0) {
        navigate("/invalid-url");
        return;
      }
    })();
  });

  const handlePasswordChanged = function (e) {
    let password = document.getElementById("password").value;
    let confirmpassword = document.getElementById("confirmpassword").value;

    setHasMinimumCharacters(isValidMinimumCharacters(password));
    setHasAtLeastOneLetter(isValidAtLeastOneLetter(password));
    setHasAtLeastOneNumber(isValidAtLeastOneNumber(password));
    setIsValidConfirmPassword(
      !confirmpassword ||
        isEqualPasswordAndConfirmPassword(password, confirmpassword)
    );
  };

  const handleConfirmPasswordChanged = function (e) {
    let password = document.getElementById("password").value;
    let confirmpassword = document.getElementById("confirmpassword").value;

    setIsValidConfirmPassword(
      !confirmpassword ||
        isEqualPasswordAndConfirmPassword(password, confirmpassword)
    );
  };

  const isValidMinimumCharacters = (txt) =>
    String(txt).length >= 8 ? true : false;
  const isValidAtLeastOneLetter = (txt) =>
    /.*[a-zA-Z\u0E00-\u0E4F].*/.test(txt);
  const isValidAtLeastOneNumber = (txt) => /.*[0-9].*/.test(txt);
  const isEqualPasswordAndConfirmPassword = (password, confirmpassword) =>
    password === confirmpassword;

  const submitChangePassword = async function (e) {
    e.preventDefault();

    // const urlParams = new URLSearchParams(window.location.search);
    const resetResult = await CTRUserAPI.changePassword({
      id: location.state.id,
      password: document.getElementById("password").value,
      secret: location.state.secret, //urlParams.get("secret"),
      securityQuestion: location.state.securityQuestion,
      securityAnswer: location.state.securityAnswer,
    });

    if (resetResult.status === "SUCCESS") {
      navigate("/");
      return;
    }

    navigate("/error-reset-password");
  };

  return (
    <div className="ChangePasswordScreen">
      <div
        className="
                slds-large-size_1-of-3
                slds-medium-size_1-of-2
                slds-size_1-of-1
                slds-p-around_xx-small
                box-absolute-center"
      >
        <h1 className="box-caption">Change Your Password</h1>
        <div className="box">
          <p>
            Enter a new password for <strong>{location.state.email}</strong>.
            Make sure to include at least
          </p>
          <ul>
            <li>
              <i
                className={
                  hasMinimumCharacters
                    ? "bi bi-check-circle-fill"
                    : "bi bi-x-circle-fill"
                }
              ></i>{" "}
              8 characters
            </li>
            <li>
              <i
                className={
                  hasAtLeastOneLetter
                    ? "bi bi-check-circle-fill"
                    : "bi bi-x-circle-fill"
                }
              ></i>{" "}
              1 letter
            </li>
            <li>
              <i
                className={
                  hasAtLeastOneNumber
                    ? "bi bi-check-circle-fill"
                    : "bi bi-x-circle-fill"
                }
              ></i>{" "}
              1 number
            </li>
          </ul>
          <form onSubmit={submitChangePassword}>
            <div className="slds-form-element">
              <label
                className="slds-form-element__label required"
                htmlFor="password"
              >
                New Password
              </label>
              <div className="slds-form-element__control">
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  placeholder="**********"
                  className="slds-input"
                  onChange={handlePasswordChanged}
                  required
                />
                <span onClick={() => setPasswordShown(!passwordShown)}>
                  <i
                    className={
                      passwordShown
                        ? "bi bi-eye toggle-hide-pass"
                        : "bi bi-eye-slash toggle-hide-pass"
                    }
                    id="togglePassword"
                  ></i>
                </span>
              </div>
            </div>
            <div
              className={
                !isValidConfirmPassword
                  ? "slds-form-element slds-has-error"
                  : "slds-form-element"
              }
            >
              <label
                className="slds-form-element__label required"
                htmlFor="confirmpassword"
              >
                Confirm New Password
              </label>
              <div className="slds-form-element__control">
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  id="confirmpassword"
                  placeholder="**********"
                  className="slds-input"
                  onChange={handleConfirmPasswordChanged}
                  required
                />
                <span
                  onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
                >
                  <i
                    className={
                      confirmPasswordShown
                        ? "bi bi-eye toggle-hide-pass"
                        : "bi bi-eye-slash toggle-hide-pass"
                    }
                    id="togglePassword"
                  ></i>
                </span>
              </div>
              {!isValidConfirmPassword && (
                <div className="slds-form-element__help">
                  Confirm Password doesn't match Password
                </div>
              )}
            </div>
            <div className="slds-grid slds-gutters">
              <div className="slds-col">
                <button
                  className="slds-button slds-button_brand slds-size_1-of-1"
                  disabled={
                    !(
                      hasMinimumCharacters &&
                      hasAtLeastOneLetter &&
                      hasAtLeastOneNumber &&
                      isValidConfirmPassword
                    )
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
          {lastChangeDate && (
            <p className="remark">
              Password was last changed on {lastChangeDate}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
