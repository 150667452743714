import * as React from "react";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import TextInput from "../TextInput";
import DateTimePicker from "../DateTimePicker";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { PreRegisterAPI, RegistrationFormAPI } from "../../services/apigateway";
import ErrorIcon from "@mui/icons-material/Error";

export default function RegistrationForm(props) {
  const objRegistrationForm = props.formDataObject;
  const setObjRegistrationForm = props.onFormDataObjectChange;
  // const [objRegistrationForm, setObjectRegistrationFormLocal] = useState(props.formDataObject);
  // const setObjRegistrationForm = (data) => {
  //   setObjectRegistrationFormLocal(data);
  //   props.onFormDataObjectChange(data);
  // }

  const [required, setRequired] = useState({
    fields: "",
    active: false,
  });
  const [customerActive, setCustomerActive] = useState([]);
  const [supplierActive, setSupplierActive] = useState([]);

  const [listCountry, setListCountry] = useState([]);
  const [listProvince1, setListProvince1] = useState([]);
  const [listProvince2, setListProvince2] = useState([]);
  const [listSalutation, setListSalutation] = useState([]);
  const [listMobileCode, setListMobileCode] = useState([]);
  const [listPhoneCode, setListPhoneCode] = useState([]);
  const [listFaxCode, setListFaxCode] = useState([]);
  const [listShippingCountry, setListShippingCountry] = useState([]);
  const [listLocationType, setListLocationType] = useState([]);
  const [listSpecifyLanguages, setListSpecifyLanguages] = useState([]);
  const [listCustomerType, setListCustomerType] = useState([]);
  const [listSupplierType, setListSupplierType] = useState([]);
  const [listBusinessType, setListBusinessType] = useState([]);
  const [listEntityLegal, setListEntityLegal] = useState([]);
  const [listEstimateMonthly, setListEstimateMonthly] = useState([]);

  const [listYearsOfExperience, setListYearsOfExperience] = useState([]);

  useEffect(() => {
    (async function () {
      const picklistMainServices = await PreRegisterAPI.getPicklist({
        objectApiName: "Account",
        fieldApiName: "YearsofExperience__c",
      });
      setListYearsOfExperience(picklistMainServices);
    })();
  }, []);

  useEffect(() => {
    if (props.formId) {
      masterDataRegistrationForm(props.formId);
    }
    getMasterDataList();

    //eslint-disable-next-line
  }, [props.formId]);

  useEffect(() => {
    isChecksCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    objRegistrationForm.InterestedCrudeAsCustomer,
    objRegistrationForm.InterestedPetrochemicalAsCustomer,
    objRegistrationForm.InterestedPetroleumAsCustomer,
    objRegistrationForm.InterestedLubeBaseAsCustomer,
    objRegistrationForm.InterestedLABIXAsCustomer,
    objRegistrationForm.InterestedOtherAsCustomer,
  ]);

  useEffect(() => {
    isChecksSupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    objRegistrationForm.InterestedCrudeAsSupplier,
    objRegistrationForm.InterestedB100EthanolAsSupplier,
    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier,
    objRegistrationForm.InterestedNormalParaffinAsSupplier,
    objRegistrationForm.InterestedOtherAsSupplier,
  ]);

  const masterDataRegistrationForm = async (id) => {
    // const data = await RequestFormAPI.getById(id);
    // setObjRegistrationForm(data);
    const data = props.formDataObject;
    getProvinceForCountry(data, 3);
  };

  const getMasterDataList = async () => {
    const slList = await RegistrationFormAPI.getSaluationList();
    const mbList = await RegistrationFormAPI.getMobileCodeList();
    const phList = await RegistrationFormAPI.getPhoneCodeList();
    const fxList = await RegistrationFormAPI.getFaxCodeList();
    const countryList = await PreRegisterAPI.getCountry();
    const shpList = await RegistrationFormAPI.getShippingCountryList();
    const lcList = await RegistrationFormAPI.getLocationTypeList();
    const spcList = await RegistrationFormAPI.getSpecifyLanguages();
    const csList = await RegistrationFormAPI.getCustomerType();
    const splList = await RegistrationFormAPI.getSupplierType();
    const buList = await RegistrationFormAPI.getBusinessType();
    const enList = await RegistrationFormAPI.getLegalEntity();
    const estMonth =
      await RegistrationFormAPI.getEstimatedMonthlyPurchaseUnit();

    setListSalutation(slList);
    setListMobileCode(mbList);
    setListPhoneCode(phList);
    setListFaxCode(fxList);
    setListCountry(countryList);
    setListShippingCountry(shpList);
    setListLocationType(lcList);
    setListSpecifyLanguages(spcList);
    setListCustomerType(csList);
    setListSupplierType(splList);
    setListBusinessType(buList);
    setListEntityLegal(enList);
    setListEstimateMonthly(estMonth);
  };

  const getProvinceForCountry = async (country, type) => {
    if (type === 3) {
      const provinceList1 = await RegistrationFormAPI.getProvince(
        country.Country
      );
      const provinceList2 = await RegistrationFormAPI.getProvince(
        country.Country
      );
      setListProvince1(provinceList1);
      setListProvince2(provinceList2);
    } else {
      const provinceList = await RegistrationFormAPI.getProvince(country);
      if (type === 1) {
        setListProvince1(provinceList);
      } else {
        setListProvince2(provinceList);
      }
    }
  };

  const isValidate = () => {
    let obj = { ...objRegistrationForm };
    let rq = {
      fields: "",
      active: false,
    };
    if (obj.TAXNumber === "") {
      rq.fields = "Tax Number";
      rq.active = true;
      return rq;
    } else if (obj.Country === "") {
      rq.fields = "Country";
      rq.active = true;
      return rq;
    } else if (obj.HouseNo === "" || obj.City === "") {
      rq.fields = obj.HouseNo === "" ? "HouseNo" : "City";
      rq.active = true;
      return rq;
    } else if (obj.MailingHouseNo === "" || obj.MailingCity === "") {
      rq.fields =
        obj.MailingHouseNo === "" ? "Mailing House No" : "Mailing City";
      rq.active = true;
      return rq;
    } else if (
      obj.ContactFirstName === "" ||
      obj.ContactLastName === "" ||
      obj.ContactMobileCountryCode === ""
    ) {
      rq.fields =
        obj.ContactMobileCountryCode === ""
          ? "Contact Mobile Country Code"
          : obj.ContactFirstName === ""
          ? "Contact FirstName"
          : "Contact LastName";
      rq.active = true;
      return rq;
    } else if (obj.DestinationCountries.length > 0) {
      if (
        obj.DestinationCountries[0].Country === "" ||
        obj.DestinationCountries[0].LocationType === ""
      ) {
        rq.fields =
          obj.DestinationCountries[0].Country === ""
            ? "Destination Countries - Country"
            : "Destination Countries - LocationType";
        rq.active = true;
        return rq;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isChecksCustomer = () => {
    if (
      objRegistrationForm.InterestedCrudeAsCustomer ||
      objRegistrationForm.InterestedPetrochemicalAsCustomer ||
      objRegistrationForm.InterestedPetroleumAsCustomer ||
      objRegistrationForm.InterestedLubeBaseAsCustomer ||
      objRegistrationForm.InterestedLABIXAsCustomer ||
      objRegistrationForm.InterestedOtherAsCustomer
    ) {
      setCustomerActive(true);
    } else {
      setCustomerActive(false);
    }
  };

  const isChecksSupplier = () => {
    let obj = { ...objRegistrationForm };
    if (
      obj.InterestedCrudeAsSupplier ||
      obj.InterestedB100EthanolAsSupplier ||
      obj.InterestedPetroleumAndComponentAsSupplier ||
      obj.InterestedNormalParaffinAsSupplier ||
      obj.InterestedOtherAsSupplier
    ) {
      setSupplierActive(true);
    } else {
      setSupplierActive(false);
    }
  };

  return (
    <Card>
      {/* Company Information */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Company Information
          </Typography>
        </Box>
        {/* row1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Company Name (English)</Typography>
            <TextInput
              placeholder="Company Name (English)"
              value={objRegistrationForm.CompanyNameEN}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameEN = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Company Name (Other Languages)
            </Typography>
            <TextInput
              placeholder="Company Name (Local)"
              value={objRegistrationForm.CompanyNameLocal}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameLocal = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Specify Languages of Company Name
            </Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.CompanyNameLanguage !== ""
                    ? objRegistrationForm.CompanyNameLanguage
                    : "CompanyNameLanguage"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.CompanyNameLanguage = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.CompanyNameLanguage !== ""
                      ? objRegistrationForm.CompanyNameLanguage
                      : "CompanyNameLanguage"
                  }
                  disabled
                >
                  Select Company Name Language
                </MenuItem>
                {listSpecifyLanguages.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* row3 Alias */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Company Name (Alias)
            </Typography>
            <TextInput
              id="CompanyNameAlias"
              className="field-required"
              placeholder="Company Name (Alias)"
              value={objRegistrationForm.CompanyNameAlias}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameAlias = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row4 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Former name of company (if any)
            </Typography>
            <TextInput
              placeholder="Former name of company"
              value={objRegistrationForm.CompanyNameFormer}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameFormer = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Country</Typography>

            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.Country !== ""
                    ? objRegistrationForm.Country
                    : "Country"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.Country = e.target.value;
                  setObjRegistrationForm(obj);
                  getProvinceForCountry(e.target.value, 1);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.Country !== ""
                      ? objRegistrationForm.Country
                      : "Country"
                  }
                  disabled
                >
                  Select Country
                </MenuItem>
                {listCountry.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* row4 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Customer Type</Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.CustomerType !== ""
                    ? objRegistrationForm.CustomerType
                    : "CustomerType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.CustomerType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.CustomerType !== ""
                      ? objRegistrationForm.CustomerType
                      : "CustomerType"
                  }
                  disabled
                >
                  Select CustomerType
                </MenuItem>
                {listCustomerType.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Supplier Type</Typography>

            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.SupplierType !== ""
                    ? objRegistrationForm.SupplierType
                    : "SupplierType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.SupplierType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.SupplierType !== ""
                      ? objRegistrationForm.SupplierType
                      : "SupplierType"
                  }
                  disabled
                >
                  Select Supplier Type
                </MenuItem>
                {listSupplierType.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* row5 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Type of Business</Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.BusinessType !== ""
                    ? objRegistrationForm.BusinessType
                    : "BusinessType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.BusinessType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.BusinessType !== ""
                      ? objRegistrationForm.BusinessType
                      : "BusinessType"
                  }
                  disabled
                >
                  Select Business Type
                </MenuItem>
                {listBusinessType.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Business Type Others"
              value={objRegistrationForm.BusinessTypeOthers}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.BusinessTypeOthers = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row6 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Legal Entity</Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                defaultValue={
                  objRegistrationForm.LegalEntityType !== ""
                    ? objRegistrationForm.LegalEntityType
                    : "LegalEntityType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.LegalEntityType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.LegalEntityType !== ""
                      ? objRegistrationForm.LegalEntityType
                      : "LegalEntityType"
                  }
                  disabled
                >
                  Select Legal Entity Type
                </MenuItem>
                {listEntityLegal.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Legal Entity Type Others"
              value={objRegistrationForm.LegalEntityTypeOthers}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.LegalEntityTypeOthers = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row7 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Incorporation Date</Typography>

            <DateTimePicker
              defaultValue={dayjs(objRegistrationForm.IncorporationDate)}
              value={dayjs(objRegistrationForm.IncorporationDate)}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.IncorporationDate = dayjs(e).format("YYYY-MM-DD");
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Years of Experience in business?
            </Typography>
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className="field-required"
            >
              <Select
                id="YearsOfExperience"
                value={
                  objRegistrationForm.YearsOfExperience !== ""
                    ? objRegistrationForm.YearsOfExperience
                    : "YearsOfExperience"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.YearsOfExperience = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                sx={
                  objRegistrationForm.YearsOfExperience
                    ? { color: "black" }
                    : { color: "#c3c3c3" }
                }
                disabled
              >
                <MenuItem
                  value={
                    objRegistrationForm.YearsOfExperience !== ""
                      ? objRegistrationForm.YearsOfExperience
                      : "YearsOfExperience"
                  }
                  disabled
                >
                  Select Years of Experience in business
                </MenuItem>
                {listYearsOfExperience &&
                  listYearsOfExperience.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* row8 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">TAX Number</Typography>
            <TextInput
              placeholder="Tax Number"
              value={objRegistrationForm.TAXNumber}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.TAXNumber = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Company Website</Typography>
            <TextInput
              placeholder="Company Website"
              value={objRegistrationForm.Website}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Website = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row9 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              For crude domestic customer. please provide the number of
              registration issuse by Ministry of Energy
            </Typography>
            <TextInput
              placeholder="Number of resgistration issues"
              value={objRegistrationForm.MinistryOfEnergyRegistrationNo}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MinistryOfEnergyRegistrationNo = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
      </CardContent>

      {/* Registration Address */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Registration Address
          </Typography>
        </Box>
        {/* row1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Registered Address</Typography>
            <TextInput
              placeholder="House No."
              value={objRegistrationForm.HouseNo}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.HouseNo = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Room"
              value={objRegistrationForm.Room}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Room = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Street"
              value={objRegistrationForm.Street}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Street = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="SubDistrict"
              value={objRegistrationForm.SubDistrict}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.SubDistrict = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                defaultValue={
                  objRegistrationForm.City !== ""
                    ? objRegistrationForm.City
                    : "City"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.City = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.City !== ""
                      ? objRegistrationForm.City
                      : "City"
                  }
                  disabled
                >
                  Select City
                </MenuItem>
                {listProvince1.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Building"
              value={objRegistrationForm.Building}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Building = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Floor"
              value={objRegistrationForm.Floor}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Floor = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <Box variant="subtitle1" sx={{ pt: 2 }}>
              &nbsp;
            </Box>
            <TextInput
              placeholder="District"
              value={objRegistrationForm.District}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.District = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Postal Code"
              value={objRegistrationForm.PostalCode}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.PostalCode = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row2 location */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Location Google Maps</Typography>
            <TextInput
              placeholder="Google Maps Link"
              value={objRegistrationForm.LocationURL}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.LocationURL = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Latitude"
              value={objRegistrationForm.Latitude}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Latitude = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" sx={{ height: 78 }}>
              &nbsp;
            </Typography>
            <TextInput
              placeholder="Longitude"
              value={objRegistrationForm.Longitude}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Longitude = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
      </CardContent>

      {/* Mailing Address */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Mailing Address
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Mailing Address</Typography>
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Same as Registered Address"
              sx={{ width: "100%" }}
              checked={objRegistrationForm.MailingIsSameAsRegistered}
              value={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingIsSameAsRegistered = e.target.checked;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="House No."
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.HouseNo
                  : objRegistrationForm.MailingHouseNo
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingHouseNo = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Room"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Room
                  : objRegistrationForm.MailingRoom
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingRoom = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Street"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Street
                  : objRegistrationForm.MailingStreet
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingStreet = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="SubDistrict"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.SubDistrict
                  : objRegistrationForm.MailingSubDistrict
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingSubDistrict = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.City
                  : objRegistrationForm.MailingCity
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            >
              <Select
                value={
                  objRegistrationForm.MailingIsSameAsRegistered
                    ? objRegistrationForm.City
                    : objRegistrationForm.MailingCity
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.MailingCity = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.MailingIsSameAsRegistered
                      ? objRegistrationForm.City
                      : objRegistrationForm.MailingCity
                  }
                  disabled
                >
                  Select City
                </MenuItem>
                {listProvince2.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ p: 1, m: 1, width: "100%", pt: 6 }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Building"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Building
                  : objRegistrationForm.MailingBuilding
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingBuilding = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Floor"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Floor
                  : objRegistrationForm.MailingFloor
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingFloor = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <Box variant="subtitle1" sx={{ pt: 2 }}>
              &nbsp;
            </Box>
            <TextInput
              placeholder="District"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.District
                  : objRegistrationForm.MailingDistrict
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingDistrict = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Postal Code"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.PostalCode
                  : objRegistrationForm.MailingPostalCode
              }
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingPostalCode = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
      </CardContent>

      {/* Contract Person */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Contract Person
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Contact Person</Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.ContactSalutation !== ""
                    ? objRegistrationForm.ContactSalutation
                    : "Title"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactSalutation = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.ContactSalutation !== ""
                      ? objRegistrationForm.ContactSalutation
                      : "Title"
                  }
                  disabled
                >
                  Select Title
                </MenuItem>
                {listSalutation.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextInput
              placeholder="First Name"
              value={objRegistrationForm.ContactFirstName}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactFirstName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Position"
              value={objRegistrationForm.ContactPosition}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactPosition = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <FormControl sx={{ minWidth: 180 }} size="small">
                <Select
                  value={
                    objRegistrationForm.ContactMobileCountryCode !== ""
                      ? objRegistrationForm.ContactMobileCountryCode
                      : "Country"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.ContactMobileCountryCode = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                >
                  <MenuItem
                    value={
                      objRegistrationForm.ContactMobileCountryCode !== ""
                        ? objRegistrationForm.ContactMobileCountryCode
                        : "Country"
                    }
                    disabled
                  >
                    Country Code
                  </MenuItem>
                  {listMobileCode.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextInput
                placeholder="Mobile Number"
                style={{ width: "100%", paddingLeft: 20 }}
                value={objRegistrationForm.ContactMobileNumber}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactMobileNumber = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <FormControl
                sx={{ minWidth: 180 }}
                size="small"
                value={objRegistrationForm.ContactFaxCountryCode}
              >
                <Select
                  value={
                    objRegistrationForm.ContactFaxCountryCode !== ""
                      ? objRegistrationForm.ContactFaxCountryCode
                      : "Country"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.ContactFaxCountryCode = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                >
                  <MenuItem
                    value={
                      objRegistrationForm.ContactFaxCountryCode !== ""
                        ? objRegistrationForm.ContactFaxCountryCode
                        : "Country"
                    }
                    disabled
                  >
                    Country Code
                  </MenuItem>
                  {listFaxCode.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextInput
                placeholder="Fax Number"
                style={{ width: "100%", paddingLeft: 20 }}
                value={objRegistrationForm.ContactFaxNumber}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactFaxNumber = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Nick Name"
              value={objRegistrationForm.ContactNickName}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactNickName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Last Name"
              value={objRegistrationForm.ContactLastName}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactLastName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Email Address"
              value={objRegistrationForm.ContactEmail}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactEmail = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />{" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <FormControl sx={{ minWidth: 180 }} size="small">
                <Select
                  value={
                    objRegistrationForm.ContactPhoneCountryCode !== ""
                      ? objRegistrationForm.ContactPhoneCountryCode
                      : "Country"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.ContactPhoneCountryCode = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                >
                  <MenuItem
                    value={
                      objRegistrationForm.ContactPhoneCountryCode !== ""
                        ? objRegistrationForm.ContactPhoneCountryCode
                        : "Country"
                    }
                    disabled
                  >
                    Country Code
                  </MenuItem>
                  {listPhoneCode.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextInput
                placeholder="Phone Number"
                style={{ width: "100%", paddingLeft: 20 }}
                value={objRegistrationForm.ContactPhoneNumber}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactPhoneNumber = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>

      {/* Product Type Of Interest */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Product Type Of Interest
          </Typography>
        </Box>
        {/*  row 1*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Interested Product Type as Customer
            </Typography>
            <Box sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Crude"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedCrudeAsCustomer}
                value={objRegistrationForm.InterestedCrudeAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedCrudeAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={"Petroleum Products"}
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedPetroleumAsCustomer}
                value={objRegistrationForm.InterestedPetroleumAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedPetroleumAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Petrochemical Products"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedPetrochemicalAsCustomer}
                value={objRegistrationForm.InterestedPetrochemicalAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedPetrochemicalAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label={"Lube Base Product"}
                  sx={{ width: "50%" }}
                  checked={objRegistrationForm.InterestedLubeBaseAsCustomer}
                  value={objRegistrationForm.InterestedLubeBaseAsCustomer}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLubeBaseAsCustomer = e.target.checked;
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  placeholder="Please specify product name"
                  value={objRegistrationForm.InterestedLubeBaseAsCustomerDesc}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLubeBaseAsCustomerDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="LABIX Products"
                  sx={{ width: "50%" }}
                  checked={objRegistrationForm.InterestedLABIXAsCustomer}
                  value={objRegistrationForm.InterestedLABIXAsCustomer}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLABIXAsCustomer = e.target.checked;
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  placeholder="Please specify product name"
                  value={objRegistrationForm.InterestedLABIXAsCustomerDesc}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLABIXAsCustomerDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Other Products"
                  sx={{ width: "50%" }}
                  checked={objRegistrationForm.InterestedOtherAsCustomer}
                  value={objRegistrationForm.InterestedOtherAsCustomer}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsCustomer = e.target.checked;
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  placeholder="Please specify product name"
                  value={objRegistrationForm.InterestedOtherAsCustomerDesc}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsCustomerDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Interested Product Type as Suppiler
            </Typography>
            <Box sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Crude"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedCrudeAsSupplier}
                value={objRegistrationForm.InterestedCrudeAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedCrudeAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="B100/Ethanol"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedB100EthanolAsSupplier}
                value={objRegistrationForm.InterestedB100EthanolAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedB100EthanolAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Normal Paraffin"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedNormalParaffinAsSupplier}
                value={objRegistrationForm.InterestedNormalParaffinAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedNormalParaffinAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Petroluem and Component"
                  sx={{ width: "100%" }}
                  checked={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier
                  }
                  value={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedPetroleumAndComponentAsSupplier =
                      e.target.checked;
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  placeholder="Please specify Petroluem and Component"
                  value={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplierDesc
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedPetroleumAndComponentAsSupplierDesc =
                      e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Other Products"
                  sx={{ width: "100%" }}
                  checked={objRegistrationForm.InterestedOtherAsSupplier}
                  value={objRegistrationForm.InterestedOtherAsSupplier}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsSupplier = e.target.checked;
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  placeholder="Please specify other products"
                  value={objRegistrationForm.InterestedOtherAsSupplierDesc}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsSupplierDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {/*  row 2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              p: 1,
              m: 1,
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">
              Please estimate the company purchasing volume per month
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextInput
                style={{ width: "100%" }}
                placeholder="Number of resgistration issues"
                value={objRegistrationForm.EstimatedMonthlyPurchaseQuantity}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.EstimatedMonthlyPurchaseQuantity = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
              &nbsp;
              <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                  value={
                    objRegistrationForm.EstimatedMonthlyPurchaseUnit !== ""
                      ? objRegistrationForm.EstimatedMonthlyPurchaseUnit
                      : "EstimatedMonthlyPurchaseUnit"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.EstimatedMonthlyPurchaseUnit = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                >
                  <MenuItem
                    value={
                      objRegistrationForm.EstimatedMonthlyPurchaseUnit !== ""
                        ? objRegistrationForm.EstimatedMonthlyPurchaseUnit
                        : "Unit"
                    }
                    disabled
                  >
                    Unit
                  </MenuItem>
                  {listEstimateMonthly.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Tentative Starting Trading Date
            </Typography>
            <DateTimePicker
              defaultValue={dayjs(objRegistrationForm.StartTradingDate)}
              value={dayjs(objRegistrationForm.StartTradingDate)}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.StartTradingDate = dayjs(e).format("YYYY-MM-DD");
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row 3 table */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Destination Country</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  dis
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">No.</TableCell>
                      <TableCell align="center">Country</TableCell>
                      <TableCell align="center">Location Type</TableCell>
                      <TableCell align="center">Delete</TableCell>
                      {/* <TableCell align="center">Actions</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objRegistrationForm.DestinationCountries &&
                      objRegistrationForm.DestinationCountries.map(
                        (item, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                                sx={{ width: "5%" }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                <FormControl
                                  sx={{ minWidth: "100%" }}
                                  size="small"
                                  disabled={!customerActive}
                                >
                                  <Select
                                    value={
                                      item.Country !== ""
                                        ? item.Country
                                        : "Country"
                                    }
                                    onChange={(e) => {
                                      const destinationCT = [
                                        ...objRegistrationForm.DestinationCountries,
                                      ];
                                      destinationCT[index].Country =
                                        e.target.value;
                                      setObjRegistrationForm({
                                        ...objRegistrationForm,
                                        DestinationCountries: destinationCT,
                                      });
                                      // let obj = Object.assign(
                                      //   {},
                                      //   objRegistrationForm
                                      // );
                                      // let destinationCT =
                                      //   objRegistrationForm.DestinationCountries.map(
                                      //     (val) => {
                                      //       if (val.Id === item.Id) {
                                      //         val.Country = e.target.value;
                                      //       }
                                      //       return val;
                                      //     }
                                      //   );
                                      // obj.DestinationCountries = destinationCT;
                                      // setObjRegistrationForm(obj);
                                    }}
                                  >
                                    <MenuItem
                                      value={
                                        item.Country !== ""
                                          ? item.Country
                                          : "Country"
                                      }
                                      disabled
                                    >
                                      Select Country
                                    </MenuItem>
                                    {listShippingCountry.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <FormControl
                                  sx={{ minWidth: "100%" }}
                                  size="small"
                                  disabled={!customerActive}
                                >
                                  <Select
                                    value={
                                      item.LocationType !== ""
                                        ? item.LocationType
                                        : "Select Location Type"
                                    }
                                    onChange={(e) => {
                                      const destinationCT = [
                                        ...objRegistrationForm.DestinationCountries,
                                      ];
                                      destinationCT[index].LocationType =
                                        e.target.value;
                                      setObjRegistrationForm({
                                        ...objRegistrationForm,
                                        DestinationCountries: destinationCT,
                                      });
                                      // let obj = Object.assign(
                                      //   {},
                                      //   objRegistrationForm
                                      // );
                                      // let destinationCT =
                                      //   objRegistrationForm.DestinationCountries.map(
                                      //     (val) => {
                                      //       if (val.Id === item.Id) {
                                      //         val.LocationType = e.target.value;
                                      //       }
                                      //       return val;
                                      //     }
                                      //   );
                                      // obj.DestinationCountries = destinationCT;
                                      // setObjRegistrationForm(obj);
                                    }}
                                  >
                                    <MenuItem
                                      value={
                                        item.LocationType !== ""
                                          ? item.LocationType
                                          : "Select Location Type"
                                      }
                                      disabled
                                    >
                                      Select Location Type
                                    </MenuItem>
                                    {listLocationType.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center" sx={{ width: "10%" }}>
                                <HighlightOffIcon
                                  style={{
                                    cursor: customerActive
                                      ? "pointer"
                                      : "no-drop",
                                  }}
                                  onClick={() => {
                                    if (customerActive) {
                                      let obj = Object.assign(
                                        {},
                                        objRegistrationForm
                                      );
                                      obj.DestinationCountries =
                                        objRegistrationForm.DestinationCountries.filter(
                                          (val, idx) => idx !== index
                                        );
                                      // if (
                                      //   obj.DestinationCountries.length === 0
                                      // ) {
                                      //   obj.DestinationCountries = [
                                      //     {
                                      //       Id: "",
                                      //       Country: "",
                                      //       LocationType: "",
                                      //     },
                                      //   ];
                                      // }
                                      setObjRegistrationForm(obj);
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}

                    {objRegistrationForm.DestinationCountries.length === 0 && (
                      <TableRow>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ width: "5%" }}
                        >
                          1
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          <FormControl
                            sx={{ minWidth: "100%" }}
                            size="small"
                            disabled={!customerActive}
                          >
                            <Select
                              value=""
                              onChange={(e) => {
                                const destinationCT = [
                                  {
                                    ...objRegistrationForm
                                      .DestinationCountries[0],
                                    Country: e.target.value,
                                  },
                                ];
                                setObjRegistrationForm({
                                  ...objRegistrationForm,
                                  DestinationCountries: destinationCT,
                                });
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Country
                              </MenuItem>
                              {listShippingCountry.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.label}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">
                          <FormControl
                            sx={{ minWidth: "100%" }}
                            size="small"
                            disabled={!customerActive}
                          >
                            <Select
                              value=""
                              onChange={(e) => {
                                const destinationCT = [
                                  {
                                    ...objRegistrationForm
                                      .DestinationCountries[0],
                                    Country: e.target.value,
                                  },
                                ];
                                setObjRegistrationForm({
                                  ...objRegistrationForm,
                                  DestinationCountries: destinationCT,
                                });
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Location Type
                              </MenuItem>
                              {listLocationType.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.label}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "10%" }}
                        ></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* add row */}
              {customerActive ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  {objRegistrationForm.DestinationCountries &&
                    objRegistrationForm.DestinationCountries.map(
                      (item, index) => {
                        return (
                          <div key={index}>
                            {objRegistrationForm.DestinationCountries.length -
                              1 ===
                            index ? (
                              <>
                                {objRegistrationForm.DestinationCountries
                                  .length === 1 ? (
                                  <>
                                    <Box
                                      sx={{
                                        ml: 1,
                                        height: 35,
                                        visibility: "hidden",
                                      }}
                                    />
                                    <AddCircleOutlineIcon
                                      color="success"
                                      sx={{
                                        cursor: "pointer",
                                        ml: 1,
                                        height: 55,
                                      }}
                                      onClick={() => {
                                        let obj = Object.assign(
                                          {},
                                          objRegistrationForm
                                        );
                                        if (
                                          obj.DestinationCountries[
                                            obj.DestinationCountries.length - 1
                                          ].Country !== "" &&
                                          obj.DestinationCountries[
                                            obj.DestinationCountries.length - 1
                                          ].LocationType !== ""
                                        ) {
                                          obj.DestinationCountries.push({
                                            Id: "",
                                            Country: "",
                                            LocationType: "",
                                          });
                                          setObjRegistrationForm(obj);
                                        }
                                      }}
                                    />
                                  </>
                                ) : (
                                  <AddCircleOutlineIcon
                                    key={index}
                                    color="success"
                                    sx={{
                                      cursor: "pointer",
                                      ml: 1,
                                      height: 55,
                                    }}
                                    onClick={() => {
                                      let obj = Object.assign(
                                        {},
                                        objRegistrationForm
                                      );
                                      if (
                                        obj.DestinationCountries[
                                          obj.DestinationCountries.length - 1
                                        ].Country !== "" &&
                                        obj.DestinationCountries[
                                          obj.DestinationCountries.length - 1
                                        ].LocationType !== ""
                                      ) {
                                        obj.DestinationCountries.push({
                                          Id: "",
                                          Country: "",
                                          LocationType: "",
                                        });
                                        setObjRegistrationForm(obj);
                                      }
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {index === 0 ? (
                                  <Box
                                    sx={{
                                      ml: 1,
                                      height: 85,
                                      visibility: "hidden",
                                    }}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      ml: 1,
                                      height: 55,
                                      visibility: "hidden",
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        );
                      }
                    )}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {/* row 4  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              p: 1,
              m: 1,
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">
              (For Customer) Which company do customers purchase this product
              from?{" "}
              <Tooltip
                title="If a customer buys a product from more than one company, use a semicolon (;) before inputting the next company."
                placement="top"
              >
                <ErrorIcon sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <TextInput
              placeholder="Number of resgistration issues"
              value={objRegistrationForm.PurchaseProductFromCompany}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.PurchaseProductFromCompany = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={!customerActive}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              (For Supplier) Which company do you supply this product to? (If
              any)
              <Tooltip
                title="If a customer buys a product from more than one company, use a semicolon (;) before inputting the next company."
                placement="top"
              >
                <ErrorIcon sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <TextInput
              placeholder="Number of resgistration issues"
              value={objRegistrationForm.SupplyProductToCompany}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.SupplyProductToCompany = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={!supplierActive}
            />
          </Box>
        </Box>
        {/* row 5 checkbox tank storage */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          {/* Customer’s Facility */}
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Customer’s Facility (Tank and Storage)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Own storage facility"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.OwnStorageAsCustomer}
                value={objRegistrationForm.OwnStorageAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.OwnStorageAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent storage facility for one-year contract or more"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentStorageMoreThanOneYearAsCustomer
                }
                value={objRegistrationForm.RentStorageMoreThanOneYearAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentStorageMoreThanOneYearAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent storage facility less than one-year contract"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentStorageLessThanOneYearAsCustomer
                }
                value={objRegistrationForm.RentStorageLessThanOneYearAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentStorageLessThanOneYearAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />

              <FormControlLabel
                control={<Checkbox size="small" />}
                label="No storage facility"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.NoStorageAsCustomer}
                value={objRegistrationForm.NoStorageAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.NoStorageAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
            </Box>
          </Box>
          {/* Supplier’s Facility */}
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Supplier’s Facility (Tank and Storage)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Own storage facility"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.OwnStorageAsSupplier}
                value={objRegistrationForm.OwnStorageAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.OwnStorageAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent storage facility for one-year contract or more"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentStorageMoreThanOneYearAsSupplier
                }
                value={objRegistrationForm.RentStorageMoreThanOneYearAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentStorageMoreThanOneYearAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent storage facility less than one-year contract"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentStorageLessThanOneYearAsSupplier
                }
                value={objRegistrationForm.RentStorageLessThanOneYearAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentStorageLessThanOneYearAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="No storage facility"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.NoStorageAsSupplier}
                value={objRegistrationForm.NoStorageAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.NoStorageAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
            </Box>
          </Box>
        </Box>
        {/* row 6 checkbox transportation */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          {/* Customer’s Facility */}
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Customer’s Facility (Transportation)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Own transportation"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.OwnTransportAsCustomer}
                value={objRegistrationForm.OwnTransportAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.OwnTransportAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent transportation for one-year contract or more"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentTransportMoreThanOneYearAsCustomer
                }
                value={
                  objRegistrationForm.RentTransportMoreThanOneYearAsCustomer
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentTransportMoreThanOneYearAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent transportation less than one-year contract"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentTransportLessThanOneYearAsCustomer
                }
                value={
                  objRegistrationForm.RentTransportLessThanOneYearAsCustomer
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentTransportLessThanOneYearAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />

              <FormControlLabel
                control={<Checkbox size="small" />}
                label="No transportation"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.NoTransportAsCustomer}
                value={objRegistrationForm.NoTransportAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.NoTransportAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              />
            </Box>
          </Box>
          {/* Supplier’s Facility */}
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Supplier’s Facility (Transportation)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Own transportation"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.OwnTransportAsSupplier}
                value={objRegistrationForm.OwnTransportAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.OwnTransportAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent transportation for one-year contract or more"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentStorageMoreThanOneYearAsSupplier
                }
                value={objRegistrationForm.RentStorageMoreThanOneYearAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentStorageMoreThanOneYearAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Rent transportation less than one-year contract"
                sx={{ width: "100%" }}
                checked={
                  objRegistrationForm.RentTransportLessThanOneYearAsSupplier
                }
                value={
                  objRegistrationForm.RentTransportLessThanOneYearAsSupplier
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.RentTransportLessThanOneYearAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />

              <FormControlLabel
                control={<Checkbox size="small" />}
                label="No transportation"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.NoTransportAsSupplier}
                value={objRegistrationForm.NoTransportAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.NoTransportAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>

      {/* Product Type Of Interest */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            ESG and Compliance
          </Typography>
        </Box>
        {/* row */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <b>Enviorment: </b> Does your company have Environment policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.EnvironmentPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.EnvironmentPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.EnvironmentPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  sx={{ width: 150 }}
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <b>Social Responsibility: </b>Does your company have Social
              Responsibility policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.SocialResponsibilityPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.SocialResponsibilityPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.SocialResponsibilityPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <b>Governance: </b>Does your company have Corporate Governance
              policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.CorporateGovernancePolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CorporateGovernancePolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.CorporateGovernancePolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <b>Occupational Health and Safety: </b>Does your company have
              Occupational Health and Safety policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.OccupationalHealthAndSafetyPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.OccupationalHealthAndSafetyPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.OccupationalHealthAndSafetyPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <b>Human Rights: </b>Does your company have Human Rights policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.HumanRightsPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.HumanRightsPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.HumanRightsPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {/* Has 1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Has your company or any of its affiliates, or any current
              director, officer, or key employee ever been charged with, or
              investigated for fraud, misrepresentation, corruption, bribery,
              money laundering, forced labour, environmental issues or other
              related activities during the past five years?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.ViolatingAntiMoneyLaunderingLaws}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ViolatingAntiMoneyLaunderingLaws = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.ViolatingAntiMoneyLaunderingLaws}
                name="radio-group-transection-cc1"
                // sx={{ justifyContent: "right" }}
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            sx={{ width: "95%" }}
            placeholder="Reason Of Violating AntiMoney Laundering Laws"
            value={objRegistrationForm.ReasonOfViolatingAntiMoneyLaunderingLaws}
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfViolatingAntiMoneyLaunderingLaws = e.target.value;
              setObjRegistrationForm(obj);
            }}
          />
        </Box>
        {/* USD 2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              (For Customer) Has your company ever had any default payment over
              the amount of USD 50,000 in the last 3 years?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.PaymentOverTheAmountOfUSD}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.PaymentOverTheAmountOfUSD = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={!customerActive}
            >
              <RadioGroup
                row
                value={objRegistrationForm.PaymentOverTheAmountOfUSD}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            sx={{ width: "95%" }}
            placeholder="Reason Of Payment Over The Amount Of USD"
            value={objRegistrationForm.ReasonOfPaymentOverTheAmountOfUSD}
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfPaymentOverTheAmountOfUSD = e.target.value;
              setObjRegistrationForm(obj);
            }}
            disabled={!customerActive}
          />
        </Box>
        {/* Failed 1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              (For Supplier) Has your company ever failed to supply under any
              agreement in the last 3 years? (Please specify the reason of the
              default)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.FailedSupplyAgreement}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.FailedSupplyAgreement = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={!supplierActive}
            >
              <RadioGroup
                row
                value={objRegistrationForm.FailedSupplyAgreement}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            sx={{ width: "95%" }}
            placeholder="Reason Of Failed Supply Agreement"
            value={objRegistrationForm.ReasonOfFailedSupplyAgreement}
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfFailedSupplyAgreement = e.target.value;
              setObjRegistrationForm(obj);
            }}
            disabled={!supplierActive}
          />
        </Box>
        {/* Failed 1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Does your company have any offices, transactions, investments,
              activities or planned activities in sanctioned targets or any
              transactions, investments, activities or planned activities with
              (or in) any person or entity located in sanctioned targets or with
              (or in) any entity owned or controlled by any entity located in
              sanctioned targets*?
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: 10 }}>
              *A sanctioned target means any party that is a target of U.S., EU,
              UK or UN sanctions or to your knowledge, any other sanctions
              authority relevant to your company such as SDN list (Please
              specify the reason of the default)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.HaveTagetsInSanction}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.HaveTagetsInSanction = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.HaveTagetsInSanction}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            sx={{ width: "95%" }}
            placeholder="Reason Of Have Targets in Sanction"
            value={objRegistrationForm.ReasonOfHaveTagetsInSanction}
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfHaveTagetsInSanction = e.target.value;
              setObjRegistrationForm(obj);
            }}
          />
        </Box>
      </CardContent>

      {/* visible submit update btn */}
      <button
        id="check-form-data-register"
        style={{
          visibility: "hidden",
        }}
        onClick={() => {
          let checksRequired = isValidate();
          if (checksRequired.active) {
            setRequired({
              fields: checksRequired.fields,
              active: checksRequired.active,
            });
          } else {
            props.formData(objRegistrationForm);
          }
        }}
      >
        submit
      </button>
      <button
        id="check-form-data-register-save-draft"
        style={{
          visibility: "hidden",
        }}
        onClick={() => props.formDataDraft(objRegistrationForm)}
      >
        update
      </button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={required.active}
        autoHideDuration={6000}
        onClose={() => {
          setRequired({
            fields: "",
            active: false,
          });
        }}
      >
        <Alert
          onClose={() => {
            setRequired({
              fields: "",
              active: false,
            });
          }}
          variant="filled"
          severity="warning"
        >
          Required Fields <b> {required.fields}</b>
        </Alert>
      </Snackbar>
    </Card>
  );
}
