import _ from "lodash";
import sign from "jwt-encode";
import jwt_decode from "jwt-decode";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // Authen
  Auth: {
    getToken: () => {
      let mytoken = sessionStorage.getItem("token");
      if (mytoken) {
        return `Bearer ${mytoken}`;
      }
      return null;
    },
    setToken: (token) => {
      sessionStorage.setItem("token", token);
    },
    getLang: () => {
      let lang = sessionStorage.getItem("lang");
      if (lang) {
        return `${lang}`;
      }
      return null;
    },
    getTokenData: () => {
      try {
        let mytoken = sessionStorage.getItem("token");
        const decode = jwt_decode(mytoken);
        return decode;
      } catch (e) {
        return {};
      }
    },
    isLOGIN: () => {
      const token = window.sessionStorage.getItem("token");
      return !!token;
    },
    Logout: (link = "/") => {
      window.sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = link;
      }, 100);
    },
  },
  // config-web-app
  CONFIG: {
    MAX_SIZE_FILE: 1024000,
    IMG_TYPE_FILE: ["image/png", "image/jpeg", "image/jpg"],
  },
  // encode & decode JWT
  encodeJWT: (data = {}, secret = "secret") => {
    const encode = sign(data, secret);
    return encode;
  },
  decodeJWT: (token = "") => {
    const decode = jwt_decode(token);
    return decode;
  },
  // functional other
  FORMAT: {
    TEL: (txt = "") => {
      return txt.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
    },
  },
  SORT: {
    compare: (a, b, key) => {
      if (_.get(a, key) < _.get(b, key)) {
        return -1;
      }
      if (_.get(a, key) > _.get(b, key)) {
        return 1;
      }
      return 0;
    },
  },
  FN: {
    numberToABCTH: (number = 0) => {
      let letters = ["ก", "ข", "ค", "ง", "จ", "ฉ"];
      if (letters[number - 1]) {
        return letters[number - 1];
      }
      return "";
    },
    removeUndefined: (obj) => {
      Object.keys(obj).forEach((key) =>
        obj[key] === undefined || obj[key] === "" || obj[key] === "[]"
          ? delete obj[key]
          : {}
      );
      return obj;
    },
    range: (start, end) => {
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    arr1DToDownDown: (arr = []) => {
      let dd = [];
      for (let a of arr) {
        dd.push({ id: a, name: a + 543 });
      }
      return dd;
    },
    toNumberWithCommas: (x = "") => {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "0";
    },
    toArrayNumber: (arr = []) => {
      return arr.map((item) => Number(item)).filter((item) => item);
    },
    numberWithZero: (num = "", pad = 4) => {
      var str = "" + num;
      var padStr = "";
      for (let i = 0; i < pad; i++) {
        padStr += "0";
      }
      return padStr.substring(0, padStr.length - str.length) + str;
    },
    gotoPage: (url) => {
      if (url) window.open(url, "_blank");
    },
    splitText: (str = "", len = 255) => {
      // if (url) window.open(url, "_blank");
      if (str.length <= len) {
        return str;
      }
      return str.substring(0, len) + "...";
    },
    getQueryStringParams: (url) => {
      const queryString = url.split("?")[1];
      if (!queryString) return {};

      const paramsArray = queryString.split("&");
      const params = {};

      paramsArray.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });

      return params;
    },
    generateRandomNumber: (n = 10) => {
      return Math.floor(Math.random() * n);
    },
    zeroToDash(value) {
      if (value === 0 || !value) {
        return "-";
      }
      return value;
    },
  },
};
