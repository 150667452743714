import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { RegistrationFormAPI } from "../services/apigateway";
import { useParams } from "react-router";
import { useState } from "react";
import helper from "../services/helper";

import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";
import "../styles/Login.css";

export default function RequestEmailScreen() {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { register, handleSubmit } = useForm();
  const [emailValidate, setEmailValidate] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

  const onSubmit = async (data) => {
    if (verificationCode) {
      const res = await RegistrationFormAPI.verifyEmailValidation(data);
      if (res.token) {
        helper.Auth.setToken(res.token);
        navigate("/main")
      } else if (res.error) {
        setErrorMessage(res.error_description);
      } else {
        setErrorMessage("Cannot connect to server, please try again.")
      }
    } else {
      const res = await RegistrationFormAPI.requestEmailValidation(data);
      if (res.status) {
        setVerificationCode(res);
      }
    }
  };

  return (
    <div className="containerForm">
      <div style={{ textAlign: "center" }}>
        <label
          style={{
            fontWeight: 600,
            fontSize: 36,
            fontStyle: "italic",
          }}
        >
          Counterparty Registration
        </label>
      </div>

      <form
        className="form-login-request-email"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group">
          <div>
            <Typography
              variant="h6"
              component="h6"
              sx={{ textAlign: "center", paddingBottom: 2 }}
            >
              Verify Your Identity
            </Typography>
            <Typography variant="subtitle1">
              To open this secure link. we'll need you to enter the email you
              provided.
            </Typography>
          </div>
          <div className={
            (emailErrorMessage)
              ? "slds-form-element slds-has-error"
              : "slds-form-element"
          }>
            <label className="slds-form-element__label" htmlFor="email">
              Email Address
            </label>
            <div className="slds-form-element__control">
              <input 
                type="hidden" 
                id="accountId" 
                name="accountId" 
                value={accountId} 
                {...register("accountId")} 
              />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email Address"
                className="slds-input"
                disabled={verificationCode}
                required
                {...register("email", {
                  onChange: async (e) => {
                    const validateEmail = e.target.value.match(
                      // eslint-disable-next-line
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                    if (validateEmail) {
                      const res =
                        await RegistrationFormAPI.checkEmailValidation({
                          email: validateEmail[0],
                          accountId: accountId,
                        });
                      if (res.isExists) {
                        setEmailValidate(true);
                        setEmailErrorMessage("");
                      } else {
                        setEmailValidate(false);
                        if (res.error) {
                          if (res.error === "invalid_email") {
                            setEmailErrorMessage("Please provide a valid email address.");
                          } else {
                            setEmailErrorMessage(res.error_description);
                          }
                        }
                      }
                    } else {
                      setEmailValidate(false);
                      setEmailErrorMessage("Invalid an email format.");
                    }
                  },
                })}
              />
            </div>
            {emailErrorMessage && (
              <div className="slds-form-element__help">
                {emailErrorMessage}
              </div>
            )}
            {verificationCode ? (
              <>
                <br />
                <div className={
                    (errorMessage)
                        ? "slds-form-element slds-has-error"
                        : "slds-form-element"
                }>
                  <label className="slds-form-element__label" htmlFor="email">
                    Verification Code
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      id="verificationCode"
                      name="verificationCode"
                      placeholder="Verification Code"
                      className="slds-input"
                      required
                      {...register("verificationCode")}
                    />
                  </div>
                  {errorMessage && (
                      <div className="slds-form-element__help">
                          {errorMessage}
                      </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
          <div>
            <button
              className="slds-button slds-button_brand ctr-button_primary slds-size_1-of-1"
              disabled={!emailValidate}
            >
              {verificationCode ? "Submit" : "Get Verification"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
