import * as React from "react";
import {
    Backdrop,
    Box,
    CircularProgress,
    Card,
    CardContent,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { SharePointFolder } from "../services/apigateway";
import helper from "../services/helper";
import dayjs from "dayjs";


// const downloadFileFinancial = async (filename) => {
//     try {
//         const foldername = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
//         if (!filename) {
//             throw new Error('Filename is not defined');
//         }
//         const response = await SharePointFolder.downloadFile(foldername, filename);
//         console.log(response);

//         // const responseData = ["VGVzdA=="];
//         const responseData = response.responseData;
//         console.log(responseData);

//         if (responseData && responseData.length > 0) {
//             const mimeType = 'application/octet-stream';
//             const blob = new Blob([responseData], { type: mimeType });
//             const url = window.URL.createObjectURL(blob);
//             // const base64Data = responseData[0];
//             // const binaryData = atob(base64Data);
//             // const blob = new Blob([binaryData], { type: 'application/octet-stream' });
//             //const url = window.URL.createObjectURL(blob);

//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', filename);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }
//     } catch (ex) {
//         console.error(ex);
//     }
// };

// const downloadFileCOI = async (filename) => {
//     try {
//         const foldername = "Shared Documents/Account/Moke/Common/02 CTR/02 COI";
//         if (!filename) {
//             throw new Error('Filename is not defined');
//         }
//         const response = await SharePointFolder.downloadFile(foldername, filename);
//         console.log(response);

//         // Replace this responseData with the actual data received from the download API
//         const responseData = ["VGVzdA=="];

//         if (responseData && responseData.length > 0) {
//             const base64Data = responseData[0];
//             const binaryData = atob(base64Data);
//             const blob = new Blob([binaryData], { type: 'application/octet-stream' });
//             const url = window.URL.createObjectURL(blob);

//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', filename);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }
//     } catch (ex) {
//         console.error(ex);
//     }
// };

// const downloadFileMOA = async (filename) => {
//     try {
//         const foldername = "Shared Documents/Account/Moke/Common/02 CTR/03 MOA";
//         if (!filename) {
//             throw new Error('Filename is not defined');
//         }
//         const response = await SharePointFolder.downloadFile(foldername, filename);
//         console.log(response);

//         // Replace this responseData with the actual data received from the download API
//         const responseData = ["VGVzdA=="];

//         if (responseData && responseData.length > 0) {
//             const base64Data = responseData[0];
//             const binaryData = atob(base64Data);
//             const blob = new Blob([binaryData], { type: 'application/octet-stream' });
//             const url = window.URL.createObjectURL(blob);

//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', filename);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }
//     } catch (ex) {
//         console.error(ex);
//     }
// };

// const downloadFileShareholders = async (filename) => {
//     try {
//         const foldername = "Shared Documents/Account/Moke/Common/02 CTR/04 List of Shareholder";
//         if (!filename) {
//             throw new Error('Filename is not defined');
//         }
//         const response = await SharePointFolder.downloadFile(foldername, filename);
//         console.log(response);

//         // Replace this responseData with the actual data received from the download API
//         const responseData = ["VGVzdA=="];

//         if (responseData && responseData.length > 0) {
//             const base64Data = responseData[0];
//             const binaryData = atob(base64Data);
//             const blob = new Blob([binaryData], { type: 'application/octet-stream' });
//             const url = window.URL.createObjectURL(blob);

//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', filename);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }
//     } catch (ex) {
//         console.error(ex);
//     }
// };

export default function DocumentsScreen() {
    // const [listFinancialData, setListFinancialData] = useState([]);
    // const [listDataCOI, setListDataCOI] = useState([]);
    // const [listDataMOA, setListDataMOA] = useState([]);
    // const [listDataShareholders, setListDataShareholders] = useState([]);

    // useEffect(() => {
    //     const FinancialData = async () => {
    //         try {
    //             const folderName = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
    //             const response = await SharePointFolder.getFileByFolder(folderName);
    //             console.log(response);
    //             console.log(response.responseData[0].value[0].Name);
    //             console.log(response.responseData[0].value[0].TimeLastModified);
    //             const responseData = response && response.responseData ? response.responseData[0].value : [];
    //             if (Array.isArray(responseData)) {
    //                 const formattedData = responseData.map(file => ({
    //                     Name: file.Name,
    //                     TimeLastModified: file.TimeLastModified,
    //                     // Add other properties you want to display
    //                 }));
    //                 setListFinancialData(formattedData);
    //                 console.log(formattedData);
    //             } else {
    //                 console.error("Data structure from SharePoint is not as expected.");
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     FinancialData();

    //     const COIData = async () => {
    //         try {
    //             const folderName = "Shared Documents/Account/Moke/Common/02 CTR/02 COI";
    //             const response = await SharePointFolder.getFileByFolder(folderName);
    //             console.log(response);
    //             console.log(response.responseData[0].value[0].Name);
    //             console.log(response.responseData[0].value[0].TimeLastModified);
    //             const responseData = response && response.responseData ? response.responseData[0].value : [];
    //             if (Array.isArray(responseData)) {
    //                 const formattedData = responseData.map(file => ({
    //                     Name: file.Name,
    //                     TimeLastModified: file.TimeLastModified,
    //                     // Add other properties you want to display
    //                 }));
    //                 setListDataCOI(formattedData);
    //                 console.log(formattedData);
    //             } else {
    //                 console.error("Data structure from SharePoint is not as expected.");
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     COIData();

    //     const MOAData = async () => {
    //         try {
    //             const folderName = "Shared Documents/Account/Moke/Common/02 CTR/03 MOA";
    //             const response = await SharePointFolder.getFileByFolder(folderName);
    //             console.log(response);
    //             console.log(response.responseData[0].value[0].Name);
    //             console.log(response.responseData[0].value[0].TimeLastModified);
    //             const responseData = response && response.responseData ? response.responseData[0].value : [];
    //             if (Array.isArray(responseData)) {
    //                 const formattedData = responseData.map(file => ({
    //                     Name: file.Name,
    //                     TimeLastModified: file.TimeLastModified,
    //                     // Add other properties you want to display
    //                 }));
    //                 setListDataMOA(formattedData);
    //                 console.log(formattedData);
    //             } else {
    //                 console.error("Data structure from SharePoint is not as expected.");
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     MOAData();
    //     const ShareholdersData = async () => {
    //         try {
    //             const folderName = "Shared Documents/Account/Moke/Common/02 CTR/04 List of Shareholder";
    //             const response = await SharePointFolder.getFileByFolder(folderName);
    //             console.log(response);
    //             console.log(response.responseData[0].value[0].Name);
    //             console.log(response.responseData[0].value[0].TimeLastModified);
    //             const responseData = response && response.responseData ? response.responseData[0].value : [];
    //             if (Array.isArray(responseData)) {
    //                 const formattedData = responseData.map(file => ({
    //                     Name: file.Name,
    //                     TimeLastModified: file.TimeLastModified,
    //                     // Add other properties you want to display
    //                 }));
    //                 setListDataShareholders(formattedData);
    //                 console.log(formattedData);
    //             } else {
    //                 console.error("Data structure from SharePoint is not as expected.");
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     ShareholdersData();
    // }, []);

    const [loading, setLoading] = React.useState(false);
    const [filesSharePoint, setFilesSharePoint] = useState([]);
    useEffect(() => {
        const getSharePointFiles = async (id) => {
            try {
                setLoading(true);
                const results = await SharePointFolder.getFileByAccount(id);
                const filesSharePoint = results.responseData;
    
                const fin = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Financial");
                const coi = filesSharePoint.find(item => item.Name === "Account_Common_CTR_COI");
                const moa = filesSharePoint.find(item => item.Name === "Account_Common_CTR_MOA");
                const shr = filesSharePoint.find(item => item.Name === "Account_Common_CTR_List_Sharehold");
                const vat = filesSharePoint.find(item => item.Name === "Account_Common_CTR_VAT_Reg");
                const moe = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Reg_by_MoE");
                const oth = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Others");
    
                fin?.Files?.sort(compareFilesSharePoint)?.reverse();
                coi?.Files?.sort(compareFilesSharePoint)?.reverse();
                moa?.Files?.sort(compareFilesSharePoint)?.reverse();
                shr?.Files?.sort(compareFilesSharePoint)?.reverse();
                vat?.Files?.sort(compareFilesSharePoint)?.reverse();
                moe?.Files?.sort(compareFilesSharePoint)?.reverse();
                oth?.Files?.sort(compareFilesSharePoint)?.reverse();
    
                setFilesSharePoint({
                    Account_Common_CTR_Financial: fin || [],
                    Account_Common_CTR_COI: coi || [],
                    Account_Common_CTR_MOA: moa || [],
                    Account_Common_CTR_List_Sharehold: shr || [],
                    Account_Common_CTR_VAT_Reg: vat || [],
                    Account_Common_CTR_Reg_by_MoE: moe || [],
                    Account_Common_CTR_Others: oth || [],
                });
            } catch(errors) {
                console.log(errors);
            } finally {
                setLoading(false);
            }
        }

        const compareFilesSharePoint = (a, b) => {
            if (a.TimeLastModified < b.TimeLastModified) {
                return -1;
            } else if (a.TimeLastModified > b.TimeLastModified) {
                return 1;
            }
            return 0;
        }

        const userInfo = helper.Auth.getTokenData()
        if (userInfo.forAccount) {
            getSharePointFiles(userInfo.forAccount);
        } else if (userInfo.accounts[0]) {
            getSharePointFiles(userInfo.accounts[0].Id);
        }
    }, []);

    const downloadFilesSharePoint = async (foldername, filename) => {
        try {
            if (!filename) {
                throw new Error('Filename is not defined');
            }

            const response = await SharePointFolder.downloadFile(foldername, filename);

            // Replace this responseData with the actual data received from the download API
            const responseData = response.responseData;

            if (responseData && responseData.length > 0) {
                const base64Data = responseData[0];
                // const binaryData = atob(base64Data);
                // const blob = new Blob([binaryData], { type: 'image/png' });
                // const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                // link.href = url;
                link.href = `data:application/octet-stream;base64,${base64Data}`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // window.URL.revokeObjectURL(url);
            }
        } catch (ex) {
            console.error(ex);
        }
    };

    return (
        <Card>
            <CardContent sx={{ minWidth: 900 }} >
                <Typography variant="h5" align="center">
                    Documents
                </Typography>

                {/* <Box sx={{ backgroundColor: "#dbd9d9" }}>
                    <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                        Financial Statements
                    </Typography>
                </Box> */}
                {/* row1 Table Financial Statements*/}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ mt: 2, width: "100%" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ width: "100%" }}
                                    size="small"
                                    aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#eeeeee" }}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <Typography variant="h6">
                                                    Financial Statements
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: 80 }}>
                                                No.
                                            </TableCell>
                                            <TableCell align="left">
                                                File Name
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 200 }}>
                                                Uploaded Date/Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filesSharePoint?.Account_Common_CTR_Financial?.Files
                                            && filesSharePoint.Account_Common_CTR_Financial.Files.map((file, index) => (
                                                <TableRow key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                downloadFilesSharePoint(filesSharePoint?.Account_Common_CTR_Financial?.Path, file.Name);
                                                            }}
                                                        >
                                                            {file.Name}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="center">{dayjs(file.TimeLastModified).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>

                {/* <Box sx={{ backgroundColor: "#dbd9d9" }}>
                    <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                        Certificate of Incorporation (COI)
                    </Typography>
                </Box> */}
                {/* row2 Table Certificate of Incorporation (COI)*/}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ mt: 4, width: "100%" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ width: "100%" }}
                                    size="small"
                                    aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#eeeeee" }}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <Typography variant="h6">
                                                    Certificate of Incorporation (COI)
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: 80 }}>
                                                No.
                                            </TableCell>
                                            <TableCell align="left">
                                                File Name
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 200 }}>
                                                Uploaded Date/Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filesSharePoint?.Account_Common_CTR_COI?.Files
                                            && filesSharePoint.Account_Common_CTR_COI.Files.map((file, index) => (
                                                <TableRow key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                downloadFilesSharePoint(filesSharePoint?.Account_Common_CTR_COI?.Path, file.Name);
                                                            }}
                                                        >
                                                            {file.Name}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="center">{dayjs(file.TimeLastModified).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>

                {/* <Box sx={{ backgroundColor: "#dbd9d9", m: 3 }}>
                    <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                        Memorandum of Association (MOA)
                    </Typography>
                </Box> */}
                {/* row3 Table Memorandum of Association (MOA)*/}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ mt: 4, width: "100%" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ width: "100%" }}
                                    size="small"
                                    aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#eeeeee" }}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <Typography variant="h6">
                                                    Memorandum of Association (MOA)
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: 80 }}>
                                                No.
                                            </TableCell>
                                            <TableCell align="left">
                                                File Name
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 200 }}>
                                                Uploaded Date/Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filesSharePoint?.Account_Common_CTR_MOA?.Files
                                            && filesSharePoint.Account_Common_CTR_MOA.Files.map((file, index) => (
                                                <TableRow key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                downloadFilesSharePoint(filesSharePoint?.Account_Common_CTR_MOA?.Path, file.Name);
                                                            }}
                                                        >
                                                            {file.Name}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="center">{dayjs(file.TimeLastModified).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>

                {/* <Box sx={{ backgroundColor: "#dbd9d9", m: 3 }}>
                    <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                        List of Shareholders (Bor. Or. Jor. 5)
                    </Typography>
                </Box> */}
                {/* row4 Table List of Shareholders (Bor. Or. Jor. 5)*/}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ mt: 4, width: "100%" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ width: "100%" }}
                                    size="small"
                                    aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#eeeeee" }}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <Typography variant="h6">
                                                    List of Shareholders (บอจ. 5)
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: 80 }}>
                                                No.
                                            </TableCell>
                                            <TableCell align="left">
                                                File Name
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 200 }}>
                                                Uploaded Date/Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filesSharePoint?.Account_Common_CTR_List_Sharehold?.Files
                                            && filesSharePoint.Account_Common_CTR_List_Sharehold.Files.map((file, index) => (
                                                <TableRow key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        <a
                                                            href="#!"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                downloadFilesSharePoint(filesSharePoint?.Account_Common_CTR_List_Sharehold?.Path, file.Name);
                                                            }}
                                                        >
                                                            {file.Name}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="center">{dayjs(file.TimeLastModified).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
                {loading && (
                    <Backdrop
                    sx={{ color: '#fff', zIndex: 2 }}
                    open={true}
                    >
                    <span
                        style={{
                        justifyContent: "center",
                        position: "fixed",
                        top: "40%",
                        }}
                    >
                        <Box
                        sx={{ p: 2, m: 2, justifyContent: "center", display: "flex" }}
                        >
                        <CircularProgress
                            style={{
                            width: 100,
                            height: 100,
                            color: "rgba(184,0,200,1)",
                            }}
                        />
                        </Box>
                        <Box sx={{ justifyContent: "center", display: "flex" }}>
                        <label style={{ fontSize: 22, fontWeight: "bold" }}>
                            {" "}
                            Please wait...
                        </label>
                        </Box>
                    </span>
                    </Backdrop>
                )}
            </CardContent>
        </Card>
    );
}