import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Backdrop from '@mui/material/Backdrop';
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import TermAndCondition from "../components/RegisterForm/TermCondition";
import RegistrationForm from "../components/RegisterForm/RegistrationForm";
import ModalRegistrationForm from "../components/RegisterForm/Modal/ModalRegistrationForm";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { RegistrationFormAPI } from "../services/apigateway";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(21, 104, 60 ) 0%, rgba(21, 104, 60 ) 43%, rgba(21, 104, 60 ) 100%);",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(21, 104, 60 ) 0%, rgba(21, 104, 60 ) 43%, rgba(21, 104, 60 ) 100%);",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      style={{
        color: completed || active ? "#fff" : "black",
        backgroundColor: "white",
        border: "1px solid #b5b5b5",
      }}
    >
      {completed ? <CheckIcon /> : <>{props.icon}</>}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Term & Conditions", "Registration Form"];

export default function RegisterScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState(location?.state?.registration?.Step || 0);
  const [openModal, setOpenModal] = React.useState(false);
  const [policy, setPolicy] = React.useState(null);
  // const [requestId, setRequestId] = React.useState("");
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const renderFormRegister = (page) => {
    switch (page) {
      case 0:
        return (
          <TermAndCondition
            policyData={(data) => setPolicy(data)}
            data={policy}
          />
        );
      case 1:
        return (
          <RegistrationForm
            formData={(data) => submitRequestFormByIds(data)}
            formDataDraft={(data) => updateRequestFormByIds(data)}
            formId={location?.state?.registration?.Id || 0}
            // formId={"a3Y1m000000B23oEAC"}
            loading={(e) => setLoading(e)}
          />
        );
      default:
        return <></>;
    }
  };

  const updateRequestFormByIds = async (data) => {
    let dataUpdate =  data;

    if (dataUpdate) {
      Swal.fire({
        title: "Do you want to save draft form?",
        icon: "question",
        position: "center",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        cancelButtonColor: "red",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);

          dataUpdate.AgreeToCookiePolicy = true;
          dataUpdate.AgreeToPrivacyPolicy = true;
          dataUpdate.Step = activeStep;
          console.log('dataUpdate',dataUpdate)
          const res = await RegistrationFormAPI.updateRequestForm(dataUpdate);
          console.log("updateRequestForm :: ", res);
          setLoading(false);
          if (res.error_description) {
            Swal.fire({
              title: res.error_description,
              icon: "error",
              // showConfirmButton: false,
              // timer: 1200,
            });
          } else {
            Swal.fire({
              title: "Saved!",
              icon: "success",
              showConfirmButton: false,
              timer: 1200,
            });
          }
        }
        setLoading(false);
      });
    }

    document.getElementById("check-form-data-register-save-draft").click();
  };

  const submitRequestFormByIds = async (data) => {
    let dataFinal = data;
    if (dataFinal) {
      Swal.fire({
        title: "Do you want to submit form?",
        icon: "question",
        position: "center",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        cancelButtonColor: "red",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          
          dataFinal.AgreeToCookiePolicy = true;
          dataFinal.AgreeToPrivacyPolicy = true;
          const res = await RegistrationFormAPI.submitRequestForm(dataFinal);
          if (res) {
            setLoading(false);
            if (res.error_description) {
              Swal.fire({
                title: res.error_description,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              // setRequestId(res.RequestFormHeaderId);
              setRegistrationNumber(res.RegistrationNumber);
              setOpenModal(true);
            }
          }
        }
        setLoading(false);
      });
    }
    document.getElementById("check-form-data-register").click();
  };

  return (
    <>
      <Stack sx={{ width: "100%", margin: 5 }} spacing={4}>
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 24,
          }}
        >
          COUNTERPARTY REGISTRATION
        </Typography>
        <Box sx={{ pt: 5 }}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box>{renderFormRegister(activeStep)}</Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: 2 }}
              open={true}
            >
              <span
                style={{
                  justifyContent: "center",
                  position: "fixed",
                  top: "40%",
                }}
              >
                <Box
                  sx={{ p: 2, m: 2, justifyContent: "center", display: "flex" }}
                >
                  <CircularProgress
                    lo
                    style={{
                      width: 100,
                      height: 100,
                      color: "rgba(184,0,200,1)",
                    }}
                  />
                </Box>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <label style={{ fontSize: 22, fontWeight: "bold" }}>
                    {" "}
                    Please wait...
                  </label>
                </Box>
              </span>
            </Backdrop>
          )}

          <Button
            sx={{ p: 1, m: 1 }}
            variant="outlined"
            color="error"
            disabled={loading}
            onClick={() => {
              // setActiveStep(activeStep > 0 ? activeStep - 1 : 0);
              Swal.fire({
                title: "Do you want to leave this page?",
                icon: "question",
                position: "center",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/main");
                }
              });
            }}
          >
            Cancel
          </Button>
          {activeStep > 0 ? (
            <Button
              sx={{ p: 1, m: 1 }}
              variant="outlined"
              disabled={loading}
              onClick={() => updateRequestFormByIds()}
            >
              Save
            </Button>
          ) : null}
          <Button
            sx={{ p: 1, m: 1 }}
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (activeStep === 1) {
                submitRequestFormByIds();
              } else {
                if (activeStep === 0) {
                  if (policy && policy.cookies && policy.policy) {
                    setActiveStep(
                      activeStep > steps.length - 2
                        ? activeStep
                        : activeStep + 1
                    );
                  }else {
                    let textError = '';
                    if(!policy || (!policy?.cookies && !policy?.policy)) {
                      textError = 'Please agree to the Cookie Policy and Privacy Policy'
                    }else if(!policy.cookies){
                      textError = 'Please agree to the Cookie Policy'
                    }else if(!policy.policy){
                      textError = 'Please agree to the Privacy Policy'
                    }
                    Swal.fire({
                      title: textError,
                      icon: "error",
                    });
                  }
                }
              }
            }}
          >
            {activeStep < 1 ? "Next" : "Submit"}
          </Button>
        </Box>
      </Stack>

      {/* modal registration-complete form */}
      <ModalRegistrationForm
        request={registrationNumber}
        open={openModal}
        onHide={() => setOpenModal(false)}
      />
    </>
  );
}
