import * as React from "react";
import { useParams } from "react-router";
// import { useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import { Box, Backdrop, CircularProgress } from "@mui/material";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dayjs from "dayjs";

import RegistrationForm from "../components/RequestView/RegistrationForm";
import RequiredDocument from "../components/RequestView/RequiredDocument";
import ApprovalStatus from "../components/RequestView/ApprovalStatus";

import { RequestFormAPI } from '../services/apigateway';

import "../styles/RequestView.css";

export default function RequestViewScreen() {
  const { id } = useParams();
  // const navigate = useNavigate();

  const [lastModifiedDate, setLastModifiedDate] = React.useState();
  const [requestNumber, setRequestNumber] = React.useState();
  const [currentStatus, setCurrentStatus] = React.useState("Review");
  const [activeTab, setActiveTab] = React.useState("RegistrationForm");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (id)
      masterDataRegistrationForm(id);
  }, [id]);

  const masterDataRegistrationForm = async (id) => {
    setLoading(true);
    const data = await RequestFormAPI.getById(id);
    setLastModifiedDate(
      dayjs(data.RequestItems[0].LastModifiedDate).format("DD/MM/YYYY HH:mm")
    );
    setRequestNumber(data.RequestItems[0]?.Name);
    setCurrentStatus(data.RequestItems[0]?.StatusCustomer);
    setLoading(false);
  };

  const updateRequestFormByIds = async (data) => {
    let dataUpdate = data;
    Swal.fire({
      title: "Save draft has been saved",
      icon: "question",
      position: "center",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Save",
      cancelButtonColor: "red",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(dataUpdate);
        Swal.fire({
          title: "Saved!",
          icon: "success",
          showConfirmButton: false,
          timer: 1200,
        });
      }
    });
  };

  const handleClickTab = (event) => {
    setActiveTab(event.currentTarget.dataset.tab)
  }

  return (
    <Container maxWidth="xl" className="RequestViewScreen">
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={true} >
          <span style={{ justifyContent: "center", position: "fixed", top: "40%", }} >
            <Box sx={{ p: 2, m: 2, justifyContent: "center", display: "flex" }} >
              <CircularProgress
                style={{
                  width: 100,
                  height: 100,
                  color: "rgba(184,0,200,1)",
                }}
              />
            </Box>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <label style={{ fontSize: 22, fontWeight: "bold" }}>
                {" "}
                Please wait...
              </label>
            </Box>
          </span>
        </Backdrop>
      )}
      <div className="header">
        <div>
          <label>Request Number</label>
          <p>{requestNumber}</p>
        </div>
        <div>
          <label>Last Update Date</label>
          <p>{lastModifiedDate}</p>
        </div>
      </div>
      <div className="path">
        <ApprovalStatus currentStatus={currentStatus} />
      </div>
      <div style={{ "display": "flex", "marginTop": "10px" }}>
        <div className="sidebar">
          <nav className="slds-nav-vertical" aria-label="Sub page">
            <div className="slds-nav-vertical__section">
              <ul aria-describedby="entity-header">
                <li
                  className={activeTab === "RegistrationForm"
                    ? "slds-nav-vertical__item slds-is-active"
                    : "slds-nav-vertical__item"}
                  onClick={handleClickTab}
                  data-tab="RegistrationForm"
                >
                  <span className="slds-nav-vertical__action">
                    Registration Form
                  </span>
                </li>
                <li
                  className={activeTab === "RequiredDocuments"
                    ? "slds-nav-vertical__item slds-is-active"
                    : "slds-nav-vertical__item"}
                  onClick={handleClickTab}
                  data-tab="RequiredDocuments"
                >
                  <span className="slds-nav-vertical__action">
                    Required Documents
                  </span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div style={{ background: "white", flexGrow: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            {
              activeTab === "RegistrationForm" &&
              (
                <RegistrationForm
                  formDataDraft={(data) => updateRequestFormByIds(data)}
                  formId={id}
                  loading={(e) => setLoading(e)}
                />
              )
            }
            {
              activeTab === "RequiredDocuments" &&
              (
                <RequiredDocument
                  formDataDraft={(data) => updateRequestFormByIds(data)}
                  formId={id}
                />
              )
            }
          </Stack>
        </div>
      </div>
    </Container>
  );
}