import helper from "./helper";

export const AuthAPI = {
  refreshTokenBeforeExpiresIn: async function () {
    const ctrUser = helper.Auth.getTokenData();
    const expiry = new Date(ctrUser.exp * 1000);
    const today = new Date();
    const remain = expiry - today;

    if (
      remain > 0 &&
      remain <= process.env.REACT_APP_JWT_REFRESH_BEFORE_EXPIRES * 1000
    ) {
      const result = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/refreshtoken",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );
      const obj = await result.json();
      helper.Auth.setToken(obj.token);
    }
  },
};

export const CTRUserAPI = {
  login: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  changePassword: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/changepassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  resetPassword: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/sendemailresetpassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  initialPassword: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/initialpassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  findUsernameByEmail: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/findusernamebyemail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  findUsernameByResetKey: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/findusernamebyresetkey",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  findUsernameByInitialKey: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/user/findusernamebyinitialkey",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  verifySecurityQuestion: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/user/verifysecurityquestion",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
};

export const PreRegisterAPI = {
  preRegister: function () {
    return new Promise((resolve) => {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/preregister/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            EnglishName: document.getElementById("EnglishName").value,
            LocalName: document.getElementById("LocalName").value,
            SpecifyLanguage: document.getElementById("SpecifyLanguage").value,
            SpecifyLanguageOther: document.getElementById("SpecifyLanguageOther").value,
            FornerCompany: document.getElementById("FormerCompany").value,
            AliasEN: document.getElementById("AliasEN").value,
            CountryCode: document.getElementById("CountryCode").value,
            TAXNumber: document.getElementById("TAXNumber").value,
            Title: document.getElementById("Title").value,
            FirstName: document.getElementById("FirstName").value,
            LastName: document.getElementById("LastName").value,
            MobileCountryCode:
              document.getElementById("MobileCountryCode").value,
            Phone: document.getElementById("Phone").value,
            Email: document.getElementById("Email").value,
            Customer: document.getElementById("Customer").checked,
            CustomerCrude: document.getElementById("CustomerCrude").checked,
            PetroleumProducts:
              document.getElementById("PetroleumProducts").checked,
            PetrochemicalProducts: document.getElementById(
              "PetrochemicalProducts"
            ).checked,
            LubeBaseProducts:
              document.getElementById("LubeBaseProducts").checked,
            Products: document.getElementById("Products").value,
            LaBixProducts: document.getElementById("LaBixProducts").checked,
            InterestedLABIXProducts: document.getElementById(
              "InterestedLABIXProducts"
            ).value,
            OtherProducts: document.getElementById("OtherProducts").checked,
            InterestedOtherProducts: document.getElementById(
              "InterestedOtherProducts"
            ).value,
            Supplier: document.getElementById("Supplier").checked,
            SupplierCrude: document.getElementById("SupplierCrude").checked,
            Ethanol: document.getElementById("Ethanol").checked,
            Petroleum: document.getElementById("Petroleum").checked,
            InterestedPetroleumProducts: document.getElementById(
              "InterestedPetroleumProducts"
            ).value,
            NormalParaffin: document.getElementById("NormalParaffin").checked,
            OtherProductsSupplier: document.getElementById(
              "OtherProductsSupplier"
            ).checked,
            InterestedOtherProductsSupplier: document.getElementById(
              "InterestedOtherProductsSupplier"
            ).value,
          },
        ]),
      })
        .then((response) => {
          response.json().then((json) => {
            resolve(json);
          });
        })
        .catch((err) => {
          throw err;
        });
    });
  },
  getPicklist: async function (obj) {
    const response = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/util/select/getPicklist?objectApiName=" +
        obj.objectApiName +
        "&fieldApiName=" +
        obj.fieldApiName,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  },
  getCountry: async function (body) {
    const response = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/api/util/select/getCountry",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return response.json();
  },
};

export const AccountAPI = {
  findAll: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/account",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      throw ex;
    }
  },
};

export const RequestFormAPI = {
  findAll: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
  getById: async function (id) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request?id=" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },

  updateRequestForm: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
};

export const ExtensionFormAPI = {
  getById: async function (id) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request/extend?id=" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },
  create: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request/extend",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },
  update: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request/extend",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },
  submit: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/request/extend/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },
};

export const RegistrationFormAPI = {
  getRequestForm: async function (params) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/registration?id=" + params,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },

  getProvince: async function (params) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getProvince?country=" +
          params,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },

  getSaluationList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=ContactSalutation__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getMobileCodeList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=MobileCountryCode__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getSpecifyLanguages: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=SpecifyLanguagesOfCompanyName__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getCustomerType: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=CustomerType__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getSupplierType: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=SupplierType__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getBusinessType: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=TypeOfBusinessCommercialEvaluation__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getLegalEntity: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=LegalEntity__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getPhoneCodeList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=PhoneCountryCode__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getFaxCodeList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=FaxCountryCode__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getShippingCountryList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=CTRShippingCountry__c&fieldApiName=ShippingCountry__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getLocationTypeList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=CTRShippingCountry__c&fieldApiName=LocationType__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  getEstimatedMonthlyPurchaseUnit: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=EstimatedMonthlyPurchaseUnit__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },

  updateRequestForm: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/registration",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },

  submitRequestForm: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/registration/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },

  checkEmailValidation: async function (body) {
    try {
      // await AuthAPI.refreshTokenBeforeExpiresIn();

      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/temporary/email/check",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      return response.json();
    } catch (ex) {}
  },

  requestEmailValidation: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/temporary/code/request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      return response.json();
    } catch (ex) {}
  },

  verifyEmailValidation: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/temporary/code/verify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      return response.json();
    } catch (ex) {}
  },

  getRequestEmailValidation: async function (data) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/temporary/registration/pending",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: data,
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
};

export const UtilAPI = {
  getPicklist: async function (obj) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=" +
          obj.objectApiName +
          "&fieldApiName=" +
          obj.fieldApiName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify(body)
        }
      );
      return response.json();
    } catch (ex) {}
  },
  getMobileCountry: async function (obj) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=" +
          obj.objectApiName +
          "&fieldApiName=" +
          obj.fieldApiName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          //body: JSON.stringify(body)
        }
      );
      return response.json();
    } catch (ex) {}
  },
  getCountry: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/util/select/getCountry",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (ex) {}
  },
  getProvince: async function (params) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getProvince?country=" +
          params,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {
      console.log(ex);
    }
  },
};

export const SaleForceUploadFile = {
  uploadFile: async function (customerId, files) {
    try {
      const formData = new FormData();
      formData.append("id", customerId);

      // Append each file to the FormData object
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/uploadfile",
        {
          method: "POST",
          headers: {
            Authorization: helper.Auth.getToken(),
          },
          body: formData,
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.error(ex);
    }
  },
};

export const SharePointFolder = {
  getFileByFolder: async function (params) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/sharepoint/getfilebyfolder?foldername=" +
          params,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  },
  downloadFile: async function (foldername, filename) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/sharepoint/downloadfile?foldername=" +
          foldername +
          "&filename=" +
          filename,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  },

  uploadFile: async function (namefolder, files) {
    try {
      const formData = new FormData();
      formData.append("namefolder", namefolder);

      // Append each file to the FormData object
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/sharepoint/uploadfile",
        {
          method: "POST",
          headers: {
            Authorization: helper.Auth.getToken(),
          },
          body: formData,
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.error(ex);
    }
  },

  getFileByAccount: async function (accountId) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/sharepoint/getfilebyaccount?accountId=" +
          accountId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  },

  getPathByAccount: async function (accountId) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/sharepoint/getpathbyaccount?accountId=" +
          accountId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  },

  createMultipleFoldersByAccount: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/sharepoint/createmultiplefoldersbyaccount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  },
};

export const AdditionalInfoFormAPI = {
  getRequestForm: async function (params) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/additionalinfo?id=" + params,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
  getRequestFormByHeader: async function (params) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/additionalinfo/getbyheader?id=" + params,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
  updateRequestForm: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/additionalinfo",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
  submitRequestForm: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/additionalinfo/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return response.json();
    } catch (ex) {}
  },
  getMainServicesList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=MainService__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },
  getMainProductsList: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/util/select/getPicklist?objectApiName=Account&fieldApiName=MainProduct__c",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (ex) {}
  },
};

export const NotificationAPI = {
  findAll: async function () {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/notification",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
        }
      );

      if (response.status === 401) helper.Auth.Logout();

      return response.json();
    } catch (ex) {}
  },
  markAsRead: async function (body) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/notification/markasread",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: helper.Auth.getToken(),
          },
          body: JSON.stringify(body),
        }
      );

      if (response.status === 401) helper.Auth.Logout();
      else await AuthAPI.refreshTokenBeforeExpiresIn();

      return null;
    } catch (ex) {}
  },
};
