import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DateTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...props}
        value={props.value}
        onChange={(e) => props.onChange(e)}
        defaultValue={props.defaultValue ? props.defaultValue : dayjs}
        slotProps={{ textField: { size: "small", error: false } }}
      />
    </LocalizationProvider>
  );
}
