import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Typography,
  Button
} from "@mui/material";
import { useState } from 'react';
import TextInput from "../components/TextInput";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ModalPerformanceBondForm from "../components/PerformanceBond/Modal/ModalPerformanceBondForm";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";


export default function PerformanceBondScreen() {
  const [openModal, setOpenModal] = React.useState(false);
  const [isSubmitVerified, setSubmitVerified] = useState(false);
  const [reasonForReduce, setReasonForReduce] = useState('');

  const handleSubmitVerify = (event) => {
    const reason = event.target.value;
    setReasonForReduce(reason);
    setSubmitVerified(!!reason); // Enable submit if reason is not empty
  };

  const handleClear = () => {
    setReasonForReduce('');
    setSubmitVerified(false);
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
  }
  return (
    <Card sx={{ width: 1 }}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <h1 className="header-title">Reduce Performance Bond</h1>

          <Box sx={{ backgroundColor: "#dbd9d9", m: 3 }}>
            <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
              Performance Bond Condition
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: 'wrap',
              justifyContent: "start",
            }}
          >
            <Box sx={{ p: 1, m: 1, ml: 11, width: "100%", textAlign: "center" }}>
              <FormControl>
                <Typography variant="subtitle1" gutterBottom>
                  Current Performance Bond:
                  <FormControlLabel control={<TextInput sx={{ ml: 5, width: '500px', }} disabled={true} placeholder="XXXXX" />} />
                </Typography>
              </FormControl>
            </Box>
            <Box sx={{ p: 1, m: 1, width: "100%", textAlign: "center" }}>
              <FormControl>
                <Typography variant="subtitle1" gutterBottom>
                  Reason for Reduce Performance Bond:
                  <FormControlLabel control={<TextInput sx={{ ml: 5, width: '500px', }} placeholder="Input reason" onChange={handleSubmitVerify} value={reasonForReduce} />} />
                </Typography>
              </FormControl>
            </Box>
          </Box>

          {/* button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ p: 1, m: 1 }}
              variant="outlined"
              color="error"
              onClick={handleClear}
            >
              Cancel
            </Button>

            <Button
              sx={{ p: 1, m: 1 }}
              variant="outlined"
            >
              Save
            </Button>

            <Button
              sx={{ p: 1, m: 1 }}
              variant="contained"
              onClick={() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Success",
                  text: "Your request has been uploaded successfully.\nWe will proceed the next steps.",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                });
              }}
              disabled={!reasonForReduce || !isSubmitVerified}
            >
              Submit
            </Button>

          </Box>
          {/* modal registration-complete form */}
          <ModalPerformanceBondForm
            open={openModal}
            onHide={() => setOpenModal(false)}
          />
        </form>
      </CardContent>
    </Card>
  );
}