import * as React from "react";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";
import "../styles/Login.css";
import Alert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";

import helper from "../services/helper";
import { CTRUserAPI } from "../services/apigateway";

export default function LoginScreen() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const onSubmit = async (data) => {
    const loginResult = await CTRUserAPI.login(data);
    if (!loginResult) {
      return setErrorMessage(
        "Cannot connect to server, please try again."
      );
    }

    if (loginResult && loginResult.token) {
      helper.Auth.setToken(loginResult.token);
      navigate("/main");
    } else {
      return setErrorMessage(
        "Invalid username or password, please try again."
      );
    }
  };

  return (
    <div className="container-form">
      <div style={{ textAlign: "center" }}>
        <label className="container-form-heading">
          Registration of Interests
        </label>
      </div>

      <form className="form-login" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          {errorMessage && (
            <Alert icon={false} severity="error">
              {errorMessage}
            </Alert>
          )}
          <div className="slds-form-element">
            <label className="slds-form-element__label" htmlFor="username">
              Email Address
            </label>
            <div className="slds-form-element__control">
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Email Address"
                className="slds-input"
                required
                {...register("username")}
              />
            </div>
          </div>
          <div className="slds-form-element">
            <label className="slds-form-element__label" htmlFor="password">
              Password
            </label>
            <div className="slds-form-element__control">
              <input
                type={passwordShown ? "text" : "password"}
                id="password"
                name="password"
                placeholder="**********"
                className="slds-input"
                required
                {...register("password")}
              />
              <span onClick={() => setPasswordShown(!passwordShown)}>
                <i
                  className={
                    passwordShown
                      ? "bi bi-eye toggle-hide-pass"
                      : "bi bi-eye-slash toggle-hide-pass"
                  }
                  id="togglePassword"
                ></i>
              </span>
            </div>
          </div>
          <div>
            <button
              className="slds-button slds-button_brand ctr-button_primary slds-size_1-of-1"
            >
              Log In
            </button>
          </div>
          <hr className="hr" />
          <Link
            to="/forgot-password"
            style={{ textDecoration: "underline", fontWeight: 600, fontSize: "0.75rem" }}
          >
            Forgot Your Password?
          </Link>
          <Link
            to="/one-time-access"
            style={{ textDecoration: "underline", fontWeight: 600, fontSize: "0.75rem", marginTop: "0.25rem", display: "block" }}
          >
            Login via Verification Code
          </Link>
        </div>
        <Link
          to="/pre-register"
          className="slds-button slds-button_outline-brand ctr-button_primary-outline slds-size_1-of-1"
        >
          Register
        </Link>
      </form>
    </div>
  );
}
