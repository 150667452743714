import * as React from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

export default function LoginScreen() {
  return (
    <React.Fragment>
      <Header />
      <div className="App">
        <div className="App-header">
          Page Not Found
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}