import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

export default function TermAndCondition(props) {
  const [cookiePolicy, setCookiePolicy] = React.useState({
    cookies: props.data !== null ? props.data.cookies : false,
    policy: props.data !== null ? props.data.policy : false,
  });
  return (
    <Card>
      <CardContent>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", backgroundColor: "#dbd9d9" }}
        >
          &nbsp;Terms & Conditions
        </Typography>
      </CardContent>
      <CardContent>
        <Box sx={{ p: 1 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            1. The company represents and warrants that information provided
            hereunder is rightfully provided on behalf of the company and it is
            accurate and true at the time of the registration.
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            2. Thai Oil Public Company Limited reserves the right, at its sole
            discretion, to cancel or terminate the counter party registration if
            it finds the company is disqualified for the registration or the
            information provided is false or fraud, provided that such
            cancellation or termination of the registration shall not affect the
            rights and obligations of the parties under any existing
            transactions.
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            3. Additional information may be requested upon review.
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            4. Thai Oil and Subsidiaries are committed to respecting human
            rights principles as declared in the International Bill of Human
            Rights in all business activities across our value chain. We will
            take necessary actions to ensure that our employees, activities,
            products or services and business partners do not contribute to
            adverse human rights impacts. To that end, we have implemented a
            human rights due diligence process in line with the United Nations
            Guiding Principles on Business and Human Rights. Thai Oil's Business
            partners must follow international human rights standards. They
            should comply with the Policy and also extend these expectations to
            their business partners.
          </Typography>
          <br />
        </Box>
        <Box sx={{ p: 1 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            Click Link for more details
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            Thai Version:{" "}
            <a
              href="https://www.thaioilgroup.com/upload/content_file/202106291006_TOPBusinessandHumanRightsPolicyforSupplyChain_TH_Signed.pdf"
              style={{ textDecoration: "underline", color: "#02aef7" }}
              target="_blank"
              rel="noreferrer"
            >
              นโยบายด้านธุรกิจ และสิทธิมนุษยชนสำหรับลูกค้า
            </a>
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: "normal" }}
          >
            Eng Version:{" "}
            <a
              href="https://www.thaioilgroup.com/upload/content_file/202106291006_TOPBusinessandHumanRightsPolicyforSupplyChain_EN_Signed.pdf"
              style={{ textDecoration: "underline", color: "#02aef7" }}
              target="_blank"
              rel="noreferrer"
            >
              Business and Human Rights Policy for Supply Chain
            </a>
          </Typography>
        </Box>

        {/* cookies & privacy */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 1,
            m: 1,
            borderRadius: 1,
          }}
        >
          <FormGroup>
            <FormControlLabel
              value
              control={
                <Checkbox
                  size="small"
                  checked={cookiePolicy.cookies}
                  onChange={() => {
                    setCookiePolicy({
                      cookies: !cookiePolicy.cookies,
                      policy: cookiePolicy.policy,
                    });
                    props.policyData({
                      cookies: !cookiePolicy.cookies,
                      policy: cookiePolicy.policy,
                    });
                  }}
                />
              }
              label={
                <>
                  I agree to the{" "}
                  <a
                    href="https://www.thaioilgroup.com/home/cookiepolicy.aspx?lang=en"
                    style={{ textDecoration: "underline", color: "#02aef7" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookie Policy
                  </a>
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={cookiePolicy.policy}
                  onChange={() => {
                    setCookiePolicy({
                      cookies: cookiePolicy.cookies,
                      policy: !cookiePolicy.policy,
                    });
                    props.policyData({
                      cookies: cookiePolicy.cookies,
                      policy: !cookiePolicy.policy,
                    });
                  }}
                />
              }
              label={
                <>
                  I agree to the{" "}
                  <a
                    target="_blank"
                    href="https://www.thaioilgroup.com/home/policy_ms.aspx?id=568&lang=en"
                    style={{ textDecoration: "underline", color: "#02aef7" }}
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </>
              }
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
