import * as React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dayjs from "dayjs";
import RegistrationForm from "../components/RequestEdit/RegistrationForm";
import RequiredDocument from "../components/RequestEdit/RequiredDocument";
import ApprovalStatus from "../components/RequestEdit/ApprovalStatus";
import { RequestFormAPI, SharePointFolder } from "../services/apigateway";
import helper from "../services/helper";
import "../styles/RequestEdit.css";

export default function RequestEditScreen() {
  const navigate = useNavigate();

  const { id, tab } = useParams();
  const [lastModifiedDate, setLastModifiedDate] = React.useState();
  const [requestNumber, setRequestNumber] = React.useState();
  const [activeTab, setActiveTab] = React.useState("RegistrationForm");
  const [dataSaving, setDataSaving] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);
  const [uploadFileOther, setUploadFileOther] = React.useState([]);
  const [objRegistrationForm, setObjRegistrationForm] = React.useState({
    RequestFormHeaderId: "",
    RequestFormItemId: "",
    CustomerId: "",
    CompanyNameLocal: "",
    CompanyNameEN: "",
    CompanyNameFormer: "",
    CompanyNameLanguage: "",
    CompanyNameAlias: "",
    CustomerType: "",
    SupplierType: "",
    BusinessType: "",
    BusinessTypeOthers: "",
    LegalEntityType: "",
    LegalEntityTypeOthers: "",
    IncorporationDate: "",
    TAXNumber: "",
    Website: "",
    HouseNo: "",
    Building: "",
    Room: "",
    Floor: "",
    Street: "",
    District: "",
    SubDistrict: "",
    City: "a0L1m000004GpMOEA0",
    Country: "a0L1m000004GmiSEAS",
    PostalCode: "",
    LocationURL: "",
    Latitude: "",
    Longitude: "",
    MailingIsSameAsRegistered: false,
    MailingHouseNo: "",
    MailingBuilding: "",
    MailingRoom: "",
    MailingFloor: "",
    MailingStreet: "",
    MailingSubDistrict: "",
    MailingDistrict: "",
    MailingCity: "a0L1m000004GpMOEA0",
    MailingCountry: "a0L1m000004GmiSEAS",
    MailingPostalCode: "",
    ContactId: "",
    ContactSalutation: "",
    ContactNickName: "",
    ContactFirstName: "",
    ContactLastName: "",
    ContactPosition: "",
    ContactEmail: "",
    ContactMobileCountryCode: "TH",
    ContactMobileNumber: "",
    ContactPhoneCountryCode: "TH",
    ContactPhoneNumber: "",
    ContactFaxCountryCode: "TH",
    ContactFaxNumber: "",
    InterestedPetroleumAsCustomer: false,
    InterestedPetrochemicalAsCustomer: false,
    InterestedLubeBaseAsCustomer: false,
    InterestedLABIXAsCustomer: false,
    InterestedCrudeAsCustomer: false,
    InterestedLubeBaseAsCustomerDesc: "",
    InterestedLABIXAsCustomerDesc: "",
    InterestedCrudeAsSupplier: false,
    InterestedB100EthanolAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplierDesc: "",
    InterestedLongResidueAsSupplier: false,
    InterestedNormalParaffinAsSupplier: false,
    InterestedOtherAsCustomer: false,
    InterestedOtherAsCustomerDesc: "",
    InterestedOtherAsSupplier: false,
    InterestedOtherAsSupplierDesc: "",
    MinistryOfEnergyRegistrationNo: "",
    YearsOfExperience: 0,
    EstimatedMonthlyPurchaseQuantity: 0,
    EstimatedMonthlyPurchaseUnit: "",
    StartTradingDate: dayjs(),
    DestinationCountries: [
      {
        Id: "",
        Country: "TH - Thailand",
        LocationType: "Local",
      },
    ],
    PurchaseProductFromCompany: "",
    SupplyProductToCompany: "",
    PaymentOverTheAmountOfUSD: "Yes",
    ReasonOfPaymentOverTheAmountOfUSD: "",
    FailedSupplyAgreement: "",
    ReasonOfFailedSupplyAgreement: "",
    HaveTagetsInSanction: "",
    ReasonOfHaveTagetsInSanction: "",
    ViolatingAntiMoneyLaunderingLaws: "No",
    ReasonOfViolatingAntiMoneyLaunderingLaws: "",
    OwnStorageAsCustomer: false,
    RentStorageMoreThanOneYearAsCustomer: false,
    RentStorageLessThanOneYearAsCustomer: false,
    NoStorageAsCustomer: false,
    OwnTransportAsCustomer: false,
    RentTransportMoreThanOneYearAsCustomer: false,
    RentTransportLessThanOneYearAsCustomer: false,
    NoTransportAsCustomer: false,
    OwnStorageAsSupplier: false,
    RentStorageMoreThanOneYearAsSupplier: false,
    RentStorageLessThanOneYearAsSupplier: false,
    NoStorageAsSupplier: false,
    OwnTransportAsSupplier: false,
    RentTransportMoreThanOneYearAsSupplier: false,
    RentTransportLessThanOneYearAsSupplier: false,
    NoTransportAsSupplier: false,
    EnvironmentPolicy: "Yes",
    OccupationalHealthAndSafetyPolicy: "Yes",
    CorporateGovernancePolicy: "Yes",
    HumanRightsPolicy: "Yes",
  });

  React.useEffect(() => {
    if (id) masterDataRegistrationForm(id);
  }, [id]);

  const masterDataRegistrationForm = async (id) => {
    setDataLoading(true);
    const data = await RequestFormAPI.getById(id);
    setObjRegistrationForm(data);
    setDataLoading(false);

    setLastModifiedDate(
      dayjs(data.RequestItems[0].LastModifiedDate).format("DD/MM/YYYY HH:mm")
    );
    setRequestNumber(data.RequestItems[0].Name);
  };

  const [pathsSharePoint, setPathsSharePoint] = React.useState([]);
  React.useEffect(() => {
    // Call your RequestForm function here
    const fetchData = async () => {
      try {
        const userInfo = helper.Auth.getTokenData();
        if (userInfo.accounts[0]) {
          const response = await SharePointFolder.getPathByAccount(
            userInfo.accounts[0].Id
          );
          const filesSharePoint = response.responseData;

          const fin = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Financial"
          );
          const coi = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_COI"
          );
          const moa = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_MOA"
          );
          const shr = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_List_Sharehold"
          );
          const vat = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_VAT_Reg"
          );
          const moe = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Reg_by_MoE"
          );
          const oth = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Others"
          );

          setPathsSharePoint({
            Account_Common_CTR_Financial: fin.Path || "",
            Account_Common_CTR_COI: coi.Path || "",
            Account_Common_CTR_MOA: moa.Path || "",
            Account_Common_CTR_List_Sharehold: shr.Path || "",
            Account_Common_CTR_VAT_Reg: vat.Path || "",
            Account_Common_CTR_Reg_by_MoE: moe.Path || "",
            Account_Common_CTR_Others: oth.Path || "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const uploadFileToFinancial = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
      const namefolder = pathsSharePoint.Account_Common_CTR_Financial;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToCOI = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/02 COI";
      const namefolder = pathsSharePoint.Account_Common_CTR_COI;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToMOA = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/03 MOA";
      const namefolder = pathsSharePoint.Account_Common_CTR_MOA;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToVAT = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/05 VAT Registration";
      const namefolder = pathsSharePoint.Account_Common_CTR_VAT_Reg;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToShareholders = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/04 List of Shareholder";
      const namefolder = pathsSharePoint.Account_Common_CTR_List_Sharehold;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadToFileToMoE = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/06 Registration by MoE";
      const namefolder = pathsSharePoint.Account_Common_CTR_Reg_by_MoE;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadToFileToOther = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/07 Other Registration Files";
      const namefolder = pathsSharePoint.Account_Common_CTR_Others;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };
  const uploadFiles = async () => {
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FinancialSecond = document.getElementById("FinancialSecond");
      const FinancialThird = document.getElementById("FinancialThird");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");
      const FileMoE = document.getElementById("FileMoE");
      const FileOther = document.getElementById("FileOther2");

      if (
        FinancialFirst.files.length === 0 ||
        FinancialSecond.files.length === 0 ||
        FinancialThird.files.length === 0 ||
        FileCOI.files.length === 0 ||
        FileMOA.files.length === 0 ||
        FileVAT.files.length === 0 ||
        FileShareholder.files.length === 0 ||
        FileMoE.files.length === 0 ||
        FileOther.files.length === 0
      ) {
        const selectedFilesFirst = FinancialFirst.files[0];
        const selectedFilesSecond = FinancialSecond.files[0];
        const selectedFilesThird = FinancialThird.files[0];
        const selectedFilesCOI = FileCOI.files[0];
        const selectedFilesMOA = FileMOA.files[0];
        const selectedFilesVAT = FileVAT.files[0];
        const selectedFileShareholder = FileShareholder.files[0];
        const selectedFileMoE = FileMoE.files[0];
        const selectedFileOther = FileOther.files;

        await uploadFileToFinancial(selectedFilesFirst);
        await uploadFileToFinancial(selectedFilesSecond);
        await uploadFileToFinancial(selectedFilesThird);
        await uploadFileToCOI(selectedFilesCOI);
        await uploadFileToMOA(selectedFilesMOA);
        await uploadFileToShareholders(selectedFileShareholder);
        await uploadFileToVAT(selectedFilesVAT);
        await uploadToFileToMoE(selectedFileMoE);
        for (const file of selectedFileOther) {
          await uploadToFileToOther(file);
        }

        // Resetting input values after successful upload
        FinancialFirst.value = "";
        FinancialSecond.value = "";
        FinancialThird.value = "";
        FileCOI.value = "";
        FileMOA.value = "";
        FileVAT.value = "";
        FileShareholder.value = "";
        FileMoE.value = "";
        FileOther.value = "";
      } else {
        console.error("No file selected.");
      }
    } catch (error) {
      console.error("Error in handling file upload:", error);
    }
  };

  const updateRequestFormByIds = async (data) => {
    let dataUpdate = data;
    Swal.fire({
      title: "Are you sure you want to save?",
      icon: "question",
      position: "center",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      cancelButtonColor: "red",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDataSaving(true);
        await RequestFormAPI.updateRequestForm(dataUpdate);
        await uploadFiles();
        setDataSaving(false);
        Swal.fire({
          title: "Saved!",
          icon: "success",
          showConfirmButton: false,
          timer: 1200,
        });
        navigate(`/request/view/${id}`);
      }
    });
  };

  const handleClickTab = (event) => {
    setActiveTab(event.currentTarget.dataset.tab);
  };

  return (
    <Container maxWidth="xl" className="RequestEditScreen">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dataLoading || dataSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="header">
        <div>
          <label>Request Number</label>
          <p>{requestNumber}</p>
        </div>
        <div>
          <label>Last Update Date</label>
          <p>{lastModifiedDate}</p>
        </div>
      </div>
      <div className="path">
        <ApprovalStatus currentStatus={"Review"} />
      </div>
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div className="sidebar">
          <nav className="slds-nav-vertical" aria-label="Sub page">
            <div className="slds-nav-vertical__section">
              <ul aria-describedby="entity-header">
                <li
                  className={
                    activeTab === "RegistrationForm"
                      ? "slds-nav-vertical__item slds-is-active"
                      : "slds-nav-vertical__item"
                  }
                  onClick={handleClickTab}
                  data-tab="RegistrationForm"
                >
                  <span className="slds-nav-vertical__action">
                    Registration Form
                  </span>
                </li>
                <li
                  className={
                    activeTab === "RequiredDocuments"
                      ? "slds-nav-vertical__item slds-is-active"
                      : "slds-nav-vertical__item"
                  }
                  onClick={handleClickTab}
                  data-tab="RequiredDocuments"
                >
                  <span className="slds-nav-vertical__action">
                    Required Documents
                  </span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {!dataLoading && (
          <div style={{ background: "white", flexGrow: 1 }}>
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disabled
                  variant="outlined"
                  onClick={() => navigate(`/request/edit/${id}/${tab}`)}
                >
                  Edit
                </Button>
              </Box>
              {activeTab === "RegistrationForm" && (
                <RegistrationForm
                  onFormDataObjectChange={(data) =>
                    setObjRegistrationForm(data)
                  }
                  formDataObject={objRegistrationForm}
                  // formDataDraft={(data) => updateRequestFormByIds(data)}
                  formId={id}
                />
              )}
              {activeTab === "RequiredDocuments" && (
                <RequiredDocument
                  onUploadFileOtherChange={(fileList) =>
                    setUploadFileOther(fileList)
                  }
                  onFormDataObjectChange={(data) =>
                    setObjRegistrationForm(data)
                  }
                  formDataObject={objRegistrationForm}
                  // formDataDraft={(data) => updateRequestFormByIds(data)}
                  formId={id}
                />
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ p: 1, m: 1 }}
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    Swal.fire({
                      title: "Do you want to leave this page?",
                      icon: "question",
                      position: "center",
                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate(`/request/view/${id}`);
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ p: 1, m: 1 }}
                  variant="outlined"
                  onClick={() => {
                    objRegistrationForm.Documents.Others.push(
                      ...uploadFileOther
                    );
                    updateRequestFormByIds(objRegistrationForm);
                  }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </div>
        )}
      </div>
    </Container>
  );
}
