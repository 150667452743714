import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BlockIcon from "@mui/icons-material/Block";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from "@mui/icons-material/Adb";
import Logo from "../assets/img/thaioil.png";
import helper from "../services/helper";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { NotificationAPI } from "../services/apigateway";

import "../styles/Header.css";
import Manual from "../assets/files/manual.pdf";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const pages = [{ label: "Logout", onClick: () => navigate("/logout") }];
  const isLogin = helper.Auth.isLOGIN();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const [accounts, setAccounts] = React.useState([]);

  React.useEffect(() => {
    const isLogin = helper.Auth.isLOGIN();
    if (isLogin) {
      const fetchData = () => {
        NotificationAPI.findAll().then((results) => {
          setNotifications(results);
        });
      };
      fetchData();
      const interval = setInterval(fetchData, 60000);

      const userInfo = helper.Auth.getTokenData();
      if (userInfo && Object.prototype.toString.call(userInfo.accounts) === "[object Array]") {
        setAccounts(userInfo.accounts)
      }
      return () => clearInterval(interval);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenDrawerMenu = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  const handleClickNotificationButton = (evt) => {
    setAnchorEl(anchorEl ? null : evt.currentTarget);
  };

  const handleClickNotificationItem = (evt) => {
    const body = { notificationId: evt.currentTarget.dataset.id };
    setAnchorEl(null);
    NotificationAPI.markAsRead(body)
      .then(NotificationAPI.findAll)
      .then((results) => setNotifications(results));
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popper" : undefined;

  return (
    <React.Fragment>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl" disableGutters>
          <Toolbar disableGutters>
            {isLogin && (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenDrawerMenu}
                color="inherit"
              >
                <MenuIcon sx={{ fontSize: "36px", color: "rgb(96, 96, 96)" }} />
              </IconButton>
            )}
            <img
              src={Logo}
              alt="Thaioil Group"
              className="logo-header"
              style={
                isLogin
                  ? {}
                  : {
                      paddingLeft: "25px",
                    }
              }
            />
            {isLogin && (
              <>
                {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
                <h1 className="header-title">&nbsp;</h1>

                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  aria-describedby={id}
                  color="inherit"
                  onClick={handleClickNotificationButton}
                >
                  <Badge badgeContent={notifications?.length} color="error">
                    <NotificationsIcon
                      sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }}
                    />
                  </Badge>
                </IconButton>
                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Paper
                    className="notification-paper"
                    elevation={3}
                    sx={{ p: 1, width: "375px" }}
                  >
                    <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                      Notification
                    </Typography>
                    <ul>
                      {Object.prototype.toString.call(notifications) === "[object Array]" ? (
                        notifications.map((noti) => {
                          noti.Body =
                            noti.Title ===
                            "Additional Information and Documents Required"
                              ? "Please update documents clicking here"
                              : noti.Body;
                          noti.Title =
                            noti.Title ===
                            "Additional Information and Documents Required"
                              ? "Documents Required"
                              : noti.Title;

                          return (
                            <li key={noti.Id}>
                              <Link
                                to={noti.RedirectTo}
                                data-id={noti.Id}
                                onClick={handleClickNotificationItem}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {noti.Title}
                                </Typography>
                                <Typography sx={{ fontSize: "0.85rem" }}>
                                  {noti.Body}
                                </Typography>
                              </Link>
                            </li>
                          );
                        })
                      ) : (
                        <li>
                          <Typography
                            textAlign="center"
                            sx={{ pt: 1, fontSize: "0.85rem" }}
                          >
                            <BlockIcon
                              sx={{
                                fontSize: "3.6rem",
                                color: "rgb(256, 256, 256)",
                              }}
                            />
                          </Typography>
                          <Typography
                            textAlign="center"
                            sx={{ pb: 1, fontSize: "0.85rem" }}
                          >
                            No Notifications
                          </Typography>
                        </li>
                      )}
                    </ul>
                  </Paper>
                </Popper>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleOpenNavMenu}
                >
                  <AccountCircleIcon
                    sx={{ fontSize: "36px", color: "rgb(194, 10, 204)" }}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: "block",
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center" onClick={page.onClick}>
                        {page.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <div
        className={isOpenDrawer ? "drawer-container open" : "drawer-container"}
        onClick={() => setIsOpenDrawer(false)}
      ></div>
      <nav className={isOpenDrawer ? "drawer open" : "drawer"}>
        <ul>
          <li>
            <Link
              to="/main"
              onClick={() => setIsOpenDrawer(false)}
              className={location.pathname === "/main" ? "active" : ""}
            >
              <i className="bi bi-house-fill"></i> Home
            </Link>
          </li>
          <li
            className={
              location.pathname === "/extend" ? "submenu open" : "submenu"
            }
          >
            <Link
              to="#"
              onClick={(e) => {
                e.target.parentElement.classList.toggle("open");
              }}
            >
              <i className="bi bi-file-text"></i> Request
            </Link>
            <ul>
              <li className="extend-submenu">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.target.parentElement.classList.toggle("open");
                  }}
                >
                  Extend
                </Link>
                <ul>
                  { Object.prototype.toString.call(accounts) === "[object Array]" &&
                    accounts.map((item, index) => {
                      return (
                        <li key={item.Id}>
                          <Link
                            to={"/extend/" + item.Id}
                            onClick={() => setIsOpenDrawer(false)}
                            className={location.pathname === "/extend/" + item.Id ? "active" : ""}
                          >
                            {item.Name}
                          </Link>
                        </li>
                      );
                    })
                  }
                  <li></li>
                </ul>
              </li>
              {/* <li>
                <Link
                  to="/reduce-performance-bond"
                  onClick={() => setIsOpenDrawer(false)}
                  className={location.pathname === "/reduce-performance-bond" ? "active" : ""}
                >
                  Reduce Performance Bond
                </Link>
              </li> */}
            </ul>
          </li>
          <li>
            <Link
              to="/documents"
              onClick={() => setIsOpenDrawer(false)}
              className={location.pathname === "/documents" ? "active" : ""}
            >
              <i className="bi bi-folder"></i> Documents
            </Link>
          </li>
          <li>
            <a
              href={Manual}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsOpenDrawer(false)}
            >
              <i className="bi bi-journal-bookmark"></i> Manual
            </a>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
}
export default Header;
