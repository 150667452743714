import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import ClearIcon from '@mui/icons-material/Clear';
import { useState, useEffect, useRef } from "react";

import helper from "../../services/helper";
import { SharePointFolder } from "../../services/apigateway";

export default function RequiredDocument(props) {
  const [showTableFirstYear, setShowTableFirstYear] = useState(false);
  const [showTableSecondYear, setShowTableSecondYear] = useState(false);
  const [showTableThirdYear, setShowTableThirdYear] = useState(false);
  const [showTableCOI, setShowTableCOI] = useState(false);
  const [showTableMOA, setShowTableMOA] = useState(false);
  const [showTableShareholder, setShowTableShareholder] = useState(false);
  const [showTableVAT, setShowTableVAT] = useState(false);
  const [showTableMoE, setShowTableMoE] = useState(false);
  const [selectedFinancialFirst, setSelectedFinancialFirst] = useState([]);
  const [selectedFinancialSecond, setSelectedFinancialSecond] = useState([]);
  const [selectedFinancialThird, setSelectedFinancialThird] = useState([]);
  const [selectedCOI, setSelectedCOI] = useState([]);
  const [selectedMOA, setSelectedMOA] = useState([]);
  const [selectedVAT, setSelectedVAT] = useState([]);
  const [selectedShareholders, setSelectedShareholders] = useState([]);
  const [selectedMoE, setSelectedMoE] = useState([]);
  const [selectedOther, setSelectedOther] = useState([]);
  const fileInputRefFirst = useRef(null);
  const fileInputRefSecond = useRef(null);
  const fileInputRefThird = useRef(null);
  const fileInputRefCOI = useRef(null);
  const fileInputRefMOA = useRef(null);
  const fileInputRefVAT = useRef(null);
  const fileInputRefShareholders = useRef(null);
  const fileInputRefMoE = useRef(null);

  const objRegistrationForm = props.formDataObject;
  const setObjRegistrationForm = props.onFormDataObjectChange;
  // const [objRegistrationForm, setObjRegistrationForm] = useState(
  //   props.formDataObject
  // );
  const [filesSharePoint, setFilesSharePoint] = useState([]);

  useEffect(() => {
    if (props.formId) {
      masterDataRegistrationForm(props.formId)
    }
    //eslint-disable-next-line
  }, [props.formId]);

  const masterDataRegistrationForm = async (id) => {
    // const data = await RequestFormAPI.getById(id)
    // setObjRegistrationForm(data)

    const userInfo = helper.Auth.getTokenData()
    if (userInfo.accounts[0]) {
      getSharePointFiles(userInfo.accounts[0].Id)
    }
  };

  const FileChangeFinancialFirst = (event) => {
    const selectedFilesFirst = event.target.files;
    if (selectedFilesFirst.length > 0) {
      const FirstfileNames = Array.from(selectedFilesFirst).map(file => file.name);
      setSelectedFinancialFirst(FirstfileNames); // Set the names of selected files
      setShowTableFirstYear(true);
      objRegistrationForm
        .Documents
        .FinancialStatement
        .FirstYear = FirstfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .FinancialStatement
        .FirstYear = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearFinancialFirst = (indexToRemove) => {
    const updatedFilesFirst = selectedFinancialFirst.filter((_, index) => index !== indexToRemove);
    setSelectedFinancialFirst(updatedFilesFirst);
    if (fileInputRefFirst.current) {
      fileInputRefFirst.current.value = '';
    }
  };

  const FileChangeFinancialSecond = (event) => {
    const selectedFilesFinancialSecond = event.target.files;
    if (selectedFilesFinancialSecond.length > 0) {
      const FinancialSecondfileNames = Array.from(selectedFilesFinancialSecond).map(file => file.name);
      setSelectedFinancialSecond(FinancialSecondfileNames); // Set the names of selected files
      setShowTableSecondYear(true);

      objRegistrationForm
        .Documents
        .FinancialStatement
        .SecondYear = FinancialSecondfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .FinancialStatement
        .SecondYear = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearFinancialSecond = (indexToRemove) => {
    const updatedFilesSecond = selectedFinancialSecond.filter((_, index) => index !== indexToRemove);
    setSelectedFinancialSecond(updatedFilesSecond);
    if (fileInputRefSecond.current) {
      fileInputRefSecond.current.value = '';
    }
  };

  const FileChangeFinancialThird = (event) => {
    const selectedFilesFinancialThird = event.target.files;
    if (selectedFilesFinancialThird.length > 0) {
      const FinancialThirdfileNames = Array.from(selectedFilesFinancialThird).map(file => file.name);
      setSelectedFinancialThird(FinancialThirdfileNames); // Set the names of selected files
      setShowTableThirdYear(true);
      objRegistrationForm
        .Documents
        .FinancialStatement
        .ThirdYear = FinancialThirdfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .FinancialStatement
        .ThirdYear = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearFinancialThird = (indexToRemove) => {
    const updatedFilesThird = selectedFinancialThird.filter((_, index) => index !== indexToRemove);
    setSelectedFinancialThird(updatedFilesThird);
    if (fileInputRefThird.current) {
      fileInputRefThird.current.value = '';
    }
  };

  const handleFileChangeCOI = (event) => {
    const selectedFilesCOI = event.target.files;
    if (selectedFilesCOI.length > 0) {
      const COIfileNames = Array.from(selectedFilesCOI).map(file => file.name);
      setSelectedCOI(COIfileNames); // Set the names of selected files
      setShowTableCOI(true);
      objRegistrationForm
        .Documents
        .CertificateOfIncorporation = COIfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .CertificateOfIncorporation = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearCOI = (indexToRemove) => {
    const updatedFilesCOI = selectedCOI.filter((_, index) => index !== indexToRemove);
    setSelectedCOI(updatedFilesCOI);
    if (fileInputRefCOI.current) {
      fileInputRefCOI.current.value = '';
    }
  };

  const handleFileChangeMOA = (event) => {
    const selectedFilesMOA = event.target.files;
    if (selectedFilesMOA.length > 0) {
      const MOAfileNames = Array.from(selectedFilesMOA).map(file => file.name);
      setSelectedMOA(MOAfileNames); // Set the names of selected files
      setShowTableMOA(true);
      objRegistrationForm
        .Documents
        .MemorandumOfAssociation = MOAfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .MemorandumOfAssociation = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearMOA = (indexToRemove) => {
    const updatedFiles = selectedMOA.filter((_, index) => index !== indexToRemove);
    setSelectedMOA(updatedFiles);
    if (fileInputRefMOA.current) {
      fileInputRefMOA.current.value = '';
    }
  };

  const handleFileChangeVAT = (event) => {
    const selectedFilesVAT = event.target.files;
    if (selectedFilesVAT.length > 0) {
      const VATfileNames = Array.from(selectedFilesVAT).map(file => file.name);
      setSelectedVAT(VATfileNames); // Set the names of selected files
      setShowTableVAT(true);
      objRegistrationForm
        .Documents
        .CertificateOfVATRegistration = VATfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .CertificateOfVATRegistration = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearVAT = (indexToRemove) => {
    const updatedFiles = selectedVAT.filter((_, index) => index !== indexToRemove);
    setSelectedVAT(updatedFiles);
    if (fileInputRefVAT.current) {
      fileInputRefVAT.current.value = '';
    }
  };

  const handleFileChangeShareholders = (event) => {
    const selectedFilesShareholders = event.target.files;
    if (selectedFilesShareholders.length > 0) {
      const ShareholdersfileNames = Array.from(selectedFilesShareholders).map(file => file.name);
      setSelectedShareholders(ShareholdersfileNames); // Set the names of selected files
      setShowTableShareholder(true);
      objRegistrationForm
        .Documents
        .ListOfShareholders = ShareholdersfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .ListOfShareholders = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearShareholders = (indexToRemove) => {
    const updatedFiles = selectedShareholders.filter((_, index) => index !== indexToRemove);
    setSelectedShareholders(updatedFiles);
    if (fileInputRefShareholders.current) {
      fileInputRefShareholders.current.value = '';
    }
  };

  const handleFileChangeMoE = (event) => {
    const selectedFilesMoE = event.target.files;
    if (selectedFilesMoE.length > 0) {
      const MoEfileNames = Array.from(selectedFilesMoE).map(file => file.name);
      setSelectedMoE(MoEfileNames); // Set the names of selected files
      setShowTableMoE(true);
      objRegistrationForm
        .Documents
        .RegistrationIssuedByMinistryOfEnergy = MoEfileNames[0];
    } else {
      objRegistrationForm
        .Documents
        .RegistrationIssuedByMinistryOfEnergy = "";
    }
    setObjRegistrationForm(objRegistrationForm);
  };
  const handleClearMoE = (indexToRemove) => {
    const updatedFiles = selectedMoE.filter((_, index) => index !== indexToRemove);
    setSelectedMoE(updatedFiles);
    if (fileInputRefMoE.current) {
      fileInputRefMoE.current.value = '';
    }
  };

  function addFileToUploadList() {
    const attachments2 = document.getElementById("FileOther2").files;
    var fileBuffer = new DataTransfer();
    for (let i = 0; i < attachments2.length; i++) {
      fileBuffer.items.add(attachments2[i]);
    }

    const attachments1 = document.getElementById("FileOther1").files;
    for (let i = 0; i < attachments1.length; i++) {
      fileBuffer.items.add(attachments1[i]);
    }

    document.getElementById("FileOther2").files = fileBuffer.files;
    document.getElementById("FileOther1").files = (new DataTransfer()).files;
    const newSelectedOther = [];
    for (let i = 0; i < fileBuffer.files.length; i++) {
      newSelectedOther.push(fileBuffer.files[i].name);
    }

    // Update the state to reflect the new files
    setSelectedOther(newSelectedOther); // Assuming setSelectedOther is the state update function
    props.onUploadFileOtherChange(newSelectedOther);
  }
  function removeFileFromUploadList(indexToRemove) {
    console.log(indexToRemove)
    var attachments = document.getElementById("FileOther2").files;
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < attachments.length; i++) {
      if (indexToRemove !== i)
        fileBuffer.items.add(attachments[i]);
    }

    document.getElementById("FileOther2").files = fileBuffer.files;
    const updatedFiles = selectedOther.filter((_, index) => index !== indexToRemove);
    setSelectedOther(updatedFiles);
    props.onUploadFileOtherChange(updatedFiles);
  }
  function handleChangeFile(e) {
    addFileToUploadList();
  }

  const getSharePointFiles = async (id) => {
    const results = await SharePointFolder.getFileByAccount(id)
    const filesSharePoint = results.responseData

    const fin = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Financial")
    const coi = filesSharePoint.find(item => item.Name === "Account_Common_CTR_COI")
    const moa = filesSharePoint.find(item => item.Name === "Account_Common_CTR_MOA")
    const shr = filesSharePoint.find(item => item.Name === "Account_Common_CTR_List_Sharehold")
    const vat = filesSharePoint.find(item => item.Name === "Account_Common_CTR_VAT_Reg")
    const moe = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Reg_by_MoE")
    const oth = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Others")

    setFilesSharePoint({
      Account_Common_CTR_Financial: fin || [],
      Account_Common_CTR_COI: coi || [],
      Account_Common_CTR_MOA: moa || [],
      Account_Common_CTR_List_Sharehold: shr || [],
      Account_Common_CTR_VAT_Reg: vat || [],
      Account_Common_CTR_Reg_by_MoE: moe || [],
      Account_Common_CTR_Others: oth || [],
    })
  }

  const findInSharePointFolders = (folderName, fileName) => {
    return filesSharePoint[folderName]
      ?.Files
      ?.find(item => item.Name === fileName)
  }

  const getPathSharePointFolders = (folderName) => {
    return filesSharePoint[folderName]?.Path
  }

  const downloadFile = async (folderName, fileName) => {
    try {
      const path = getPathSharePointFolders(folderName);
      if (!fileName) {
        throw new Error('Filename is not defined');
      }
      const response = await SharePointFolder.downloadFile(path, fileName);
      const responseData = response.responseData;

      if (responseData && responseData.length > 0) {
        const mimeType = 'application/octet-stream';
        const blob = new Blob([responseData], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
            Required Document Check list
          </Typography>
        </Box>
        {/* row */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            {/* 1 */}
            <Typography variant="subtitle1" gutterBottom>
              1. Audited Financial Statements with Notes to the Financial
              Statements for the last 3 years
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.1 First Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={FileChangeFinancialFirst}
                        id="FinancialFirst"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefFirst}
                      />
                      <label htmlFor="FinancialFirst">
                        <Button variant="outlined"
                          component="span"
                        >
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableFirstYear
                          ? selectedFinancialFirst.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearFinancialFirst(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.FinancialStatement
                            ?.FirstYear
                          && findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objRegistrationForm
                              .Documents
                              .FinancialStatement
                              .FirstYear
                          )
                          && <Typography variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_Financial",
                              //   objRegistrationForm
                              //     .Documents
                              //     .FinancialStatement
                              //     .FirstYear
                              // )
                            }}
                          >
                            {
                              objRegistrationForm
                                .Documents
                                .FinancialStatement
                                .FirstYear
                            }
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer', mt: -0.5 }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .FinancialStatement
                                  .FirstYear = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.2 Second Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl: 2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={FileChangeFinancialSecond}
                        id="FinancialSecond"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefSecond}
                      />
                      <label htmlFor="FinancialSecond">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableSecondYear
                          ? selectedFinancialSecond.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearFinancialSecond(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.FinancialStatement
                            ?.SecondYear
                          && findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objRegistrationForm
                              .Documents
                              .FinancialStatement
                              .SecondYear
                          )
                          && <Typography variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_Financial",
                              //   objRegistrationForm
                              //     .Documents
                              //     .FinancialStatement
                              //     .SecondYear
                              // )
                            }}
                          >
                            {
                              objRegistrationForm
                                .Documents
                                .FinancialStatement
                                .SecondYear
                            }
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer', mt: -0.5 }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .FinancialStatement
                                  .SecondYear = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.3 Third Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={FileChangeFinancialThird}
                        id="FinancialThird"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefThird}
                      />
                      <label htmlFor="FinancialThird">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableThirdYear
                          ? selectedFinancialThird.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearFinancialThird(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.FinancialStatement
                            ?.ThirdYear
                          && findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objRegistrationForm
                              .Documents
                              .FinancialStatement
                              .ThirdYear
                          )
                          && <Typography variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_Financial",
                              //   objRegistrationForm
                              //     .Documents
                              //     .FinancialStatement
                              //     .ThirdYear
                              // )
                            }}
                          >
                            {
                              objRegistrationForm
                                .Documents
                                .FinancialStatement
                                .ThirdYear
                            }
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer', mt: -0.5 }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .FinancialStatement
                                  .ThirdYear = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 2 */}
            <Typography variant="subtitle1" gutterBottom>
              2. Certificate of Incorporation (COI)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl: 2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChangeCOI}
                        id="FileCOI"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefCOI}
                      />
                      <label htmlFor="FileCOI">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableCOI
                          ? selectedCOI.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearCOI(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.CertificateOfIncorporation
                          && findInSharePointFolders(
                            "Account_Common_CTR_COI",
                            objRegistrationForm
                              .Documents
                              .CertificateOfIncorporation
                          )
                          && <Typography
                            variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_COI",
                              //   objRegistrationForm
                              //     .Documents
                              //     .CertificateOfIncorporation
                              // )
                            }}
                          >
                            {objRegistrationForm
                              .Documents
                              .CertificateOfIncorporation}
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer' }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .CertificateOfIncorporation = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 3 */}
            <Typography variant="subtitle1" gutterBottom>
              3. Memorandum of Association (MOA)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChangeMOA}
                        id="FileMOA"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefMOA}
                      />
                      <label htmlFor="FileMOA">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableMOA
                          ? selectedMOA.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearMOA(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.MemorandumOfAssociation
                          && findInSharePointFolders(
                            "Account_Common_CTR_MOA",
                            objRegistrationForm
                              .Documents
                              .MemorandumOfAssociation
                          )
                          && <Typography variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_MOA",
                              //   objRegistrationForm
                              //     .Documents
                              //     .MemorandumOfAssociation
                              // )
                            }}
                          >
                            {objRegistrationForm
                              .Documents
                              .MemorandumOfAssociation}
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer' }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .MemorandumOfAssociation = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 4 */}
            <Typography variant="subtitle1" gutterBottom>
              4. Certificate of VAT Registration (ภ.พ. 20)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl: 2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChangeVAT}
                        id="FileVAT"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefVAT}
                      />
                      <label htmlFor="FileVAT">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableVAT
                          ? selectedVAT.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearVAT(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.CertificateOfVATRegistration
                          && findInSharePointFolders(
                            "Account_Common_CTR_VAT_Reg",
                            objRegistrationForm
                              .Documents
                              .CertificateOfVATRegistration
                          )
                          && <Typography
                            variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_VAT_Reg",
                              //   objRegistrationForm
                              //     .Documents
                              //     .CertificateOfVATRegistration
                              // )
                            }}
                          >
                            {objRegistrationForm
                              .Documents
                              .CertificateOfVATRegistration}
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer' }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .CertificateOfVATRegistration = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 5 */}
            <Typography variant="subtitle1" gutterBottom>
              5. List of the Shareholders (Bor.Or.Jor.5)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl: 2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChangeShareholders}
                        id="FileShareholder"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefShareholders}
                      />
                      <label htmlFor="FileShareholder">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {showTableShareholder
                        ? selectedShareholders.map((fileName, index) => (
                          <Typography
                            key={index}
                            variant="outlined"
                          >
                            {fileName}
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer' }}
                              onClick={() => handleClearShareholders(index)}
                            />
                          </Typography>
                        ))
                        : objRegistrationForm
                          ?.Documents
                          ?.ListOfShareholders
                        && findInSharePointFolders(
                          "Account_Common_CTR_List_Sharehold",
                          objRegistrationForm
                            .Documents
                            .ListOfShareholders
                        ) && <Typography
                          variant="outlined"
                          onClick={(e) => {
                            // e.preventDefault()
                            // downloadFile(
                            //   "Account_Common_CTR_List_Sharehold",
                            //   objRegistrationForm
                            //     .Documents
                            //     .ListOfShareholders
                            // )
                          }}
                        >
                          {objRegistrationForm
                            .Documents
                            .ListOfShareholders}
                          <ClearIcon
                            sx={{ color: red[500], cursor: 'pointer' }}
                            onClick={() => {
                              objRegistrationForm
                                .Documents
                                .ListOfShareholders = ""
                              setObjRegistrationForm({ ...objRegistrationForm });
                            }}
                          />
                        </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 6 */}
            <Typography variant="subtitle1" gutterBottom>
              6. Registration issueก by Ministry of Energy (For petroleum)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl: 2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChangeMoE}
                        id="FileMoE"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefMoE}
                      />
                      <label htmlFor="FileMoE">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button> &nbsp;
                      </label>
                      {
                        showTableMoE
                          ? selectedMoE.map((fileName, index) => (
                            <Typography
                              key={index}
                              variant="outlined"
                            >
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: 'pointer' }}
                                onClick={() => handleClearMoE(index)}
                              />
                            </Typography>
                          ))
                          : objRegistrationForm
                            ?.Documents
                            ?.RegistrationIssuedByMinistryOfEnergy
                          && findInSharePointFolders(
                            "Account_Common_CTR_Reg_by_MoE",
                            objRegistrationForm
                              .Documents
                              .RegistrationIssuedByMinistryOfEnergy
                          ) && <Typography
                            variant="outlined"
                            onClick={(e) => {
                              // e.preventDefault()
                              // downloadFile(
                              //   "Account_Common_CTR_Reg_by_MoE",
                              //   objRegistrationForm
                              //     .Documents
                              //     .RegistrationIssuedByMinistryOfEnergy
                              // )
                            }}
                          >
                            {objRegistrationForm
                              .Documents
                              .RegistrationIssuedByMinistryOfEnergy}
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer' }}
                              onClick={() => {
                                objRegistrationForm
                                  .Documents
                                  .RegistrationIssuedByMinistryOfEnergy = ""
                                setObjRegistrationForm({ ...objRegistrationForm });
                              }}
                            />
                          </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 7 */}
            <Typography variant="subtitle1" gutterBottom>
              7. Other File (If any)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl: 2, pr: 2, mr: 0.5 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        id="FileOther2"
                        variant="outlined"
                        multiple  // Allow multiple file selection
                        style={{ display: 'none' }}
                      />

                      {/* Input element for file selection */}
                      <input
                        type="file"
                        onChange={handleChangeFile}
                        id="FileOther1"
                        variant="outlined"
                        multiple  // Allow multiple file selection
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="FileOther1">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <TableContainer component={Paper} sx={{ m: 2 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                  <TableRow>
                      <TableCell align="center" width="120px">No.</TableCell>
                      <TableCell align="left">File Name</TableCell>
                      {/* <TableCell align="center" width="120px">File Size</TableCell> */}
                      <TableCell align="center" width="120px">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {objRegistrationForm?.Documents?.Others?.map((item, index) => {
                      const files = findInSharePointFolders("Account_Common_CTR_Others", item);
                      console.log(files);
                      if (files) {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center" component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                              <a
                                className="link-download"
                                href="#!"
                                onClick={
                                  (e) => {
                                    e.preventDefault()
                                    downloadFile("Account_Common_CTR_Others", files.Name)
                                  }
                                }
                              >
                                {files.Name}
                              </a>
                            </TableCell>
                           
                            <TableCell align="center" component="th" scope="row">
                                <ClearIcon
                                  data-name={files.Name}
                                  sx={{ color: red[500], cursor: 'pointer' }}
                                  onClick={(evt) => {
                                    const index = objRegistrationForm.Documents.Others.findIndex(item => {
                                      return item === evt.currentTarget.dataset.name
                                    })
                                    objRegistrationForm.Documents.Others.splice(index, 1)
                                    setObjRegistrationForm({ ...objRegistrationForm })
                                  }}
                                />
                              </TableCell>
                          </TableRow>
                        )
                      } else {
                        return null;
                      }
                    })}
                    {selectedOther?.map((fileName, index) => (
                        <TableRow key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {objRegistrationForm?.Documents?.Others?.length + index + 1}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            {fileName}
                          </TableCell>
                          <TableCell align="center">
                            <ClearIcon
                              sx={{ color: red[500], cursor: 'pointer' }}
                              onClick={() => removeFileFromUploadList(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
