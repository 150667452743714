import React from "react";
import Modal from "react-modal";
import { Box, Typography } from "@mui/material";

export default function ModalAnnualReviewForm(props) {
    function closeModal() {
        props.onHide();
    }

    return (
        <div>
            <Modal
                ariaHideApp={false}
                isOpen={props.open}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <Box>
                    <Typography variant="h6">Thanks you toyr registration</Typography>
                    <Typography variant="h6">Your request number is</Typography>
                    <Typography variant="h6">2019204885 and 299302930333</Typography>
                </Box>
            </Modal>
        </div>
    );
}

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};
