import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { red, green, grey } from "@mui/material/colors";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CheckIconTrue from "@mui/icons-material/CheckCircleRounded";
import CheckIconFlase from "@mui/icons-material/CheckCircleOutlineRounded";
import {
  AdditionalInfoFormAPI,
  SharePointFolder,
} from "../../services/apigateway";
import helper from "../../services/helper";

export default function RequiredDocument({
  showTableFirstYear,
  setShowTableFirstYear,
  showTableSecondYear,
  setShowTableSecondYear,
  showTableThirdYear,
  setShowTableThirdYear,
  showTableCOI,
  setShowTableCOI,
  showTableMOA,
  setShowTableMOA,
  showTableShareholder,
  setShowTableShareholder,
  showTableVAT,
  setShowTableVAT,
  showTableMoE,
  setShowTableMoE,
  showTableOther,
  setShowTableOther,
  formData,
  formDataSave,
  formDataDraft,
  formId,
}) {
  const [selectedFinancialFirst, setSelectedFinancialFirst] = useState([]);
  const [selectedFinancialSecond, setSelectedFinancialSecond] = useState([]);
  const [selectedFinancialThird, setSelectedFinancialThird] = useState([]);
  const [selectedCOI, setSelectedCOI] = useState([]);
  const [selectedMOA, setSelectedMOA] = useState([]);
  const [selectedVAT, setSelectedVAT] = useState([]);
  const [selectedShareholders, setSelectedShareholders] = useState([]);
  const [selectedMoE, setSelectedMoE] = useState([]);
  const [selectedOther, setSelectedOther] = useState([]);
  const fileInputRefFirst = useRef(null);
  const fileInputRefSecond = useRef(null);
  const fileInputRefThird = useRef(null);
  const fileInputRefCOI = useRef(null);
  const fileInputRefMOA = useRef(null);
  const fileInputRefVAT = useRef(null);
  const fileInputRefShareholders = useRef(null);
  const fileInputRefMoE = useRef(null);

  const [fileSelectedFirst, setFileSelectedFirst] = useState(false);
  const [fileSelectedSecond, setFileSelectedSecond] = useState(false);
  const [fileSelectedThird, setFileSelectedThird] = useState(false);
  const [fileSelectedCOI, setFileSelectedCOI] = useState(false);
  const [fileSelectedMOA, setFileSelectedMOA] = useState(false);
  const [fileSelectedVAT, setFileSelectedVAT] = useState(false);
  const [fileSelectedShareholders, setFileSelectedShareholders] =
    useState(false);
  const [fileSelectedMoE, setFileSelectedMoE] = useState(false);

  const [objAdditionalForm, setObjAdditionalForm] = useState({
    RequestFormHeaderId: "",
    CustomerId: "",
    CEOFirstName: "",
    CEOLastName: "",
    Shareholders: [
      {
        Id: "",
        Name: "",
        PercentOfShareholder: 0,
        RequestFormHeaderId: "",
      },
    ],
    Documents: {
      FinancialStatement: {
        FirstYear: "",
        SecondYear: "",
        ThirdYear: "",
      },
      CertificateOfIncorporation: "",
      MemorandumOfAssociation: "",
      CertificateOfVATRegistration: "",
      ListOfShareholders: "",
      RegistrationIssuedByMinistryOfEnergy: "",
      Others: [],
    },
    ImmidiateParentCompany: "",
    UltimateParentCompany: "",
    MainService: "",
    OtherMainService: "",
    MainProduct: "",
    OtherMainProduct: "",
    CompanyLatestCreditRating: "",
  });

  const [required, setRequired] = useState({
    fields: "",
    active: false,
  });

  useEffect(() => {
    // Call your RequestForm function here
    const fetchData = async () => {
      try {
        // Replace 'params' with the actual parameters needed for your API request
        if (formId) {
          const response = await AdditionalInfoFormAPI.getRequestFormByHeader(formId);
          setObjAdditionalForm(response);
        }

        const userInfo = helper.Auth.getTokenData();
        if (userInfo.accounts[0]) {
          getSharePointFiles(userInfo.accounts[0].Id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [formId]);

  const FileChangeFinancialFirst = (event) => {
    const selectedFilesFirst = event.target.files;
    if (selectedFilesFirst.length > 0) {
      const FirstfileNames = Array.from(selectedFilesFirst).map(
        (file) => file.name
      );
      setSelectedFinancialFirst(FirstfileNames); // Set the names of selected files
      setShowTableFirstYear(true);
      setFileSelectedFirst(true);

      objAdditionalForm
      .Documents
      .FinancialStatement
      .FirstYear = FirstfileNames[0];
    } else {
      setFileSelectedFirst(false);
      objAdditionalForm
        .Documents
        .FinancialStatement
        .FirstYear = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearFinancialFirst = (indexToRemove) => {
    const updatedFilesFirst = selectedFinancialFirst.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFinancialFirst(updatedFilesFirst);
    if (fileInputRefFirst.current) {
      fileInputRefFirst.current.value = "";
      setFileSelectedFirst(false);
    }
  };

  const FileChangeFinancialSecond = (event) => {
    const selectedFilesFinancialSecond = event.target.files;
    if (selectedFilesFinancialSecond.length > 0) {
      const FinancialSecondfileNames = Array.from(
        selectedFilesFinancialSecond
      ).map((file) => file.name);
      setSelectedFinancialSecond(FinancialSecondfileNames); // Set the names of selected files
      setShowTableSecondYear(true);
      setFileSelectedSecond(true);

      objAdditionalForm.Documents.FinancialStatement.SecondYear =
        FinancialSecondfileNames[0];
    } else {
      setFileSelectedSecond(false);
      objAdditionalForm.Documents.FinancialStatement.SecondYear = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearFinancialSecond = (indexToRemove) => {
    const updatedFilesSecond = selectedFinancialSecond.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFinancialSecond(updatedFilesSecond);
    if (fileInputRefSecond.current) {
      fileInputRefSecond.current.value = "";
      setFileSelectedSecond(false);
    }
  };

  const FileChangeFinancialThird = (event) => {
    const selectedFilesFinancialThird = event.target.files;
    if (selectedFilesFinancialThird.length > 0) {
      const FinancialThirdfileNames = Array.from(
        selectedFilesFinancialThird
      ).map((file) => file.name);
      setSelectedFinancialThird(FinancialThirdfileNames); // Set the names of selected files
      setShowTableThirdYear(true);
      setFileSelectedThird(true);
      objAdditionalForm.Documents.FinancialStatement.ThirdYear =
        FinancialThirdfileNames[0];
    } else {
      setFileSelectedThird(false);
      objAdditionalForm.Documents.FinancialStatement.ThirdYear = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearFinancialThird = (indexToRemove) => {
    const updatedFilesThird = selectedFinancialThird.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFinancialThird(updatedFilesThird);
    if (fileInputRefThird.current) {
      fileInputRefThird.current.value = "";
      setFileSelectedThird(false);
    }
  };

  const handleFileChangeCOI = (event) => {
    const selectedFilesCOI = event.target.files;
    if (selectedFilesCOI.length > 0) {
      const COIfileNames = Array.from(selectedFilesCOI).map(
        (file) => file.name
      );
      setSelectedCOI(COIfileNames); // Set the names of selected files
      setShowTableCOI(true);
      setFileSelectedCOI(true);
      objAdditionalForm.Documents.CertificateOfIncorporation = COIfileNames[0];
    } else {
      setFileSelectedCOI(false);
      objAdditionalForm.Documents.CertificateOfIncorporation = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearCOI = (indexToRemove) => {
    const updatedFilesCOI = selectedCOI.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedCOI(updatedFilesCOI);
    if (fileInputRefCOI.current) {
      fileInputRefCOI.current.value = "";
      setFileSelectedCOI(false);
    }
  };

  const handleFileChangeMOA = (event) => {
    const selectedFilesMOA = event.target.files;
    if (selectedFilesMOA.length > 0) {
      const MOAfileNames = Array.from(selectedFilesMOA).map(
        (file) => file.name
      );
      setSelectedMOA(MOAfileNames); // Set the names of selected files
      setShowTableMOA(true);
      setFileSelectedMOA(true);
      objAdditionalForm.Documents.MemorandumOfAssociation = MOAfileNames[0];
    } else {
      setFileSelectedMOA(false);
      objAdditionalForm.Documents.MemorandumOfAssociation = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearMOA = (indexToRemove) => {
    const updatedFiles = selectedMOA.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedMOA(updatedFiles);
    if (fileInputRefMOA.current) {
      fileInputRefMOA.current.value = "";
      setFileSelectedMOA(false);
    }
  };

  const handleFileChangeVAT = (event) => {
    const selectedFilesVAT = event.target.files;
    if (selectedFilesVAT.length > 0) {
      const VATfileNames = Array.from(selectedFilesVAT).map(
        (file) => file.name
      );
      setSelectedVAT(VATfileNames); // Set the names of selected files
      setShowTableVAT(true);
      setFileSelectedVAT(true);
      objAdditionalForm.Documents.CertificateOfVATRegistration =
        VATfileNames[0];
    } else {
      setFileSelectedVAT(false);
      objAdditionalForm.Documents.CertificateOfVATRegistration = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearVAT = (indexToRemove) => {
    const updatedFiles = selectedVAT.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedVAT(updatedFiles);
    if (fileInputRefVAT.current) {
      fileInputRefVAT.current.value = "";
      setFileSelectedVAT(false);
    }
  };

  const handleFileChangeShareholders = (event) => {
    const selectedFilesShareholders = event.target.files;
    if (selectedFilesShareholders.length > 0) {
      const ShareholdersfileNames = Array.from(selectedFilesShareholders).map(
        (file) => file.name
      );
      setSelectedShareholders(ShareholdersfileNames); // Set the names of selected files
      setShowTableShareholder(true);
      setFileSelectedShareholders(true);
      objAdditionalForm.Documents.ListOfShareholders = ShareholdersfileNames[0];
    } else {
      setFileSelectedShareholders(false);
      objAdditionalForm.Documents.ListOfShareholders = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearShareholders = (indexToRemove) => {
    const updatedFiles = selectedShareholders.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedShareholders(updatedFiles);
    if (fileInputRefShareholders.current) {
      fileInputRefShareholders.current.value = "";
      setFileSelectedShareholders(false);
    }
  };

  const handleFileChangeMoE = (event) => {
    const selectedFilesMoE = event.target.files;
    if (selectedFilesMoE.length > 0) {
      const MoEfileNames = Array.from(selectedFilesMoE).map(
        (file) => file.name
      );
      setSelectedMoE(MoEfileNames); // Set the names of selected files
      setShowTableMoE(true);
      setFileSelectedMoE(true);
      objAdditionalForm.Documents.RegistrationIssuedByMinistryOfEnergy =
        MoEfileNames[0];
    } else {
      setFileSelectedMoE(false);
      objAdditionalForm.Documents.RegistrationIssuedByMinistryOfEnergy = "";
    }
    setObjAdditionalForm(objAdditionalForm);
  };
  const handleClearMoE = (indexToRemove) => {
    const updatedFiles = selectedMoE.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedMoE(updatedFiles);
    if (fileInputRefMoE.current) {
      fileInputRefMoE.current.value = "";
      setFileSelectedMoE(false);
    }
  };

  // const handleFileChangeOther = (event) => {
  //   const selectedFilesOther = event.target.files;
  //   if (selectedFilesOther.length > 0) {
  //     const OtherfileNames = Array.from(selectedFilesOther).map(file => file.name);
  //     //setSelectedOther(prevFiles => [...prevFiles, ...OtherfileNames]);
  //     setSelectedOther(OtherfileNames); // Set the names of selected files
  //     setShowTableOther(true);
  //   }
  // };
  // const handleClearOther = function (indexToRemove) {
  //   var attachments = document.getElementById("FileOther").files; // <-- reference your file input here
  //   var fileBuffer = new DataTransfer();

  //   // append the file list to an array iteratively
  //   for (let i = 0; i < attachments.length; i++) {
  //     // Exclude file in specified index
  //     if (indexToRemove !== i)
  //       fileBuffer.items.add(attachments[i]);
  //   }

  //   // Assign buffer to file input
  //   document.getElementById("FileOther").files = fileBuffer.files; // <-- according to your file input reference
  //   const updatedFiles = selectedOther.filter((_, index) => index !== indexToRemove);
  //   setSelectedOther(updatedFiles);
  // }

  function addFileToUploadList() {
    const attachments2 = document.getElementById("FileOther2").files;
    var fileBuffer = new DataTransfer();
    for (let i = 0; i < attachments2.length; i++) {
      fileBuffer.items.add(attachments2[i]);
    }

    const attachments1 = document.getElementById("FileOther1").files;
    for (let i = 0; i < attachments1.length; i++) {
      fileBuffer.items.add(attachments1[i]);
    }

    document.getElementById("FileOther2").files = fileBuffer.files;
    document.getElementById("FileOther1").files = new DataTransfer().files;
    const newSelectedOther = [];
    for (let i = 0; i < fileBuffer.files.length; i++) {
      newSelectedOther.push(fileBuffer.files[i].name);
    }

    // Update the state to reflect the new files
    setSelectedOther(newSelectedOther); // Assuming setSelectedOther is the state update function
    setShowTableOther(true);
  }
  function removeFileFromUploadList(indexToRemove) {
    var attachments = document.getElementById("FileOther2").files;
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < attachments.length; i++) {
      if (indexToRemove !== i) fileBuffer.items.add(attachments[i]);
    }

    document.getElementById("FileOther2").files = fileBuffer.files;
    const updatedFiles = selectedOther.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedOther(updatedFiles);
  }
  function handleChangeFile(e) {
    addFileToUploadList();
  }

  const [filesSharePoint, setFilesSharePoint] = useState([]);

  const getSharePointFiles = async (id) => {
    const results = await SharePointFolder.getFileByAccount(id);
    const filesSharePoint = results.responseData;

    const fin = filesSharePoint.find(
      (item) => item.Name === "Account_Common_CTR_Financial"
    );
    const coi = filesSharePoint.find((item) => item.Name === "Account_Common_CTR_COI");
    const moa = filesSharePoint.find((item) => item.Name === "Account_Common_CTR_MOA");
    const shr = filesSharePoint.find(
      (item) => item.Name === "Account_Common_CTR_List_Sharehold"
    );
    const vat = filesSharePoint.find((item) => item.Name === "Account_Common_CTR_VAT_Reg");
    const moe = filesSharePoint.find(
      (item) => item.Name === "Account_Common_CTR_Reg_by_MoE"
    );
    const oth = filesSharePoint.find(
      (item) => item.Name === "Account_Common_CTR_Others"
    );

    setFilesSharePoint({
      Account_Common_CTR_Financial: fin || [],
      Account_Common_CTR_COI: coi || [],
      Account_Common_CTR_MOA: moa || [],
      Account_Common_CTR_List_Sharehold: shr || [],
      Account_Common_CTR_VAT_Reg: vat || [],
      Account_Common_CTR_Reg_by_MoE: moe || [],
      Account_Common_CTR_Others: oth || [],
    });
  };

  const findInSharePointFolders = (folderName, fileName) => {
    return filesSharePoint[folderName]?.Files?.find(
      (item) => item.Name === fileName
    );
  };

  const getPathSharePointFolders = (folderName) => {
    return filesSharePoint[folderName]?.Path;
  };

  const downloadFile = async (folderName, fileName) => {
    try {
      const path = getPathSharePointFolders(folderName);
      if (!fileName) {
        throw new Error("Filename is not defined");
      }
      const response = await SharePointFolder.downloadFile(path, fileName);
      const responseData = response.responseData;

      if (responseData && responseData.length > 0) {
        const mimeType = "application/octet-stream";
        const blob = new Blob([responseData], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
            Required Document Check list
          </Typography>
        </Box>

        {/* Audited Financial Statements with Notes to the Financial Statements for the last 3 years */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            {/* 1 */}
            <Typography variant="subtitle1" gutterBottom>
              {fileSelectedFirst && fileSelectedSecond && fileSelectedThird ? (
                <CheckIconTrue sx={{ color: green[500] }} />
              ) : (
                <CheckIconFlase sx={{ color: grey[500] }} />
              )}
              1. Audited Financial Statements with Notes to the Financial
              Statements for the last 3 years
              <Box sx={{ textIndent: 20 }}>
                {/* {showTableFinancial && selectedFinancialFirst.length > 0 && (
                  <Box sx={{ p: 1, m: 1, width: "100%" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 800 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead sx={{ backgroundColor: "#6699FF" }}>
                          <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell align="left">
                              File Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedFinancialFirst.map((fileName, index) => (
                            <TableRow key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">
                                {fileName}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )} */}

                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.1 First Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={FileChangeFinancialFirst}
                        id="FinancialFirst"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefFirst}
                      />
                      <label htmlFor="FinancialFirst">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableFirstYear
                        ? selectedFinancialFirst.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearFinancialFirst(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents?.FinancialStatement
                            ?.FirstYear &&
                          findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objAdditionalForm.Documents.FinancialStatement
                              .FirstYear
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_Financial",
                                //   objAdditionalForm
                                //     .Documents
                                //     .FinancialStatement
                                //     .FirstYear
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents.FinancialStatement
                                  .FirstYear
                              }
                              <ClearIcon
                                sx={{
                                  color: red[500],
                                  cursor: "pointer",
                                  mt: -0.5,
                                }}
                                onClick={() => {
                                  objAdditionalForm.Documents.FinancialStatement.FirstYear =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.2 Second Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={FileChangeFinancialSecond}
                        id="FinancialSecond"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefSecond}
                      />
                      <label htmlFor="FinancialSecond">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableSecondYear
                        ? selectedFinancialSecond.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() =>
                                  handleClearFinancialSecond(index)
                                }
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents?.FinancialStatement
                            ?.SecondYear &&
                          findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objAdditionalForm.Documents.FinancialStatement
                              .SecondYear
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_Financial",
                                //   objAdditionalForm
                                //     .Documents
                                //     .FinancialStatement
                                //     .SecondYear
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents.FinancialStatement
                                  .SecondYear
                              }
                              <ClearIcon
                                sx={{
                                  color: red[500],
                                  cursor: "pointer",
                                  mt: -0.5,
                                }}
                                onClick={() => {
                                  objAdditionalForm.Documents.FinancialStatement.SecondYear =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.3 Third Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={FileChangeFinancialThird}
                        id="FinancialThird"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefThird}
                      />
                      <label htmlFor="FinancialThird">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableThirdYear
                        ? selectedFinancialThird.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearFinancialThird(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents?.FinancialStatement
                            ?.ThirdYear &&
                          findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objAdditionalForm.Documents.FinancialStatement
                              .ThirdYear
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_Financial",
                                //   objAdditionalForm
                                //     .Documents
                                //     .FinancialStatement
                                //     .ThirdYear
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents.FinancialStatement
                                  .ThirdYear
                              }
                              <ClearIcon
                                sx={{
                                  color: red[500],
                                  cursor: "pointer",
                                  mt: -0.5,
                                }}
                                onClick={() => {
                                  objAdditionalForm.Documents.FinancialStatement.ThirdYear =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>

            {/* (COI) */}
            <Typography variant="subtitle1" gutterBottom>
              {fileSelectedCOI ? (
                <CheckIconTrue sx={{ color: green[500] }} />
              ) : (
                <CheckIconFlase sx={{ color: grey[500] }} />
              )}
              2. Certificate of Incorporation (COI)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChangeCOI}
                        id="FileCOI"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefCOI}
                      />
                      <label htmlFor="FileCOI">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableCOI
                        ? selectedCOI.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearCOI(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents
                            ?.CertificateOfIncorporation &&
                          findInSharePointFolders(
                            "Account_Common_CTR_COI",
                            objAdditionalForm.Documents
                              .CertificateOfIncorporation
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_COI",
                                //   objAdditionalForm
                                //     .Documents
                                //     .CertificateOfIncorporation
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents
                                  .CertificateOfIncorporation
                              }
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => {
                                  objAdditionalForm.Documents.CertificateOfIncorporation =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>

            {/* (MOA) */}
            <Typography variant="subtitle1" gutterBottom>
              {fileSelectedMOA ? (
                <CheckIconTrue sx={{ color: green[500] }} />
              ) : (
                <CheckIconFlase sx={{ color: grey[500] }} />
              )}
              3. Memorandum of Association (MOA)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChangeMOA}
                        id="FileMOA"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefMOA}
                      />
                      <label htmlFor="FileMOA">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableMOA
                        ? selectedMOA.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearMOA(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents
                            ?.MemorandumOfAssociation &&
                          findInSharePointFolders(
                            "Account_Common_CTR_MOA",
                            objAdditionalForm.Documents.MemorandumOfAssociation
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_MOA",
                                //   objAdditionalForm
                                //     .Documents
                                //     .MemorandumOfAssociation
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents
                                  .MemorandumOfAssociation
                              }
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => {
                                  objAdditionalForm.Documents.MemorandumOfAssociation =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>

            {/* VAT Registration */}
            <Typography variant="subtitle1" gutterBottom>
              {fileSelectedVAT ? (
                <CheckIconTrue sx={{ color: green[500] }} />
              ) : (
                <CheckIconFlase sx={{ color: grey[500] }} />
              )}
              4. Certificate of VAT Registration (ภ.พ.20)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChangeVAT}
                        id="FileVAT"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefVAT}
                      />
                      <label htmlFor="FileVAT">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableVAT
                        ? selectedVAT.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearVAT(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents
                            ?.CertificateOfVATRegistration &&
                          findInSharePointFolders(
                            "Account_Common_CTR_VAT_Reg",
                            objAdditionalForm.Documents
                              .CertificateOfVATRegistration
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_VAT_Reg",
                                //   objAdditionalForm
                                //     .Documents
                                //     .CertificateOfVATRegistration
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents
                                  .CertificateOfVATRegistration
                              }
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => {
                                  objAdditionalForm.Documents.CertificateOfVATRegistration =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>

            {/* Shareholders */}
            <Typography variant="subtitle1" gutterBottom>
              {fileSelectedShareholders ? (
                <CheckIconTrue sx={{ color: green[500] }} />
              ) : (
                <CheckIconFlase sx={{ color: grey[500] }} />
              )}
              5. List of the Shareholders (Bor.Or.Jor.5)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChangeShareholders}
                        id="FileShareholder"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefShareholders}
                      />
                      <label htmlFor="FileShareholder">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableShareholder
                        ? selectedShareholders.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearShareholders(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents?.ListOfShareholders &&
                          findInSharePointFolders(
                            "Account_Common_CTR_List_Sharehold",
                            objAdditionalForm.Documents.ListOfShareholders
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_List_Sharehold",
                                //   objAdditionalForm
                                //     .Documents
                                //     .ListOfShareholders
                                // )
                              }}
                            >
                              {objAdditionalForm.Documents.ListOfShareholders}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => {
                                  objAdditionalForm.Documents.ListOfShareholders =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>

            {/* MoE */}
            <Typography variant="subtitle1" gutterBottom>
              {fileSelectedMoE ? (
                <CheckIconTrue sx={{ color: green[500] }} />
              ) : (
                <CheckIconFlase sx={{ color: grey[500] }} />
              )}
              6. Registration Issued by Ministry of Energy (For petroleum)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChangeMoE}
                        id="FileMoE"
                        variant="outlined"
                        // multiple  // Allow multiple file selection
                        ref={fileInputRefMoE}
                      />
                      <label htmlFor="FileMoE">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>{" "}
                        &nbsp;
                      </label>
                      {showTableMoE
                        ? selectedMoE.map((fileName, index) => (
                            <Typography key={index} variant="outlined">
                              {fileName}
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => handleClearMoE(index)}
                              />
                            </Typography>
                          ))
                        : objAdditionalForm?.Documents
                            ?.RegistrationIssuedByMinistryOfEnergy &&
                          findInSharePointFolders(
                            "Account_Common_CTR_Reg_by_MoE",
                            objAdditionalForm.Documents
                              .RegistrationIssuedByMinistryOfEnergy
                          ) && (
                            <Typography
                              variant="outlined"
                              onClick={(e) => {
                                // e.preventDefault()
                                // downloadFile(
                                //   "Account_Common_CTR_Reg_by_MoE",
                                //   objAdditionalForm
                                //     .Documents
                                //     .RegistrationIssuedByMinistryOfEnergy
                                // )
                              }}
                            >
                              {
                                objAdditionalForm.Documents
                                  .RegistrationIssuedByMinistryOfEnergy
                              }
                              <ClearIcon
                                sx={{ color: red[500], cursor: "pointer" }}
                                onClick={() => {
                                  objAdditionalForm.Documents.RegistrationIssuedByMinistryOfEnergy =
                                    "";
                                  setObjAdditionalForm({
                                    ...objAdditionalForm,
                                  });
                                }}
                              />
                            </Typography>
                          )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>

            {/* Other File */}
            <Typography variant="subtitle1" gutterBottom>
              7. Other File (If any)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ textIndent: 0, p: 1, pl: 2 }}>
                      {/* Input element for file selection */}
                      <input
                        type="file"
                        id="FileOther2"
                        variant="outlined"
                        multiple // Allow multiple file selection
                        style={{ display: "none" }}
                      />

                      {/* Input element for file selection */}
                      <input
                        type="file"
                        onChange={handleChangeFile}
                        id="FileOther1"
                        variant="outlined"
                        multiple // Allow multiple file selection
                        style={{ display: "none" }}
                      />
                      <label htmlFor="FileOther1">
                        <Button variant="outlined" component="span">
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Box>
                {/* {showTableOther && selectedOther.length > 0 && (
                  <Box sx={{ p: 1, m: 1, width: "50%" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead sx={{ backgroundColor: "#6699FF" }}>
                          <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell align="left">
                              File Name
                            </TableCell>
                            <TableCell align="left">
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedOther.map((fileName, index) => (
                            <TableRow key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">
                                {fileName}
                              </TableCell>
                              <TableCell align="center">
                                <ClearIcon
                                  sx={{ color: red[500], cursor: 'pointer' }}
                                  onClick={() => removeFileFromUploadList(index)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )} */}
                <TableContainer component={Paper} sx={{ m: 2 }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width="120px">
                          No.
                        </TableCell>
                        <TableCell align="left">File Name</TableCell>
                        <TableCell align="center" width="150px">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {objAdditionalForm?.Documents?.Others?.map(
                        (item, index) => {
                          const files = findInSharePointFolders(
                            "Account_Common_CTR_Others",
                            item
                          );
                          if (files) {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                >
                                  <span
                                    className="link-download"
                                    href="#!"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      downloadFile(
                                        "Account_Common_CTR_Others",
                                        files.Name
                                      );
                                    }}
                                  >
                                    {files.Name}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  <ClearIcon
                                    data-name={files.Name}
                                    sx={{ color: red[500], cursor: "pointer" }}
                                    onClick={(evt) => {
                                      const index =
                                        objAdditionalForm.Documents.Others.findIndex(
                                          (item) => {
                                            return (
                                              item ===
                                              evt.currentTarget.dataset.name
                                            );
                                          }
                                        );
                                      objAdditionalForm.Documents.Others.splice(
                                        index,
                                        1
                                      );
                                      setObjAdditionalForm({
                                        ...objAdditionalForm,
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                      {selectedOther?.map((fileName, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            {fileName}
                          </TableCell>
                          <TableCell align="center">
                            <ClearIcon
                              sx={{ color: red[500], cursor: "pointer" }}
                              onClick={() => removeFileFromUploadList(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Typography>
          </Box>
        </Box>
      </CardContent>
      {/* visible submit update btn */}
      <Button
        id="check-form-data-register"
        style={{
          visibility: "hidden",
        }}
        onClick={() => {
          objAdditionalForm.Documents.Others.push(...selectedOther);
          formDataSave(objAdditionalForm);
        }}
      >
        submit
      </Button>
      <Button
        id="check-form-data-register-save-draft"
        style={{
          visibility: "hidden",
        }}
        onClick={() => {
          objAdditionalForm.Documents.Others.push(...selectedOther);
          formDataDraft(objAdditionalForm);
        }}
      >
        update
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={required.active}
        autoHideDuration={6000}
        onClose={() => {
          setRequired({
            fields: "",
            active: false,
          });
        }}
      >
        <Alert
          onClose={() => {
            setRequired({
              fields: "",
              active: false,
            });
          }}
          variant="filled"
          severity="warning"
        >
          Required Fields <b> {required.fields}</b>
        </Alert>
      </Snackbar>
    </Card>
  );
}
