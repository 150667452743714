import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Button, Stepper, Typography } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useNavigate } from "react-router-dom";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(21, 104, 60 ) 0%, rgba(21, 104, 60 ) 43%, rgba(21, 104, 60 ) 100%);",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 72,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(21, 104, 60 ) 0%, rgba(21, 104, 60 ) 43%, rgba(21, 104, 60 ) 100%);",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      style={{
        color: completed || active ? "#fff" : "black",
        backgroundColor: "white",
        border: "1px dashed #b5b5b5",
      }}
    >
      {completed ? <CheckIcon /> : <>{props.icon}</>}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = [
  "Registered",
  "Review",
  "Submit Documents",
  "Verify Counterparty",
  "In Approval Process",
  "Completed",
];

export default function ModalPreScreeningForm(props) {
  const navigate = useNavigate();

  function closeModal() {
    props.onHide();
  }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={props.open}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <Box>
          <Stepper
            alternativeLabel
            activeStep={2}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <Typography style={{ height: "50px" }}>{label}</Typography>
                <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />

          <Typography variant="subtitle1">
            Thank you for your registration.
          </Typography>
          <Typography variant="subtitle1">Your request number is</Typography>
          <Typography variant="inherit" style={{ fontWeight: "bold" }}>
            {props.request ? props.request : "No request id"}
          </Typography>
          <Typography variant="subtitle1">
            We will inform you once the information is verified.
          </Typography>
          <br />
          <Button variant="contained" onClick={() => navigate("/main")}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust transparency here
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "50%",
    zIndex: 10000, // Higher z-index for the modal content
  },
};
