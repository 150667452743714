import * as React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import ModalAnnualReviewForm from "../components/AnnualReview/Modal/ModalAnnualReviewForm"
import Swal from "sweetalert2/dist/sweetalert2.js";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";
import moment from 'moment';
import { SharePointFolder } from "../services/apigateway";

function getMimeType(fileExtension) {
    switch (fileExtension.toLowerCase()) {
        case 'pdf':
            return 'application/pdf';
        case 'doc':
        case 'docx':
            return 'application/msword';
        case 'xls':
        case 'xlsx':
            return 'application/vnd.ms-excel';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        default:
            return 'application/octet-stream'; // Default MIME type for unknown file types
    }
}

const downloadFileFinancial = async (filename) => {
    try {
        const foldername = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
        if (!filename) {
            throw new Error('Filename is not defined');
        }
        const response = await SharePointFolder.downloadFile(foldername, filename);
        console.log(response);

        const responseData = response.responseData;
        console.log(responseData);

        if (responseData && responseData.length > 0) {
            const fileExtension = filename.split('.').pop(); // Get file extension
            const mimeType = getMimeType(fileExtension); // Get MIME type based on extension

            const blob = new Blob([responseData], { type: mimeType });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    } catch (ex) {
        console.error(ex);
    }
};


export default function AnnualReviewScreen() {
    const [openModal, setOpenModal] = React.useState(false);
    const [listFinancialData, setListFinancialData] = useState([]);
    const [selectedFileNames, setSelectedFileNames] = useState([]);
    const [showTable, setShowTable] = useState(true);

    const uploadFileToServer = async (file) => {
        try {
            const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
            const uploadResponse = await SharePointFolder.uploadFile(namefolder, [file]);
            console.log('Upload response:', uploadResponse);
            // Handle the response or perform further actions based on 'uploadResponse'
        } catch (error) {
            console.error('Error while uploading:', error);
            if (error.response) {
                console.error('Response:', error.response); // Log the entire response for inspection
            }
            // Handle the error here
        }
    };

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles.length > 0) {
            const fileNames = Array.from(selectedFiles).map(file => file.name);
            setSelectedFileNames(fileNames); // Set the names of selected files
            //uploadFilesToServer(selectedFiles); // Initiating file upload process for multiple files
        }
    };

    // const handleSubmit = async function (e) {
    //     e.preventDefault();
    //     const fileInput = document.getElementById('fileInput'); // Get the file input element
    //     if (fileInput.files.length > 0) {
    //         const selectedFile = fileInput.files;
    //         Array.from(selectedFile).forEach(async file => {
    //             await uploadFileToServer(file);
    //         });
    //     } else {
    //     }
    // }
    const handleSubmit = async function (e) {
        try {
            const fileInput = document.getElementById('fileInput'); // Get the file input element
            // if (fileInput.files.length > 0) {
            //     const selectedFile = fileInput.files;
            //     Array.from(selectedFile).forEach(async file => {
            //         await uploadFileToServer(file);
            //     });
            // }
            if (fileInput.files.length > 0) {
                const selectedFiles = fileInput.files;
                for (const file of selectedFiles) {
                    await uploadFileToServer(file);
                }
            }
            const folderName = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
            const updatedResponse = await SharePointFolder.getFileByFolder(folderName); // Assuming you retrieve updated data
            const updatedData = updatedResponse?.responseData[0]?.value || [];
            // Update listFinancialData with the new financial data
            const formattedUpdatedData = updatedData.map(file => ({
                Name: file.Name,
                TimeLastModified: file.TimeLastModified,
                // Add other properties you want to display
            }));
            setListFinancialData(formattedUpdatedData);
            setShowTable(false);
        } catch (error) {
            console.error(error);
            // Handle errors appropriately
        }
    };

    useEffect(() => {
        const FinancialData = async () => {
            try {
                const folderName = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
                const response = await SharePointFolder.getFileByFolder(folderName);
                console.log(response);
                console.log(response.responseData[0].value[0].Name);
                console.log(response.responseData[0].value[0].TimeLastModified);
                const responseData = response && response.responseData ? response.responseData[0].value : [];
                if (Array.isArray(responseData)) {
                    const formattedData = responseData.map(file => ({
                        Name: file.Name,
                        TimeLastModified: file.TimeLastModified,
                        // Add other properties you want to display
                    }));
                    setListFinancialData(formattedData);
                    console.log(formattedData);
                } else {
                    console.error("Data structure from SharePoint is not as expected.");
                }
            } catch (error) {
                console.error(error);
            }
        };
        FinancialData();
    }, []);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(/* pass form data if needed */);
        }}>
            <Card>
                <CardContent>
                    <h1 className="header-title">Financial Statement</h1>

                    <Box sx={{ backgroundColor: "#dbd9d9", m: 2 }}>
                        <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                            Existing Document
                        </Typography>
                    </Box>
                    {/* row1 Table*/}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                        }}
                    >
                        <Box sx={{ p: 1, m: 1, width: "100%" }}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 800 }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead sx={{ backgroundColor: "#dbd9d9" }}>
                                        <TableRow>
                                            <TableCell>No.</TableCell>
                                            <TableCell align="center">
                                                File Name
                                            </TableCell>
                                            <TableCell align="center">
                                                Uploaded Date/Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listFinancialData.map((file, index) => (
                                            <TableRow key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center">
                                                    <a href="#!" onClick={(e) => { e.preventDefault(); downloadFileFinancial(file.Name); }}>
                                                        {file.Name}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="center">{moment(file.TimeLastModified).format('DD/MM/YYYY, HH:mm:ss')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>

                    <Box sx={{ backgroundColor: "#dbd9d9", m: 2 }}>
                        <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                            Financial Statemen
                        </Typography>
                    </Box>
                    {/* row1 Table*/}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                        }}
                    >
                        {showTable && selectedFileNames.length > 0 && (
                            <Box sx={{ p: 1, m: 1, width: "100%" }}>
                                <TableContainer component={Paper}>
                                    <Table
                                        sx={{ minWidth: 800 }}
                                        size="small"
                                        aria-label="a dense table"
                                    >
                                        <TableHead sx={{ backgroundColor: "#dbd9d9" }}>
                                            <TableRow>
                                                <TableCell>No.</TableCell>
                                                <TableCell align="left">
                                                    File Name
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedFileNames.map((fileName, index) => (
                                                <TableRow key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        {fileName}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    </Box>
                    {/* 2 */}
                    <Typography sx={{ ml: 2 }} variant="subtitle1" gutterBottom>
                        Please upload the latest audited financial statements with notes
                        <Box sx={{ textIndent: 20 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                                <Box className="upload-box" sx={{ ml: 2 }}>
                                    <Box sx={{ p: 1, mr: 2 }}>
                                        {/* Input element for file selection */}
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleFileInputChange}
                                            id="fileInput"
                                            variant="outlined"
                                            multiple  // Allow multiple file selection
                                        />
                                        {/* Label and styled button to trigger file selection */}
                                        <label htmlFor="fileInput">
                                            <Button variant="outlined" component="span">
                                                Upload
                                            </Button>
                                        </label>
                                        &nbsp;or Drop Files
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Typography>

                    {/* button */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            sx={{ p: 1, m: 1 }}
                            variant="outlined"
                            color="error"
                        >
                            Cancel
                        </Button>

                        <Button
                            sx={{ p: 1, m: 1 }}
                            variant="contained"
                            onClick={() => {
                                Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: "Success",
                                    text: "Your request has been uploaded successfully.\nWe will proceed the next steps.",
                                    showConfirmButton: true,
                                    confirmButtonColor: "#3085d6",
                                });
                            }}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Box>
                    {/* modal registration-complete form */}
                    <ModalAnnualReviewForm
                        open={openModal}
                        onHide={() => setOpenModal(false)}
                    />
                </CardContent>
            </Card>
        </form>
    );
}