import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Basiclayout = ({ component }) => {
  return (
    <React.Fragment>
      <Header />
      <div className="App">
        <div className="App-header">{component}</div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Basiclayout;
