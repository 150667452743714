import React, { useEffect, useState } from "react";
import helper from "../services/helper";
import Header from "./Header";
import Footer from "./Footer";

const AuthGuard = ({ component }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const isLogin = helper.Auth.isLOGIN();
    if (!isLogin) {
      window.location.href = `/`;
    } else {
      setLoading(false);
    }
  }, [component]);

  if (loading) return <></>;

  return (
    <React.Fragment>
      <Header />
      <div className="App">
        <div className="App-header">{component}</div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default AuthGuard;
