import * as React from "react";
import Background from "../assets/img/footer.png";

function Footer() {
  return (
    <div className="App-footer" style={{
      backgroundImage: `url(${Background})`,
      backgroundSize: "500px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
      borderBottom: "2px solid #b6b6b6"
    }}>
      <div style={{ color: "black", fontSize: 12, paddingTop: "10px" }}>
        <p>
          &copy;
          <a href="https://thaioilgroup.com">Thai Oil Main Page</a>
          &nbsp;|&nbsp;
          <a href="mailto:pttdigital-callcenter@thaioilgroup.com">Contact Us</a>
        </p>
        <p>&copy; COPYRIGHT 2023 A COMPANY OF THAI OIL GROUP.ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
}
export default Footer;
