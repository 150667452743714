import * as React from 'react';
// import logo from '../assets/thaioil.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PasswordChecklist from "react-password-checklist"
import { CTRUserAPI } from '../services/apigateway';

// css-layouts
import "../styles/SetPassword.css";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";

export default function SetPasswordScreen() {

    const [requiredFields, setRequiredFields] = useState({});
    const [passwordShown, setPasswordShown] = React.useState(false);
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [Question, setQuestion] = useState("");
    const [Answer, setAnswer] = useState("");
    const isLengthValid = password.length >= 8;
    const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasCapital = /[A-Z]/.test(password);

    const navigate = useNavigate();
    const [id, setId] = React.useState('');
    const [email, setEmail] = React.useState('');

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        (async function () {
            const ctrUser = await CTRUserAPI.findUsernameByInitialKey({
                secret: urlParams.get('secret')
            });
            if (!ctrUser || ctrUser.records.length === 0) {
                navigate('/invalidurl')
                return
            }
            setId(ctrUser.records[0].Id);
            setEmail(ctrUser.records[0].Email__c);
        })();
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const validateForm = () => {
        const isNewPasswordValid = isLengthValid && hasSpecialChar && hasNumber && hasCapital;
        const isConfirmPasswordValid = password === passwordAgain;
        const isQuestionValid = !!Question;
        const isAnswerValid = !!Answer;

        // Update the form validity
        setIsFormValid(isNewPasswordValid && isConfirmPasswordValid && isQuestionValid && isAnswerValid);
    };

    const handleSubmit = async function (e) {
        e.preventDefault();

        const urlParams = new URLSearchParams(window.location.search);
        const resetResult = await CTRUserAPI.initialPassword({
            id: id,
            password: document.getElementById('password').value,
            secret: urlParams.get('secret'),
            securityQuestion: document.getElementById('question').value,
            securityAnswer: document.getElementById('answer').value,
        });

        if (resetResult.status === 'SUCCESS') {
            navigate('/');
            return;
        }

        navigate('/errorresetpassword');
    }

    return (
        <form onSubmit={handleSubmit} className="SetPassword">
            <div className="slds-large-size_1-of-4 slds-medium-size_1-of-2 slds-size_1-of-1 slds-p-around_xx-small box-absolute-center">
                {/* <img src={logo} className="logo" alt="logo" /> */}
                <h1 className="box-caption">Change Your Password</h1>
                <div className="box">
                    <div className="slds-form-element">
                        <label className="slds-form-element__label" htmlFor="email">Email Address</label>
                        <div className="slds-form-element__control">
                            <input
                                type="text"
                                id="email"
                                placeholder="email@thaioilgroup.com"
                                value={email}
                                className="slds-input" disabled />
                        </div>
                    </div>
                    <ul>
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital"]}
                            minLength={8}
                            value={password}
                            valueAgain={passwordAgain}
                            messages={{
                                minLength: "8 characters",
                                specialChar: "special characters",
                                number: "1 number",
                                capital: "1 letter",
                            }}
                        />
                    </ul>
                    <div className={requiredFields.isBlankpassword ? "slds-form-element slds-has-error" : "slds-form-element"}>
                        <label className="slds-form-element__label" htmlFor="password">New Password<abbr className="slds-required" title="required">* </abbr></label>
                        <div className="slds-form-element__control">
                            <input
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                placeholder=""
                                className="slds-input"
                                required
                                //onBlur={(e) => setRequiredFields({ ...requiredFields, isBlankpassword: !e.currentTarget.value })}
                                onBlur={(e) => {
                                    setRequiredFields({ ...requiredFields, isBlankpassword: !e.currentTarget.value });
                                    setPassword(e.target.value);
                                    validateForm();
                                }}
                                onChange={e => setPassword(e.target.value)} />
                            <span onClick={() => setPasswordShown(!passwordShown)}>
                                <i
                                    className={
                                        passwordShown
                                            ? "bi bi-eye toggle-hide-pass"
                                            : "bi bi-eye-slash toggle-hide-pass"
                                    }
                                    id="togglePassword"
                                ></i>
                            </span>
                        </div>
                    </div>
                    <div className={requiredFields.isBlankconfirmpassword ? "slds-form-element slds-has-error" : "slds-form-element"}>
                        <label className="slds-form-element__label" htmlFor="confirmpassword">Confirm New Password<abbr className="slds-required" title="required">* </abbr></label>
                        <div className="slds-form-element__control">
                            <input
                                type={passwordShown ? "text" : "password"}
                                id="confirmpassword" placeholder=""
                                className="slds-input" required
                                //onBlur={(e) => setRequiredFields({ ...requiredFields, isBlankconfirmpassword: !e.currentTarget.value })}
                                onBlur={(e) => {
                                    setRequiredFields({ ...requiredFields, isBlankconfirmpassword: !e.currentTarget.value });
                                    setPasswordAgain(e.target.value);
                                    validateForm();
                                }}
                                onChange={e => setPasswordAgain(e.target.value)} />
                            <span onClick={() => setPasswordShown(!passwordShown)}>
                                <i
                                    className={
                                        passwordShown
                                            ? "bi bi-eye toggle-hide-pass"
                                            : "bi bi-eye-slash toggle-hide-pass"
                                    }
                                    id="togglePassword"
                                ></i>
                            </span>
                        </div>
                    </div>
                    <ul>
                        <PasswordChecklist
                            rules={["match"]}
                            minLength={8}
                            value={password}
                            valueAgain={passwordAgain}
                            messages={{
                                match: "Passwords match",
                            }}
                        />
                    </ul>
                    <hr className="hr" />
                    <div className={requiredFields.isBlankquestion ? "slds-form-element slds-has-error" : "slds-form-element"}>
                        <label className="slds-form-element__label" htmlFor="question">Security Question<abbr className="slds-required" title="required">* </abbr></label>
                        <div className="slds-form-element__control">
                            <div className="slds-select_container">
                                <select className="slds-select"
                                    id="question"
                                    required
                                    //onBlur={(e) => setRequiredFields({ ...requiredFields, isBlankquestion: !e.currentTarget.value })}
                                    onBlur={(e) => {
                                        setRequiredFields({ ...requiredFields, isBlankquestion: !e.currentTarget.value });
                                        setQuestion(e.currentTarget.value);
                                        validateForm();
                                    }}
                                    onChange={e => setQuestion(e.target.value)}
                                >
                                    <option value=""></option>
                                    <option value="In what city were you born?">In what city were you born?</option>
                                    <option value="In what city were you born?">What is your pet’s name?</option>
                                    <option value="In what city were you born?">What was your childhood nickname?</option>
                                    <option value="In what city were you born?">In what city was your first job?</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={requiredFields.isBlankanswer ? "slds-form-element slds-has-error" : "slds-form-element"}>
                        <label className="slds-form-element__label" htmlFor="answer">Answer<abbr className="slds-required" title="required">* </abbr></label>
                        <div className="slds-form-element__control">
                            <input
                                type="text"
                                id="answer"
                                placeholder=""
                                className="slds-input"
                                required
                                //onBlur={(e) => setRequiredFields({ ...requiredFields, isBlankanswer: !e.currentTarget.value })}
                                onBlur={(e) => {
                                    setRequiredFields({ ...requiredFields, isBlankanswer: !e.currentTarget.value });
                                    setAnswer(e.currentTarget.value);
                                    validateForm();
                                }}
                                onChange={e => setAnswer(e.target.value)} />
                        </div>
                    </div>
                    <div className="slds-grid slds-gutters">
                        <div className="slds-col">
                            <button className="slds-button slds-button_brand ctr-button_primary slds-size_1-of-1"
                                type="submit"
                                disabled={!isFormValid}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
