import React from "react";
import Modal from "react-modal";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import ExtensionStepper from "../ExtensionStepper";

const steps = [
  "Registered",
  "Review",
  "Submit Documents",
  "Verify Counterparty",
  "In Approval Process",
  "Completed",
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "50%",
  },
};

const StyledBox = styled(Box)({
  overflow: "auto"
});

export default function ModalExtensionForm(props) {
  const navigate = useNavigate();

  function closeModal() {
    props.onHide();
  }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={props.open}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <StyledBox>
          <ExtensionStepper steps={steps} activeStep={1} />
          <br />
        </StyledBox>

        <Box>
          <Typography variant="subtitle1">
            Thank you to your registration.
          </Typography>
          <Typography variant="subtitle1">
            Your request number is
          </Typography>
          <Typography variant="inherit" style={{ fontWeight: "bold" }}>
            {props.request ? props.request : "No request id"}
          </Typography>
          <Typography variant="subtitle1">
            Your registration will take effect once confirmed.
          </Typography>
          <Typography variant="subtitle1">
            the information has been confirmed.
          </Typography>
          <br />

          <Button variant="contained" onClick={() => navigate("/main")}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
