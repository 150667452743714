import * as React from "react";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import TextInput from "../TextInput";
import DateTimePicker from "../DateTimePicker";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { red } from "@mui/material/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { PreRegisterAPI, RegistrationFormAPI } from "../../services/apigateway";
import ErrorIcon from "@mui/icons-material/Error";

export default function ExtensionForm(props) {
  const [objRegistrationForm, setObjRegistrationForm] = useState({
    RequestFormHeaderId: "",
    RequestFormItemId: "",
    CustomerId: "",
    CompanyNameLocal: "",
    CompanyNameEN: "",
    CompanyNameFormer: "",
    CompanyNameLanguage: "",
    CompanyNameAlias: "",
    CustomerType: "",
    SupplierType: "",
    BusinessType: "",
    BusinessTypeOthers: "",
    LegalEntityType: "",
    LegalEntityTypeOthers: "",
    IncorporationDate: "",
    TAXNumber: "",
    Website: "",
    CEOFirstName: "",
    CEOLastName: "",
    Shareholders: [
      {
        Id: "",
        Name: "",
        PercentOfShareholder: 0,
        RequestFormHeaderId: "",
      },
    ],
    HouseNo: "",
    Building: "",
    Room: "",
    Floor: "",
    Street: "",
    District: "",
    SubDistrict: "",
    Disctrict: "",
    City: "",
    Country: "",
    PostalCode: "",
    LocationURL: "",
    Latitude: "",
    Longitude: "",
    MailingIsSameAsRegistered: false,
    MailingHouseNo: "",
    MailingBuilding: "",
    MailingRoom: "",
    MailingFloor: "",
    MailingStreet: "",
    MailingSubDistrict: "",
    MailingDistrict: "",
    MailingCity: "",
    MailingCountry: "",
    MailingPostalCode: "",
    ContactId: "",
    ContactSalutation: "",
    ContactNickName: "",
    ContactFirstName: "",
    ContactLastName: "",
    ContactPosition: "",
    ContactEmail: "",
    ContactMobileCountryCode: "",
    ContactMobileNumber: "",
    ContactPhoneCountryCode: "TH",
    ContactPhoneNumber: "",
    ContactFaxCountryCode: "",
    ContactFaxNumber: "",
    InterestedPetroleumAsCustomer: false,
    InterestedPetrochemicalAsCustomer: false,
    InterestedLubeBaseAsCustomer: false,
    InterestedLABIXAsCustomer: false,
    InterestedCrudeAsCustomer: false,
    InterestedLubeBaseAsCustomerDesc: "",
    InterestedLABIXAsCustomerDesc: "",
    InterestedCrudeAsSupplier: false,
    InterestedB100EthanolAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplierDesc: "",
    InterestedLongResidueAsSupplier: false,
    InterestedNormalParaffinAsSupplier: false,
    InterestedOtherAsCustomer: false,
    InterestedOtherAsCustomerDesc: "",
    InterestedOtherAsSupplier: false,
    InterestedOtherAsSupplierDesc: "",
    MinistryOfEnergyRegistrationNo: "",
    YearsOfExperience: 0,
    EstimatedMonthlyPurchaseQuantity: 0,
    EstimatedMonthlyPurchaseUnit: "",
    StartTradingDate: dayjs(),
    DestinationCountries: [
      {
        Id: "",
        Country: "",
        LocationType: "",
      },
    ],
    PurchaseProductFromCompany: "",
    SupplyProductToCompany: "",
    PaymentOverTheAmountOfUSD: "",
    ReasonOfPaymentOverTheAmountOfUSD: "",
    FailedSupplyAgreement: "",
    ReasonOfFailedSupplyAgreement: "",
    HaveTagetsInSanction: "",
    ReasonOfHaveTagetsInSanction: "",
    ViolatingAntiMoneyLaunderingLaws: "",
    ReasonOfViolatingAntiMoneyLaunderingLaws: "",
    CustomersFacilityTankAndStorage: "",
    // OwnStorageAsCustomer: false,
    // RentStorageMoreThanOneYearAsCustomer: false,
    // RentStorageLessThanOneYearAsCustomer: false,
    // NoStorageAsCustomer: false,
    CustomersFacilityTransportation: "",
    // OwnTransportAsCustomer: false,
    // RentTransportMoreThanOneYearAsCustomer: false,
    // RentTransportLessThanOneYearAsCustomer: false,
    // NoTransportAsCustomer: false,
    SuppliersFacilityTankAndStorage: "",
    // OwnStorageAsSupplier: false,
    // RentStorageMoreThanOneYearAsSupplier: false,
    // RentStorageLessThanOneYearAsSupplier: false,
    // NoStorageAsSupplier: false,
    SuppliersFacilityTransportation: "",
    // OwnTransportAsSupplier: false,
    // RentTransportMoreThanOneYearAsSupplier: false,
    // RentTransportLessThanOneYearAsSupplier: false,
    // NoTransportAsSupplier: false,
    EnvironmentPolicy: "",
    OccupationalHealthAndSafetyPolicy: "",
    CorporateGovernancePolicy: "",
    HumanRightsPolicy: "",
    SocialResponsibilityPolicy: "",
    CompanyLatestCreditRating: "",
  });

  const [required, setRequired] = useState({
    fields: "",
    active: false,
  });
  const [customerActive, setCustomerActive] = useState([]);
  const [supplierActive, setSupplierActive] = useState([]);

  const [listCountry, setListCountry] = useState([]);
  const [listProvince1, setListProvince1] = useState([]);
  const [listProvince2, setListProvince2] = useState([]);
  const [listSalutation, setListSalutation] = useState([]);
  const [listMobileCode, setListMobileCode] = useState([]);
  const [listPhoneCode, setListPhoneCode] = useState([]);
  const [listFaxCode, setListFaxCode] = useState([]);
  const [listShippingCountry, setListShippingCountry] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listLocationType, setListLocationType] = useState([]);
  const [listSpecifyLanguages, setListSpecifyLanguages] = useState([]);
  const [listCustomerType, setListCustomerType] = useState([]);
  const [listSupplierType, setListSupplierType] = useState([]);
  const [listBusinessType, setListBusinessType] = useState([]);
  const [listEntityLegal, setListEntityLegal] = useState([]);
  const [listEstimateMonthly, setListEstimateMonthly] = useState([]);
  const [emailValidation, setEmailValidation] = useState(false);
  const [listOilTrader, setListOilTrader] = useState([]);
  const [listYearsOfExperience, setListYearsOfExperience] = useState([]);

  React.useEffect(() => {
    (async function () {
      getMasterDataList();
      const picklistMainServices = await PreRegisterAPI.getPicklist({
        objectApiName: "Account",
        fieldApiName: "YearsofExperience__c",
      });
      setListYearsOfExperience(picklistMainServices);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.formData) {
      setObjRegistrationForm(props.formData);
      getProvinceForCountry(props.formData, 3);
    }

    //eslint-disable-next-line
  }, [props.formData]);

  useEffect(() => {
    isChecksCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    objRegistrationForm.InterestedCrudeAsCustomer,
    objRegistrationForm.InterestedPetrochemicalAsCustomer,
    objRegistrationForm.InterestedPetroleumAsCustomer,
    objRegistrationForm.InterestedLubeBaseAsCustomer,
    objRegistrationForm.InterestedLABIXAsCustomer,
    objRegistrationForm.InterestedOtherAsCustomer,
  ]);

  useEffect(() => {
    isChecksSupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    objRegistrationForm.InterestedCrudeAsSupplier,
    objRegistrationForm.InterestedB100EthanolAsSupplier,
    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier,
    objRegistrationForm.InterestedNormalParaffinAsSupplier,
    objRegistrationForm.InterestedOtherAsSupplier,
  ]);

  const getMasterDataList = async () => {
    const pSlList = RegistrationFormAPI.getSaluationList();
    const pMbList = RegistrationFormAPI.getMobileCodeList();
    const pPhList = RegistrationFormAPI.getPhoneCodeList();
    const pFxList = RegistrationFormAPI.getFaxCodeList();
    const pCountryList = PreRegisterAPI.getCountry();
    const pShpList = RegistrationFormAPI.getShippingCountryList();
    const pLcList = RegistrationFormAPI.getLocationTypeList();
    const pSpcList = RegistrationFormAPI.getSpecifyLanguages();
    const pCsList = RegistrationFormAPI.getCustomerType();
    const pSplList = RegistrationFormAPI.getSupplierType();
    const pBuList = RegistrationFormAPI.getBusinessType();
    const pEnList = RegistrationFormAPI.getLegalEntity();
    const pEstMonth = RegistrationFormAPI.getEstimatedMonthlyPurchaseUnit();
    const oilTraderList = PreRegisterAPI.getPicklist({
      objectApiName: "CTRRequestFormHeader__c",
      fieldApiName: "NoOfRegistrationMinistryEnergy__c",
    });

    Promise.all([
      pSlList,
      pMbList,
      pPhList,
      pFxList,
      pCountryList,
      pShpList,
      pLcList,
      pSpcList,
      pCsList,
      pSplList,
      pBuList,
      pEnList,
      pEstMonth,
      oilTraderList,
    ])
      .then(
        ([
          slList,
          mbList,
          phList,
          fxList,
          countryList,
          shpList,
          lcList,
          spcList,
          csList,
          splList,
          buList,
          enList,
          estMonth,
          oilTrader,
        ]) => {
          setListSalutation(slList);
          setListMobileCode(mbList);
          setListPhoneCode(phList);
          setListFaxCode(fxList);
          setListCountry(countryList);
          setListShippingCountry(shpList);
          setListLocationType(lcList);
          setListSpecifyLanguages(spcList);
          setListCustomerType(csList);
          setListSupplierType(splList);
          setListBusinessType(buList);
          setListEntityLegal(enList);
          setListEstimateMonthly(estMonth);
          setListOilTrader(oilTrader);
        }
      )
      .catch((errors) => {
        console.error(errors);
      })
      .finally(() => {});
  };

  const getProvinceForCountry = async (country, type) => {
    // if (type === 3) {
    //   const provinceList1 = await RegistrationFormAPI.getProvince(
    //     country.Country
    //   );
    //   const provinceList2 = await RegistrationFormAPI.getProvince(
    //     country.Country
    //   );
    //   setListProvince1(provinceList1);
    //   setListProvince2(provinceList2);
    // } else {
    //   const provinceList = await RegistrationFormAPI.getProvince(country);
    //   if (type === 1) {
    //     setListProvince1(provinceList);
    //   } else {
    //     setListProvince2(provinceList);
    //   }
    // }
    const provinceList = await RegistrationFormAPI.getProvince(country.Country);
    setListProvince1(provinceList);
    setListProvince2(provinceList);
  };

  const isValidateOld = () => {
    let obj = { ...objRegistrationForm };
    let rq = {
      fields: "",
      active: false,
    };
    if (obj.CompanyNameEN === "") {
      rq.fields += "*Company Name (English)";
      rq.active = true;
      return rq;
    }
    if (obj.Country === "") {
      rq.fields += "Country";
      rq.active = true;
      return rq;
    }
    if (obj.BusinessType === "") {
      rq.fields += "Type of Business";
      rq.active = true;
      return rq;
    } else if (
      obj.BusinessType?.indexOf("Other") > -1 &&
      obj.BusinessTypeOthers === ""
    ) {
      rq.fields = "Other Type of Business";
      rq.active = true;
      return rq;
    } else if (obj.LegalEntityType === "") {
      rq.fields = "Legal Entity";
      rq.active = true;
      return rq;
    } else if (
      obj.LegalEntityType?.indexOf("Other") > -1 &&
      obj.LegalEntityTypeOthers === ""
    ) {
      rq.fields = "Other Legal Entity";
      rq.active = true;
      return rq;
    } else if (obj.IncorporationDate === "") {
      rq.fields = "Incorporation Date";
      rq.active = true;
      return rq;
    } else if (obj.YearsOfExperience === "") {
      rq.fields = "Years of Experience in business?";
      rq.active = true;
      return rq;
    } else if (obj.TAXNumber === "") {
      rq.fields = "Tax Number";
      rq.active = true;
      return rq;
    } else if (obj.MinistryOfEnergyRegistrationNo === "") {
      rq.fields = "Do you have oil trader license from MOE?";
      rq.active = true;
      return rq;
    } else if (obj.CEOFirstName === "") {
      rq.fields = "CEO/President/MD First Name";
      rq.active = true;
      return rq;
    } else if (obj.CEOLastName === "") {
      rq.fields = "CEO/President/MD Last Name";
      rq.active = true;
      return rq;
    } else if (obj.Shareholders.length === 0) {
      rq.fields = "Shareholders";
      rq.active = true;
      rq.target = document.getElementById("Shareholders");
      return rq;
    } else if (obj.Shareholders.length > 0) {
      if (
        obj.Shareholders[0].Name === "" ||
        obj.Shareholders[0].PercentOfShareholder === ""
      ) {
        rq.fields =
          obj.Shareholders[0].Name === ""
            ? "Shareholders - Name"
            : "Shareholders - Percent of Shareholder";
        rq.active = true;
        return rq;
      }
      const percentLessThan10 = obj.Shareholders.filter((shareholder) => {
        return shareholder.PercentOfShareholder < 10;
      });
      if (percentLessThan10.length > 0) {
        rq.fields =
          "Percent of Shareholder less than 10 - Provide only shareholder for equal or more than 10 percents";
        rq.active = true;
        return rq;
      }
    }

    if (obj.HouseNo === "") {
      rq.fields = "House No";
      rq.active = true;
      return rq;
    } else if (obj.Street === "") {
      rq.fields = "Street";
      rq.active = true;
      return rq;
    } else if (obj.City === "") {
      rq.fields = "City";
      rq.active = true;
      return rq;
    } else if (obj.Disctrict === "") {
      rq.fields = "Disctrict";
      rq.active = true;
      return rq;
    }
    // else if (obj.PostalCode === "") {
    //   rq.fields = "Postal Code";
    //   rq.active = true;
    //   return rq;
    // }
    else if (!obj.MailingIsSameAsRegistered) {
      if (obj.MailingHouseNo === "") {
        rq.fields = "Mailing House No.";
        rq.active = true;
        return rq;
      } else if (obj.MailingStreet === "") {
        rq.fields = "Mailing Street";
        rq.active = true;
        return rq;
      } else if (obj.MailingCity === "") {
        rq.fields = "Mailing City";
        rq.active = true;
        return rq;
      } else if (obj.MailingDistrict === "") {
        rq.fields = "Mailing District";
        rq.active = true;
        return rq;
      }
      // else if (obj.MailingPostalCode === "") {
      //   rq.fields = "Mailing Postal Code";
      //   rq.active = true;
      //   return rq;
      // }
    } else if (obj.ContactFirstName === "") {
      rq.fields = "Contact Person First Name";
      rq.active = true;
      return rq;
    } else if (obj.ContactLastName === "") {
      rq.fields = "Contact Person Last Name";
      rq.active = true;
      return rq;
    } else if (obj.ContactEmail === "") {
      rq.fields = "Contact Person Email";
      rq.active = true;
      return rq;
    } else if (obj.ContactPhoneCountryCode === "") {
      rq.fields = "Contact Person Phone Number Country Code";
      rq.active = true;
      return rq;
    } else if (obj.ContactPhoneNumber === "") {
      rq.fields = "Contact Person Phone Number";
      rq.active = true;
      return rq;
    } else if (obj.ContactMobileCountryCode === "") {
      rq.fields = "Contact Person Mobile Number Country Code";
      rq.active = true;
      return rq;
    } else if (obj.ContactMobileNumber === "") {
      rq.fields = "Contact Person Mobile Number";
      rq.active = true;
      return rq;
    } else if (
      !(
        obj.InterestedCrudeAsCustomer ||
        obj.InterestedPetroleumAsCustomer ||
        obj.InterestedPetrochemicalAsCustomer ||
        obj.InterestedLubeBaseAsCustomer ||
        obj.InterestedLABIXAsCustomer ||
        obj.InterestedOtherAsCustomer ||
        obj.InterestedOtherAsCustomer ||
        obj.InterestedCrudeAsSupplier ||
        obj.InterestedB100EthanolAsSupplier ||
        obj.InterestedNormalParaffinAsSupplier ||
        obj.InterestedPetroleumAndComponentAsSupplier ||
        obj.InterestedOtherAsSupplier
      )
    ) {
      rq.fields = "Interested Product Type as Customer/Supplier";
      rq.active = true;
      return rq;
    } else if (obj.InterestedLubeBaseAsCustomer) {
      if (obj.InterestedLubeBaseAsCustomerDesc === "") {
        rq.fields = "Lube Base Products";
        rq.active = true;
        return rq;
      }
    } else if (obj.InterestedLABIXAsCustomer) {
      if (obj.InterestedLABIXAsCustomerDesc === "") {
        rq.fields = "LABIX Products";
        rq.active = true;
        return rq;
      }
    } else if (obj.InterestedOtherAsCustomer) {
      if (obj.InterestedOtherAsCustomerDesc === "") {
        rq.fields = "Other Products";
        rq.active = true;
        return rq;
      }
    } else if (obj.InterestedPetroleumAndComponentAsSupplier) {
      if (obj.InterestedPetroleumAndComponentAsSupplierDesc === "") {
        rq.fields = "Petroleum and Components";
        rq.active = true;
        return rq;
      }
    } else if (obj.InterestedOtherAsSupplier) {
      if (obj.InterestedOtherAsSupplierDesc === "") {
        rq.fields = "Other Products";
        rq.active = true;
        return rq;
      }
    } else if (obj.EnvironmentPolicy === "") {
      rq.fields = "Enviorment: Does your company have Environment policy?";
      rq.active = true;
      return rq;
    } else if (obj.SocialResponsibilityPolicy === "") {
      rq.fields =
        "Social Responsibility: Does your company have Social Responsibility policy?";
      rq.active = true;
      return rq;
    } else if (obj.CorporateGovernancePolicy === "") {
      rq.fields =
        "Governance: Does your company have Corporate Governance policy?";
      rq.active = true;
      return rq;
    } else if (obj.OccupationalHealthAndSafetyPolicy === "") {
      rq.fields =
        "Occupational Health and Safety: Does your company have Occupational Health and Safety policy?";
      rq.active = true;
      return rq;
    } else if (obj.HumanRightsPolicy === "") {
      rq.fields = "Human Rights: Does your company have Human Rights policy?";
      rq.active = true;
      return rq;
    } else if (obj.ViolatingAntiMoneyLaunderingLaws === "") {
      rq.fields =
        "Has your company or any of its affiliates, or any current director, officer, or key employee ever been charged with, or investigated for fraud, misrepresentation, corruption, bribery, money laundering, forced labour, environmental issues or other related activities during the past five years?";
      rq.active = true;
      return rq;
    } else if (obj.ViolatingAntiMoneyLaunderingLaws === "Yes") {
      if (obj.ReasonOfViolatingAntiMoneyLaunderingLaws === "") {
        rq.fields = "Reason of have targets in sanction";
        rq.active = true;
        return rq;
      }
    } else if (obj.HaveTagetsInSanction === "") {
      rq.fields =
        "Does your company have any offices, transactions, investments, activities or planned activities in sanctioned targets or any transactions, investments, activities or planned activities with (or in) any person or entity located in sanctioned targets or with (or in) any entity owned or controlled by any entity located in sanctioned targets?";
      rq.active = true;
      return rq;
    } else if (obj.HaveTagetsInSanction === "Yes") {
      if (obj.ReasonOfHaveTagetsInSanction === "") {
        rq.fields = "Reason of have targets in sanction";
        rq.active = true;
        return rq;
      }
    }

    if (emailValidation) {
      rq.fields = "Emal Address";
      rq.active = true;
    }

    if (customerActive) {
      if (obj.CustomerType === "") {
        rq.fields = "Customer Type";
        rq.active = true;
        rq.target = document.getElementById("CustomerType");
        return rq;
      }
      if (obj.DestinationCountries.length === 0) {
        rq.fields = "Destination Countries";
        rq.active = true;
        rq.target = document.getElementById("DestinationCountries");
        return rq;
      } else if (obj.DestinationCountries.length > 0) {
        if (
          obj.DestinationCountries[0].Country === ""
          // || obj.DestinationCountries[0].LocationType === ""
        ) {
          rq.fields = "Destination Countries - Country";
          // obj.DestinationCountries[0].Country === ""
          //   ? "Destination Countries - Country"
          //   : "Destination Countries - LocationType";
          rq.active = true;
          return rq;
        }
      }
      // if (obj.PurchaseProductFromCompany === "") {
      //   rq.fields = "Where do customers buy this product from?";
      //   rq.active = true;
      //   rq.target = document.getElementById("PurchaseProductFromCompany");
      //   return rq;
      // }
      if (obj.CustomersFacilityTankAndStorage === "") {
        rq.fields = "Customer’s Facility (Tank and Storage)";
        rq.active = true;
        rq.target = document.getElementById("CustomersFacilityTankAndStorage");
        return rq;
      } else if (obj.CustomersFacilityTransportation === "") {
        rq.fields = "Customer’s Facility (Transportation)";
        rq.active = true;
        rq.target = document.getElementById("CustomersFacilityTransportation");
        return rq;
      } else if (obj.PaymentOverTheAmountOfUSD === "") {
        rq.fields =
          "(For Customer) Has your company ever had any default payment over the amount of USD 50,000 in the last 3 years?";
        rq.active = true;
        rq.target = document.getElementById("PaymentOverTheAmountOfUSD");
        return rq;
      } else if (obj.PaymentOverTheAmountOfUSD === "Yes") {
        if (obj.ReasonOfPaymentOverTheAmountOfUSD === "") {
          rq.fields =
            "Reason of had any default payment over the amount of USD 50,000 in the last 3 years?";
          rq.active = true;
          rq.target = document.getElementById(
            "ReasonOfPaymentOverTheAmountOfUSD"
          );
          return rq;
        }
      }
    }

    if (supplierActive) {
      if (obj.SupplierType === "") {
        rq.fields = "Supplier Type";
        rq.active = true;
        rq.target = document.getElementById("SupplierType");
        return rq;
      }
      // else if (obj.SupplyProductToCompany === "") {
      //   rq.fields = "Supplying this product to which company?";
      //   rq.active = true;
      //   rq.target = document.getElementById("SupplyProductToCompany");
      //   return rq;
      // }
      else if (obj.SuppliersFacilityTankAndStorage === "") {
        rq.fields = "Supplier's Facility (Tank and Storage)";
        rq.active = true;
        rq.target = document.getElementById("SuppliersFacilityTankAndStorage");
        return rq;
      } else if (obj.SuppliersFacilityTransportation === "") {
        rq.fields = "Supplier Facility (Transportation)";
        rq.active = true;
        rq.target = document.getElementById("SuppliersFacilityTransportation");
        return rq;
      } else if (obj.FailedSupplyAgreement === "") {
        rq.fields =
          "(For Supplier) Has your company ever failed to supply under any agreement in the last 3 years?";
        rq.active = true;
        rq.target = document.getElementById("FailedSupplyAgreement");
        return rq;
      } else if (obj.FailedSupplyAgreement === "Yes") {
        if (obj.ReasonOfFailedSupplyAgreement === "") {
          rq.fields =
            "Reason of had any failed to supply under any agreement in the last 3 years?";
          rq.active = true;
          rq.target = document.getElementById("ReasonOfFailedSupplyAgreement");
          return rq;
        }
      }
    }

    if (customerActive) {
      if (obj.ContactEmail === "") {
        rq.fields = "Contact Person Email";
        rq.active = true;
        rq.target = document.getElementById("ContactEmail");
        return rq;
      }
    }

    return false;
  };

  const isValidate = () => {
    let obj = { ...objRegistrationForm };
    let invalidFields = [];

    const checkField = (condition, fieldName) => {
      if (condition) {
        invalidFields.push(fieldName);
      }
    };

    checkField(!obj.CompanyNameEN, "*Company Name (English)");
    checkField(!obj.Country, "Country");
    checkField(!obj.BusinessType, "Type of Business");
    checkField(!obj.CompanyNameAlias, "*Company Name (Alias)");
    checkField(
      obj.BusinessType?.indexOf("Other") > -1 && !obj.BusinessTypeOthers,
      "Other Type of Business"
    );
    checkField(!obj.LegalEntityType, "Legal Entity");
    checkField(
      obj.LegalEntityType?.indexOf("Other") > -1 && !obj.LegalEntityTypeOthers,
      "Other Legal Entity"
    );
    checkField(!obj.IncorporationDate, "Incorporation Date");
    checkField(!obj.YearsOfExperience, "Years of Experience in business?");
    checkField(!obj.TAXNumber, "Tax Number");
    checkField(
      !obj.MinistryOfEnergyRegistrationNo,
      "Do you have oil trader license from MOE?"
    );
    checkField(!obj.CEOFirstName, "CEO/President/MD First Name");
    checkField(!obj.CEOLastName, "CEO/President/MD Last Name");
    checkField(!obj.HouseNo, "House No");
    checkField(!obj.Street, "Street");
    checkField(!obj.City, "City");
    checkField(!obj.District, "District");
    checkField(
      !obj.MailingIsSameAsRegistered && !obj.MailingHouseNo,
      "Mailing House No."
    );
    checkField(
      !obj.MailingIsSameAsRegistered && !obj.MailingStreet,
      "Mailing Street"
    );
    checkField(
      !obj.MailingIsSameAsRegistered && !obj.MailingCity,
      "Mailing City"
    );
    checkField(
      !obj.MailingIsSameAsRegistered && !obj.MailingDistrict,
      "Mailing District"
    );
    checkField(!obj.ContactFirstName, "Contact Person First Name");
    checkField(!obj.ContactLastName, "Contact Person Last Name");
    checkField(!obj.ContactEmail, "Contact Person Email");
    checkField(
      !obj.ContactPhoneCountryCode,
      "Contact Person Phone Number Country Code"
    );
    checkField(!obj.ContactPhoneNumber, "Contact Person Phone Number");
    checkField(
      !obj.ContactMobileCountryCode,
      "Contact Person Mobile Number Country Code"
    );
    checkField(!obj.ContactMobileNumber, "Contact Person Mobile Number");
    checkField(
      !obj.EnvironmentPolicy,
      "Environment: Does your company have Environment policy?"
    );
    checkField(
      !obj.SocialResponsibilityPolicy,
      "Social Responsibility: Does your company have Social Responsibility policy?"
    );
    checkField(
      !obj.CorporateGovernancePolicy,
      "Governance: Does your company have Corporate Governance policy?"
    );
    checkField(
      !obj.OccupationalHealthAndSafetyPolicy,
      "Occupational Health and Safety: Does your company have Occupational Health and Safety policy?"
    );
    checkField(
      !obj.HumanRightsPolicy,
      "Human Rights: Does your company have Human Rights policy?"
    );
    checkField(
      !obj.ViolatingAntiMoneyLaunderingLaws,
      "Has your company or any of its affiliates, or any current director, officer, or key employee ever been charged with, or investigated for fraud, misrepresentation, corruption, bribery, money laundering, forced labour, environmental issues or other related activities during the past five years?"
    );
    checkField(
      obj.ViolatingAntiMoneyLaunderingLaws === "Yes" &&
        !obj.ReasonOfViolatingAntiMoneyLaunderingLaws,
      "Reason of have targets in sanction"
    );
    checkField(
      !obj.HaveTagetsInSanction,
      "Does your company have any offices, transactions, investments, activities or planned activities in sanctioned targets or any transactions, investments, activities or planned activities with (or in) any person or entity located in sanctioned targets or with (or in) any entity owned or controlled by any entity located in sanctioned targets?"
    );
    checkField(
      obj.HaveTagetsInSanction === "Yes" && !obj.ReasonOfHaveTagetsInSanction,
      "Reason of have targets in sanction"
    );

    if (obj.Shareholders.length === 0) {
      invalidFields.push("Shareholders");
    } else {
      checkField(obj.Shareholders[0].Name === "", "Shareholders - Name");
      checkField(
        obj.Shareholders[0].PercentOfShareholder === "",
        "Shareholders - Percent of Shareholder"
      );

      const percentLessThan10 = obj.Shareholders.filter((shareholder) => {
        return shareholder.PercentOfShareholder < 10;
      });
      checkField(
        percentLessThan10.length > 0,
        "Percent of Shareholder less than 10 - Provide only shareholder for equal or more than 10 percents"
      );
    }

    const interestedFields = [
      {
        field: obj.InterestedLubeBaseAsCustomer,
        desc: obj.InterestedLubeBaseAsCustomerDesc,
        name: "Lube Base Products",
      },
      {
        field: obj.InterestedLABIXAsCustomer,
        desc: obj.InterestedLABIXAsCustomerDesc,
        name: "LABIX Products",
      },
      {
        field: obj.InterestedOtherAsCustomer,
        desc: obj.InterestedOtherAsCustomerDesc,
        name: "Other Products",
      },
      {
        field: obj.InterestedPetroleumAndComponentAsSupplier,
        desc: obj.InterestedPetroleumAndComponentAsSupplierDesc,
        name: "Petroleum and Components",
      },
      {
        field: obj.InterestedOtherAsSupplier,
        desc: obj.InterestedOtherAsSupplierDesc,
        name: "Other Products",
      },
    ];

    for (let { field, desc, name } of interestedFields) {
      checkField(field && !desc, name);
    }

    const interestedProductTypes = [
      obj.InterestedCrudeAsCustomer,
      obj.InterestedPetroleumAsCustomer,
      obj.InterestedPetrochemicalAsCustomer,
      obj.InterestedLubeBaseAsCustomer,
      obj.InterestedLABIXAsCustomer,
      obj.InterestedOtherAsCustomer,
      obj.InterestedCrudeAsSupplier,
      obj.InterestedB100EthanolAsSupplier,
      obj.InterestedNormalParaffinAsSupplier,
      obj.InterestedPetroleumAndComponentAsSupplier,
      obj.InterestedOtherAsSupplier,
    ];

    checkField(
      !interestedProductTypes.some((type) => type),
      "Interested Product Type as Customer/Supplier"
    );

    checkField(emailValidation, "Email Address");

    const customerFields = [
      {
        active: customerActive,
        field: obj.CustomerType,
        name: "Customer Type",
      },
      {
        active: customerActive,
        field: obj.DestinationCountries.length > 0,
        name: "Destination Countries",
      },
      {
        active:
          customerActive &&
          obj.DestinationCountries.length > 0 &&
          obj.DestinationCountries[0].Country === "",
        name: "Destination Countries - Country",
      },
      {
        active: customerActive,
        field: obj.CustomersFacilityTankAndStorage,
        name: "Customer’s Facility (Tank and Storage)",
      },
      {
        active: customerActive,
        field: obj.CustomersFacilityTransportation,
        name: "Customer’s Facility (Transportation)",
      },
      {
        active: customerActive,
        field: obj.PaymentOverTheAmountOfUSD,
        name: "(For Customer) Has your company ever had any default payment over the amount of USD 50,000 in the last 3 years?",
      },
      {
        active:
          customerActive &&
          obj.PaymentOverTheAmountOfUSD === "Yes" &&
          !obj.ReasonOfPaymentOverTheAmountOfUSD,
        name: "Reason of had any default payment over the amount of USD 50,000 in the last 3 years?",
      },
    ];

    for (let { active, field, name } of customerFields) {
      checkField(active && !field, name);
    }

    const supplierFields = [
      {
        active: supplierActive,
        field: obj.SupplierType,
        name: "Supplier Type",
      },
      {
        active: supplierActive,
        field: obj.SuppliersFacilityTankAndStorage,
        name: "Supplier's Facility (Tank and Storage)",
      },
      {
        active: supplierActive,
        field: obj.SuppliersFacilityTransportation,
        name: "Supplier Facility (Transportation)",
      },
      {
        active: supplierActive,
        field: obj.FailedSupplyAgreement,
        name: "(For Supplier) Has your company ever failed to supply under any agreement in the last 3 years?",
      },
      {
        active:
          supplierActive &&
          obj.FailedSupplyAgreement === "Yes" &&
          !obj.ReasonOfFailedSupplyAgreement,
        name: "Reason of had any failed to supply under any agreement in the last 3 years?",
      },
    ];

    for (let { active, field, name } of supplierFields) {
      checkField(active && !field, name);
    }

    checkField(customerActive && !obj.ContactEmail, "Contact Person Email");

    const fieldsString = invalidFields.join(", ");
    return {
      fields: fieldsString,
      active: invalidFields.length > 0,
    };
  };

  const isChecksCustomer = () => {
    if (
      objRegistrationForm.InterestedCrudeAsCustomer ||
      objRegistrationForm.InterestedPetrochemicalAsCustomer ||
      objRegistrationForm.InterestedPetroleumAsCustomer ||
      objRegistrationForm.InterestedLubeBaseAsCustomer ||
      objRegistrationForm.InterestedLABIXAsCustomer ||
      objRegistrationForm.InterestedOtherAsCustomer
    ) {
      setCustomerActive(true);
    } else {
      setCustomerActive(false);

      objRegistrationForm.CustomersFacilityTankAndStorage = "";
      objRegistrationForm.CustomersFacilityTransportation = "";
      objRegistrationForm.PaymentOverTheAmountOfUSD = "";
      objRegistrationForm.ReasonOfPaymentOverTheAmountOfUSD = "";

      setObjRegistrationForm(objRegistrationForm);
    }
  };

  const isChecksSupplier = () => {
    let obj = { ...objRegistrationForm };
    if (
      obj.InterestedCrudeAsSupplier ||
      obj.InterestedB100EthanolAsSupplier ||
      obj.InterestedPetroleumAndComponentAsSupplier ||
      obj.InterestedNormalParaffinAsSupplier ||
      obj.InterestedOtherAsSupplier
    ) {
      setSupplierActive(true);
    } else {
      setSupplierActive(false);

      objRegistrationForm.SuppliersFacilityTankAndStorage = "";
      objRegistrationForm.SuppliersFacilityTransportation = "";
      objRegistrationForm.FailedSupplyAgreement = "";
      objRegistrationForm.ReasonOfPaymentOverTheAmountOfUSD = "";

      setObjRegistrationForm(objRegistrationForm);
    }
  };

  return (
    <Card>
      {/* Company Information */}
      <CardContent>
        <Typography sx={{ m: 2 }} variant="subtitle1">
          <abbr className="slds-required">*</abbr>
          is required field
        </Typography>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Company Information
          </Typography>
        </Box>
        {/* row1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Company Name (English)
            </Typography>
            <TextInput
              id="CompanyNameEN"
              className="field-required"
              placeholder="Company Name (English)"
              value={objRegistrationForm.CompanyNameEN}
              inputProps={{ maxLength: 70 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameEN = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Company Name (Other Languages)
            </Typography>
            <TextInput
              placeholder="Company Name (Local)"
              value={objRegistrationForm.CompanyNameLocal}
              inputProps={{ maxLength: 70 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameLocal = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Specify Languages of Company Name
            </Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.CompanyNameLanguage !== ""
                    ? objRegistrationForm.CompanyNameLanguage
                    : "CompanyNameLanguage"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.CompanyNameLanguage = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.CompanyNameLanguage !== ""
                      ? objRegistrationForm.CompanyNameLanguage
                      : "CompanyNameLanguage"
                  }
                  disabled
                >
                  Select Company Name Language
                </MenuItem>
                {listSpecifyLanguages &&
                  listSpecifyLanguages.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* row3 Alias */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Company Name (Alias)
            </Typography>
            <TextInput
              id="CompanyNameAlias"
              className="field-required"
              placeholder="Company Name (Alias)"
              value={objRegistrationForm.CompanyNameAlias}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameAlias = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row4 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Former name of company (if any)
            </Typography>
            <TextInput
              placeholder="Former name of company"
              value={objRegistrationForm.CompanyNameFormer}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CompanyNameFormer = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Country
            </Typography>

            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className="field-required"
            >
              <Select
                id="Country"
                value={
                  objRegistrationForm.Country !== ""
                    ? objRegistrationForm.Country
                    : "Country"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.Country = e.target.value;
                  setObjRegistrationForm(obj);
                  getProvinceForCountry(e.target.value, 1);
                }}
                disabled
              >
                <MenuItem
                  value={
                    objRegistrationForm.Country !== ""
                      ? objRegistrationForm.Country
                      : "Country"
                  }
                  disabled
                >
                  Select Country
                </MenuItem>
                {listCountry &&
                  listCountry.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* row5 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Type of Business
            </Typography>
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className="field-required"
            >
              <Select
                id="BusinessType"
                value={
                  objRegistrationForm.BusinessType !== ""
                    ? objRegistrationForm.BusinessType
                    : "BusinessType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.BusinessType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.BusinessType !== ""
                      ? objRegistrationForm.BusinessType
                      : "BusinessType"
                  }
                  disabled
                >
                  Select Business Type
                </MenuItem>
                {listBusinessType &&
                  listBusinessType.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Business Type Others"
              className={
                objRegistrationForm.BusinessType?.indexOf("Other") > -1
                  ? "field-required"
                  : ""
              }
              value={objRegistrationForm.BusinessTypeOthers}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.BusinessTypeOthers = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row6 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Legal Entity
            </Typography>
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className="field-required"
            >
              <Select
                id="LegalEntityType"
                value={
                  objRegistrationForm.LegalEntityType !== ""
                    ? objRegistrationForm.LegalEntityType
                    : "LegalEntityType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.LegalEntityType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.LegalEntityType !== ""
                      ? objRegistrationForm.LegalEntityType
                      : "LegalEntityType"
                  }
                  disabled
                >
                  Select Legal Entity Type
                </MenuItem>
                {listEntityLegal &&
                  listEntityLegal.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              className={
                objRegistrationForm.LegalEntityType?.indexOf("Other") > -1
                  ? "field-required"
                  : ""
              }
              placeholder="Legal Entity Type Others"
              value={objRegistrationForm.LegalEntityTypeOthers}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.LegalEntityTypeOthers = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row7 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Incorporation Date
            </Typography>

            <div className="field-required">
              <DateTimePicker
                id="IncorporationDate"
                defaultValue={dayjs(objRegistrationForm.IncorporationDate)}
                value={dayjs(objRegistrationForm.IncorporationDate)}
                maxDate={dayjs()}
                format="DD/MM/YYYY"
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.IncorporationDate = dayjs(e).format("YYYY-MM-DD");
                  setObjRegistrationForm(obj);
                }}
              />
            </div>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Years of Experience in business?
            </Typography>
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className="field-required"
            >
              <Select
                id="YearsOfExperience"
                value={
                  objRegistrationForm.YearsOfExperience !== ""
                    ? objRegistrationForm.YearsOfExperience
                    : "YearsOfExperience"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.YearsOfExperience = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                sx={
                  objRegistrationForm.YearsOfExperience
                    ? { color: "black" }
                    : { color: "#c3c3c3" }
                }
              >
                <MenuItem
                  value={
                    objRegistrationForm.YearsOfExperience !== ""
                      ? objRegistrationForm.YearsOfExperience
                      : "YearsOfExperience"
                  }
                  disabled
                >
                  Select Years of Experience in business
                </MenuItem>
                {listYearsOfExperience &&
                  listYearsOfExperience.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* row8 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              TAX Number{" "}
              <Tooltip
                title={
                  <div>
                    <p>
                      N = number <br /> A = letter <br /> X = A, B, C, F, G, H,
                      L, J, P, T or <br /> Y = letter or number <br /> Z = Z
                    </p>
                    <p className="slds-var-p-top_small">
                      Thailand: NNNNNNNNNNNNN <br /> India: NNAXXXANNNNAYZY{" "}
                      <br /> Vietnam: NNNNNNNNNN <br /> Indonesia:
                      YYYYYYYYYYYYYYYYYYYY <br /> Singapore: NNNNNNNNNA
                    </p>
                  </div>
                }
                placement="top"
              >
                <ErrorIcon className="help-text" sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <TextInput
              id="TAXNumber"
              className="field-required"
              placeholder="Tax Number"
              value={objRegistrationForm.TAXNumber}
              inputProps={{ maxLength: 40 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.TAXNumber = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Company Website</Typography>
            <TextInput
              placeholder="Company Website"
              value={objRegistrationForm.Website}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Website = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row9 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Do you have oil trader license from MOE?{" "}
              <Tooltip
                title="ใบทะเบียนเป็นผู้ค้าน้ำมันตามมาตราต่างๆ ที่ออกโดยกรมธุรกิจพลังงาน (N.P. 104 or N.P.105 or N.P.106)"
                placement="top"
              >
                <ErrorIcon className="help-text" sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <FormControl
              sx={{ minWidth: 180 }}
              size="small"
              className="field-required"
            >
              <Select
                id="MinistryOfEnergyRegistrationNo"
                value={
                  objRegistrationForm.MinistryOfEnergyRegistrationNo !== ""
                    ? objRegistrationForm.MinistryOfEnergyRegistrationNo
                    : "Do you have oil trader license from MOE?"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.MinistryOfEnergyRegistrationNo = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                sx={
                  objRegistrationForm.MinistryOfEnergyRegistrationNo
                    ? { color: "black" }
                    : { color: "#c3c3c3" }
                }
              >
                <MenuItem
                  value={
                    objRegistrationForm.MinistryOfEnergyRegistrationNo !== ""
                      ? objRegistrationForm.MinistryOfEnergyRegistrationNo
                      : "Do you have oil trader license from MOE?"
                  }
                  disabled
                >
                  Do you have oil trader license from MOE?
                </MenuItem>
                {listOilTrader &&
                  listOilTrader.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {/* <TextInput
              placeholder="Number of resgistration issues"
              value={objRegistrationForm.MinistryOfEnergyRegistrationNo}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MinistryOfEnergyRegistrationNo = e.target.value;
                setObjRegistrationForm(obj);
              }}
            /> */}
          </Box>
        </Box>

        {/* Name of CEO/President/Managing Director */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              <abbr className="slds-required">*</abbr>
              CEO/President/MD First Name
            </Typography>
            <TextInput
              id="CEOFirstName"
              className="field-required"
              placeholder="CEO/President/MD First Name"
              value={objRegistrationForm.CEOFirstName}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CEOFirstName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              <abbr className="slds-required">*</abbr>
              CEO/President/MD Last Name
            </Typography>
            <TextInput
              id="CEOLastName"
              className="field-required"
              placeholder="CEO/President/MD Last Name"
              value={objRegistrationForm.CEOLastName}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CEOLastName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>

        {/* List of Shareholders who hold an interest in the company more than 10% */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              <abbr className="slds-required">*</abbr>
              List of Shareholders who hold an interest in the company more than
              10%
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "100%" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>No.</TableCell> */}
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">% of Shareholder</TableCell>
                      <TableCell align="center">Delete</TableCell>
                      {/* <TableCell align="center">Add</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objRegistrationForm?.Shareholders?.length > 0 &&
                      objRegistrationForm.Shareholders.map((item, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell
                              align="left"
                              component="th"
                              scope="row">
                              {index + 1}
                            </TableCell> */}
                            <TableCell align="left" component="th" scope="row">
                              <TextField
                                sx={{ width: "100%" }}
                                data-input-text="text-input-form"
                                placeholder="Shareholder Name"
                                value={item.Name || ""}
                                inputProps={{ maxLength: 80 }}
                                onChange={(e) => {
                                  const updatedShareholders = [
                                    ...objRegistrationForm.Shareholders,
                                  ];
                                  updatedShareholders[index].Name =
                                    e.target.value;
                                  setObjRegistrationForm({
                                    ...objRegistrationForm,
                                    Shareholders: updatedShareholders,
                                  });
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <TextField
                                type="number"
                                sx={{ width: "50%", textAlign: "center" }}
                                data-input-text="text-input-form"
                                placeholder="% of Shareholders"
                                value={item.PercentOfShareholder || ""}
                                onChange={(e) => {
                                  const updatedShareholders = [
                                    ...objRegistrationForm.Shareholders,
                                  ];
                                  updatedShareholders[
                                    index
                                  ].PercentOfShareholder = e.target.value;
                                  setObjRegistrationForm({
                                    ...objRegistrationForm,
                                    Shareholders: updatedShareholders,
                                  });
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <ClearIcon
                                sx={{ color: red[500] }}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const updatedShareholders =
                                    objRegistrationForm.Shareholders.filter(
                                      (_, idx) => idx !== index
                                    );
                                  if (!updatedShareholders.length) {
                                    updatedShareholders.push({
                                      Id: "",
                                      Name: "",
                                      PercentOfShareholder: "",
                                    });
                                  }
                                  setObjRegistrationForm({
                                    ...objRegistrationForm,
                                    Shareholders: updatedShareholders,
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* Default row when there are no shareholders */}
                    {objRegistrationForm.Shareholders.length === 0 && (
                      <TableRow>
                        {/* <TableCell align="left" component="th" scope="row">1</TableCell> */}
                        <TableCell align="left" component="th" scope="row">
                          <TextField
                            sx={{ width: "100%" }}
                            data-input-text="text-input-form"
                            placeholder="Shareholder Name"
                            inputProps={{ maxLength: 80 }}
                            value={
                              objRegistrationForm.Shareholders[0]?.Name || ""
                            }
                            onFocus={(e) => {
                              const newShareholders = [
                                {
                                  ...objRegistrationForm.Shareholders[0],
                                  Name: e.target.value,
                                },
                              ];
                              setObjRegistrationForm({
                                ...objRegistrationForm,
                                Shareholders: newShareholders,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          <TextField
                            sx={{ width: "50%", textAlign: "center" }}
                            data-input-text="text-input-form"
                            placeholder="% of Shareholders"
                            value={
                              objRegistrationForm.Shareholders[0]
                                ?.PercentOfShareholder || ""
                            }
                            onFocus={(e) => {
                              const newShareholders = [
                                {
                                  ...objRegistrationForm.Shareholders[0],
                                  PercentOfShareholder: e.target.value,
                                },
                              ];
                              setObjRegistrationForm({
                                ...objRegistrationForm,
                                Shareholders: newShareholders,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <ClearIcon
                            sx={{ color: red[500] }}
                            style={{ cursor: "pointer" }}
                            // onClick={() => {
                            //   const updatedShareholders = objAdditionalForm.Shareholders.filter((_, idx) => idx !== index);
                            //   setObjAdditionalForm({ ...objAdditionalForm, Shareholders: updatedShareholders });
                            // }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* add row */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
                id="Shareholders"
              >
                {objRegistrationForm.Shareholders &&
                  objRegistrationForm.Shareholders?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {objRegistrationForm.Shareholders.length - 1 ===
                        index ? (
                          <>
                            {objRegistrationForm.Shareholders.length === 1 ? (
                              <>
                                <Box
                                  sx={{
                                    ml: 1,
                                    height: 35,
                                    visibility: "hidden",
                                  }}
                                />
                                <AddCircleOutlineIcon
                                  color="success"
                                  sx={{
                                    cursor: "pointer",
                                    ml: 1,
                                    height: 55,
                                  }}
                                  onClick={() => {
                                    let obj = Object.assign(
                                      {},
                                      objRegistrationForm
                                    );
                                    if (
                                      obj.Shareholders[
                                        obj.Shareholders.length - 1
                                      ].Name !== "" &&
                                      obj.Shareholders[
                                        obj.Shareholders.length - 1
                                      ].PercentOfShareholder !== ""
                                    ) {
                                      obj.Shareholders.push({
                                        Id: "",
                                        Name: "",
                                        PercentOfShareholder: "",
                                      });
                                      setObjRegistrationForm(obj);
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <AddCircleOutlineIcon
                                key={index}
                                color="success"
                                sx={{
                                  cursor: "pointer",
                                  ml: 1,
                                  height: 55,
                                }}
                                onClick={() => {
                                  let obj = Object.assign(
                                    {},
                                    objRegistrationForm
                                  );
                                  if (
                                    obj.Shareholders[
                                      obj.Shareholders.length - 1
                                    ].Name !== "" &&
                                    obj.Shareholders[
                                      obj.Shareholders.length - 1
                                    ].PercentOfShareholder !== ""
                                  ) {
                                    obj.Shareholders.push({
                                      Id: "",
                                      Name: "",
                                      PercentOfShareholder: "",
                                    });
                                    setObjRegistrationForm(obj);
                                  }
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {index === 0 ? (
                              <Box
                                sx={{
                                  ml: 1,
                                  height: 85,
                                  visibility: "hidden",
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  ml: 1,
                                  height: 55,
                                  visibility: "hidden",
                                }}
                              />
                            )}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Parent Company: (If any) (Please give full legal/official company name) */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              Parent Company: (If any) (Please give full legal/official company
              name)
            </Typography>
            <TextInput
              placeholder="Immediate Parent Company"
              value={objRegistrationForm.ImmidiateParentCompany}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ImmidiateParentCompany = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              &nbsp;
            </Typography>
            <TextInput
              placeholder="Ultimate Parent Company"
              value={objRegistrationForm.UltimateParentCompany}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.UltimateParentCompany = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>

        <Box sx={{ p: 1, m: 1, width: "100%" }}>
          <Typography variant="subtitle1">
            Company's latest credit rating by international agencies: (Please
            specify if any e.g. BBB, Ba2)
          </Typography>
          <TextInput
            sx={{ width: "100%" }}
            placeholder="Please specify if any e.g. BBB, Baa2"
            value={objRegistrationForm.CompanyLatestCreditRating}
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.CompanyLatestCreditRating = e.target.value;
              setObjRegistrationForm(obj);
            }}
          />
        </Box>
      </CardContent>

      {/* Registration Address */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Registration Address
          </Typography>
        </Box>
        {/* row1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Registered Address
            </Typography>
            <TextInput
              id="HouseNo"
              className="field-required"
              placeholder="House No."
              value={objRegistrationForm.HouseNo}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.HouseNo = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Room"
              value={objRegistrationForm.Room}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Room = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              className="field-required"
              placeholder="Street"
              value={objRegistrationForm.Street}
              inputProps={{ maxLength: 60 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Street = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="SubDistrict"
              value={objRegistrationForm.SubDistrict}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.SubDistrict = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />

            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className="field-required"
            >
              <Select
                id="City"
                className="slds-required"
                value={
                  objRegistrationForm.City !== ""
                    ? objRegistrationForm.City
                    : "City"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.City = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.City !== ""
                      ? objRegistrationForm.City
                      : "City"
                  }
                  disabled
                >
                  Select City
                </MenuItem>
                {listProvince1 &&
                  listProvince1.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Building"
              value={objRegistrationForm.Building}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Building = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Floor"
              value={objRegistrationForm.Floor}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Floor = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <Box variant="subtitle1" sx={{ pt: 2 }}>
              &nbsp;
            </Box>
            <TextInput
              id="Disctrict"
              className="field-required"
              placeholder="District"
              value={objRegistrationForm.District}
              inputProps={{ maxLength: 40 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.District = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              id="PostalCode"
              placeholder="Postal Code"
              value={objRegistrationForm.PostalCode}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.PostalCode = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row2 location */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">Location Google Maps</Typography>
            <TextInput
              id="LocationURL"
              placeholder="Google Maps Link"
              value={objRegistrationForm.LocationURL}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.LocationURL = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Latitude"
              value={objRegistrationForm.Latitude}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Latitude = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1" sx={{ height: 78 }}>
              &nbsp;
            </Typography>
            <TextInput
              placeholder="Longitude"
              value={objRegistrationForm.Longitude}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.Longitude = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
      </CardContent>

      {/* Mailing Address */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Mailing Address
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Mailing Address
            </Typography>
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Same as Registered Address"
              sx={{ width: "100%" }}
              checked={objRegistrationForm.MailingIsSameAsRegistered}
              value={objRegistrationForm.MailingIsSameAsRegistered}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingIsSameAsRegistered = e.target.checked;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              id="MailingHouseNo"
              className="field-required"
              placeholder="House No."
              inputProps={{ maxLength: 10 }}
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.HouseNo
                  : objRegistrationForm.MailingHouseNo
              }
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingHouseNo = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <TextInput
              placeholder="Room"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Room
                  : objRegistrationForm.MailingRoom
              }
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingRoom = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <TextInput
              className="field-required"
              placeholder="Street"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Street
                  : objRegistrationForm.MailingStreet
              }
              inputProps={{ maxLength: 60 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingStreet = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <TextInput
              placeholder="SubDistrict"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.SubDistrict
                  : objRegistrationForm.MailingSubDistrict
              }
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingSubDistrict = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <FormControl
              className="field-required"
              sx={{ minWidth: 120 }}
              size="small"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.City
                  : objRegistrationForm.MailingCity
              }
            >
              <Select
                id="MailingCity"
                value={
                  objRegistrationForm.MailingIsSameAsRegistered
                    ? objRegistrationForm.City
                    : objRegistrationForm.MailingCity
                    ? objRegistrationForm.MailingCity
                    : "City"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.MailingCity = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                disabled={objRegistrationForm.MailingIsSameAsRegistered}
              >
                <MenuItem
                  value={
                    objRegistrationForm.MailingIsSameAsRegistered
                      ? objRegistrationForm.City
                      : objRegistrationForm.MailingCity
                      ? objRegistrationForm.MailingCity
                      : "City"
                  }
                >
                  Select City
                </MenuItem>
                {listProvince2 &&
                  listProvince2.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ p: 1, m: 1, width: "100%", pt: 6 }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Building"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Building
                  : objRegistrationForm.MailingBuilding
              }
              inputProps={{ maxLength: 20 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingBuilding = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <TextInput
              placeholder="Floor"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.Floor
                  : objRegistrationForm.MailingFloor
              }
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingFloor = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <Box variant="subtitle1" sx={{ pt: 2 }}>
              &nbsp;
            </Box>
            <TextInput
              id="MailingDistrict"
              className="field-required"
              placeholder="District"
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.District
                  : objRegistrationForm.MailingDistrict
              }
              inputProps={{ maxLength: 40 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingDistrict = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
            <TextInput
              id="MailingPostalCode"
              placeholder="Postal Code"
              inputProps={{ maxLength: 10 }}
              value={
                objRegistrationForm.MailingIsSameAsRegistered
                  ? objRegistrationForm.PostalCode
                  : objRegistrationForm.MailingPostalCode
              }
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.MailingPostalCode = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={objRegistrationForm.MailingIsSameAsRegistered}
            />
          </Box>
        </Box>
      </CardContent>

      {/* Contract Person */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Contact Person Information
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Contact Person
            </Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={
                  objRegistrationForm.ContactSalutation !== ""
                    ? objRegistrationForm.ContactSalutation
                    : "Title"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactSalutation = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                sx={
                  objRegistrationForm.ContactSalutation
                    ? { color: "black" }
                    : { color: "#c3c3c3" }
                }
              >
                <MenuItem
                  value={
                    objRegistrationForm.ContactSalutation !== ""
                      ? objRegistrationForm.ContactSalutation
                      : "Title"
                  }
                  disabled
                >
                  Select Title
                </MenuItem>
                {listSalutation &&
                  listSalutation.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <TextInput
              id="ContactFirstName"
              className="field-required"
              placeholder="First Name"
              value={objRegistrationForm.ContactFirstName}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactFirstName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              placeholder="Position"
              value={objRegistrationForm.ContactPosition}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactPosition = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <FormControl
                sx={{ minWidth: 180 }}
                size="small"
                className="field-required"
              >
                <Select
                  id="ContactMobileCountryCode"
                  value={
                    objRegistrationForm.ContactMobileCountryCode !== ""
                      ? objRegistrationForm.ContactMobileCountryCode
                      : "Country"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.ContactMobileCountryCode = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  sx={
                    objRegistrationForm.ContactMobileCountryCode
                      ? { color: "black" }
                      : { color: "#c3c3c3" }
                  }
                >
                  <MenuItem
                    value={
                      objRegistrationForm.ContactMobileCountryCode !== ""
                        ? objRegistrationForm.ContactMobileCountryCode
                        : "Country"
                    }
                    disabled
                  >
                    Country Code
                  </MenuItem>
                  {listMobileCode &&
                    listMobileCode.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <TextInput
                id="ContactMobileNumber"
                className="field-required"
                placeholder="Mobile Number"
                style={{ width: "100%", paddingLeft: 20 }}
                value={objRegistrationForm.ContactMobileNumber}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactMobileNumber = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <FormControl
                sx={{ minWidth: 180 }}
                size="small"
                value={objRegistrationForm.ContactFaxCountryCode}
              >
                <Select
                  value={
                    objRegistrationForm.ContactFaxCountryCode !== ""
                      ? objRegistrationForm.ContactFaxCountryCode
                      : "Country"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.ContactFaxCountryCode = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  sx={
                    objRegistrationForm.ContactFaxCountryCode
                      ? { color: "black" }
                      : { color: "#c3c3c3" }
                  }
                >
                  <MenuItem
                    value={
                      objRegistrationForm.ContactFaxCountryCode !== ""
                        ? objRegistrationForm.ContactFaxCountryCode
                        : "Country"
                    }
                    disabled
                  >
                    Country Code
                  </MenuItem>
                  {listFaxCode &&
                    listFaxCode.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <TextInput
                placeholder="Fax Number"
                style={{ width: "100%", paddingLeft: 20 }}
                value={objRegistrationForm.ContactFaxNumber}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactFaxNumber = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
            <TextInput
              placeholder="Nick Name"
              value={objRegistrationForm.ContactNickName}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactNickName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              id="ContactLastName"
              className="field-required"
              placeholder="Last Name"
              value={objRegistrationForm.ContactLastName}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactLastName = e.target.value;
                setObjRegistrationForm(obj);
              }}
            />
            <TextInput
              id="ContactEmail"
              className="field-required"
              placeholder="Email Address"
              type="email"
              value={objRegistrationForm.ContactEmail}
              inputProps={{ maxLength: 241 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ContactEmail = e.target.value;

                // var validRegex = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                var validRegex =
                  /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_.]+@[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-]+(?:\.[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-]+)*$/;
                if (obj.ContactEmail.match(validRegex)) {
                  setEmailValidation(false);
                } else {
                  setEmailValidation(true);
                }
                setObjRegistrationForm(obj);
              }}
              error={emailValidation}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <FormControl
                className="field-required"
                sx={{ minWidth: 180 }}
                size="small"
              >
                <Select
                  value={
                    objRegistrationForm.ContactPhoneCountryCode !== ""
                      ? objRegistrationForm.ContactPhoneCountryCode
                      : "Country"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.ContactPhoneCountryCode = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  sx={
                    objRegistrationForm.ContactPhoneCountryCode
                      ? { color: "black" }
                      : { color: "#c3c3c3" }
                  }
                >
                  <MenuItem
                    value={
                      objRegistrationForm.ContactPhoneCountryCode !== ""
                        ? objRegistrationForm.ContactPhoneCountryCode
                        : "Country"
                    }
                    disabled
                  >
                    Country Code
                  </MenuItem>
                  {listPhoneCode &&
                    listPhoneCode.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <TextInput
                className="field-required"
                placeholder="Phone Number"
                style={{ width: "100%", paddingLeft: 20 }}
                value={objRegistrationForm.ContactPhoneNumber}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.ContactPhoneNumber = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>

      {/* Product Type Of Interest */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            Product Type Of Interest
          </Typography>
        </Box>
        {/*  row 1*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Interested Product Type as Customer
            </Typography>
            <Box sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Crude"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedCrudeAsCustomer}
                value={objRegistrationForm.InterestedCrudeAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedCrudeAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={"Petroleum Products (UG95, UG91, etc.)"}
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedPetroleumAsCustomer}
                value={objRegistrationForm.InterestedPetroleumAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedPetroleumAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Petrochemical Products (Paraxylene etc.)"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedPetrochemicalAsCustomer}
                value={objRegistrationForm.InterestedPetrochemicalAsCustomer}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedPetrochemicalAsCustomer = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label={"Lube Base Product"}
                  sx={{ width: "50%" }}
                  checked={objRegistrationForm.InterestedLubeBaseAsCustomer}
                  value={objRegistrationForm.InterestedLubeBaseAsCustomer}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLubeBaseAsCustomer = e.target.checked;
                    if (!obj.InterestedLubeBaseAsCustomer) {
                      obj.InterestedLubeBaseAsCustomerDesc = "";
                    }
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  className={
                    objRegistrationForm.InterestedLubeBaseAsCustomer
                      ? "field-required"
                      : ""
                  }
                  placeholder="Please specify product name"
                  value={objRegistrationForm.InterestedLubeBaseAsCustomerDesc}
                  inputProps={{ maxLength: 160 }}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLubeBaseAsCustomerDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  disabled={!objRegistrationForm.InterestedLubeBaseAsCustomer}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="LABIX Products"
                  sx={{ width: "50%" }}
                  checked={objRegistrationForm.InterestedLABIXAsCustomer}
                  value={objRegistrationForm.InterestedLABIXAsCustomer}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLABIXAsCustomer = e.target.checked;
                    setObjRegistrationForm(obj);
                    if (!obj.InterestedLABIXAsCustomer) {
                      obj.InterestedLABIXAsCustomerDesc = "";
                    }
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  className={
                    objRegistrationForm.InterestedLABIXAsCustomer
                      ? "field-required"
                      : ""
                  }
                  placeholder="Please specify product name"
                  value={objRegistrationForm.InterestedLABIXAsCustomerDesc}
                  inputProps={{ maxLength: 160 }}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedLABIXAsCustomerDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  disabled={!objRegistrationForm.InterestedLABIXAsCustomer}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Other Products"
                  sx={{ width: "50%" }}
                  checked={objRegistrationForm.InterestedOtherAsCustomer}
                  value={objRegistrationForm.InterestedOtherAsCustomer}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsCustomer = e.target.checked;
                    if (!obj.InterestedOtherAsCustomer) {
                      obj.InterestedOtherAsCustomerDesc = "";
                    }
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  className={
                    objRegistrationForm.InterestedOtherAsCustomer
                      ? "field-required"
                      : ""
                  }
                  placeholder="Please specify product name"
                  value={objRegistrationForm.InterestedOtherAsCustomerDesc}
                  inputProps={{ maxLength: 160 }}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsCustomerDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  disabled={!objRegistrationForm.InterestedOtherAsCustomer}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Interested Product Type as Suppiler
            </Typography>
            <Box sx={{ width: "100%" }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Crude"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedCrudeAsSupplier}
                value={objRegistrationForm.InterestedCrudeAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedCrudeAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="B100/Ethanol"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedB100EthanolAsSupplier}
                value={objRegistrationForm.InterestedB100EthanolAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedB100EthanolAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Normal Paraffin"
                sx={{ width: "100%" }}
                checked={objRegistrationForm.InterestedNormalParaffinAsSupplier}
                value={objRegistrationForm.InterestedNormalParaffinAsSupplier}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.InterestedNormalParaffinAsSupplier = e.target.checked;
                  setObjRegistrationForm(obj);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Petroleum and Component"
                  sx={{ width: "100%" }}
                  checked={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier
                  }
                  value={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedPetroleumAndComponentAsSupplier =
                      e.target.checked;
                    if (!obj.InterestedPetroleumAndComponentAsSupplier) {
                      obj.InterestedPetroleumAndComponentAsSupplierDesc = "";
                    }
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  className={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplier
                      ? "field-required"
                      : ""
                  }
                  placeholder="Please specify Petroleum and Component"
                  value={
                    objRegistrationForm.InterestedPetroleumAndComponentAsSupplierDesc
                  }
                  inputProps={{ maxLength: 160 }}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedPetroleumAndComponentAsSupplierDesc =
                      e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  disabled={
                    !objRegistrationForm.InterestedPetroleumAndComponentAsSupplier
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  pt: 1,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Other Products"
                  sx={{ width: "100%" }}
                  checked={objRegistrationForm.InterestedOtherAsSupplier}
                  value={objRegistrationForm.InterestedOtherAsSupplier}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsSupplier = e.target.checked;
                    if (!obj.InterestedOtherAsSupplier) {
                      obj.InterestedOtherAsSupplierDesc = "";
                    }
                    setObjRegistrationForm(obj);
                  }}
                />
                <TextInput
                  sx={{ width: "100%" }}
                  className={
                    objRegistrationForm.InterestedOtherAsSupplier
                      ? "field-required"
                      : ""
                  }
                  placeholder="Please specify other products"
                  value={objRegistrationForm.InterestedOtherAsSupplierDesc}
                  inputProps={{ maxLength: 160 }}
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.InterestedOtherAsSupplierDesc = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                  disabled={!objRegistrationForm.InterestedOtherAsSupplier}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* row4 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {customerActive && <abbr className="slds-required">*</abbr>}
              Customer Type
            </Typography>
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className={customerActive ? "field-required" : ""}
            >
              <Select
                id="CustomerType"
                value={
                  objRegistrationForm.CustomerType !== ""
                    ? objRegistrationForm.CustomerType
                    : "CustomerType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.CustomerType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.CustomerType !== ""
                      ? objRegistrationForm.CustomerType
                      : "CustomerType"
                  }
                  disabled
                >
                  Select Customer Type
                </MenuItem>
                {listCustomerType &&
                  listCustomerType.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {supplierActive && <abbr className="slds-required">*</abbr>}
              Supplier Type
            </Typography>

            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
              className={supplierActive ? "field-required" : ""}
            >
              <Select
                value={
                  objRegistrationForm.SupplierType !== ""
                    ? objRegistrationForm.SupplierType
                    : "SupplierType"
                }
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.SupplierType = e.target.value;
                  setObjRegistrationForm(obj);
                }}
              >
                <MenuItem
                  value={
                    objRegistrationForm.SupplierType !== ""
                      ? objRegistrationForm.SupplierType
                      : "SupplierType"
                  }
                  disabled
                >
                  Select Supplier Type
                </MenuItem>
                {listSupplierType &&
                  listSupplierType.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/*  row 2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              p: 1,
              m: 1,
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">
              Estimate monthly purchase per grade
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextInput
                style={{ width: "100%" }}
                placeholder="Number of resgistration issues"
                value={objRegistrationForm.EstimatedMonthlyPurchaseQuantity}
                onChange={(event) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.EstimatedMonthlyPurchaseQuantity =
                    event.target.value.replace(/[^0-9]/g, "");
                  setObjRegistrationForm(obj);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              &nbsp;
              <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                  value={
                    objRegistrationForm.EstimatedMonthlyPurchaseUnit !== ""
                      ? objRegistrationForm.EstimatedMonthlyPurchaseUnit
                      : "EstimatedMonthlyPurchaseUnit"
                  }
                  onChange={(e) => {
                    let obj = Object.assign({}, objRegistrationForm);
                    obj.EstimatedMonthlyPurchaseUnit = e.target.value;
                    setObjRegistrationForm(obj);
                  }}
                >
                  <MenuItem
                    value={
                      objRegistrationForm.EstimatedMonthlyPurchaseUnit !== ""
                        ? objRegistrationForm.EstimatedMonthlyPurchaseUnit
                        : ""
                    }
                    disabled
                  >
                    Unit
                  </MenuItem>
                  {listEstimateMonthly &&
                    listEstimateMonthly.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              Tentative Starting Trading Date
            </Typography>
            <DateTimePicker
              defaultValue={dayjs(objRegistrationForm.StartTradingDate)}
              value={dayjs(objRegistrationForm.StartTradingDate)}
              minDate={dayjs()}
              format="DD/MM/YYYY"
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.StartTradingDate = dayjs(e).format("YYYY-MM-DD");
                setObjRegistrationForm(obj);
              }}
            />
          </Box>
        </Box>
        {/* row 3 table */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {customerActive && <abbr className="slds-required">*</abbr>}
              Destination of the Product (Ship-To Country){" "}
              <Tooltip
                title="Provide the destination country details if you are registered as a customer."
                placement="top"
              >
                <ErrorIcon className="help-text" sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  dis
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">No.</TableCell> */}
                      <TableCell align="left">Country</TableCell>
                      {/* <TableCell align="center">Location Type</TableCell> */}
                      <TableCell align="right">Delete</TableCell>
                      {/* <TableCell align="center">Actions</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objRegistrationForm.DestinationCountries?.length > 0 &&
                      objRegistrationForm.DestinationCountries.map(
                        (item, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {/* <TableCell
                                align="center"
                                component="th"
                                scope="row"
                                sx={{ width: "5%" }}
                              >
                                {index + 1}
                              </TableCell> */}
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                sx={{ width: "50%" }}
                              >
                                <FormControl
                                  sx={{ minWidth: "100%" }}
                                  size="small"
                                  disabled={!customerActive}
                                >
                                  <Select
                                    value={
                                      item.Country !== ""
                                        ? item.Country
                                        : "Country"
                                    }
                                    onChange={(e) => {
                                      const destinationCT = [
                                        ...objRegistrationForm.DestinationCountries,
                                      ];
                                      destinationCT[index].Country =
                                        e.target.value;
                                      setObjRegistrationForm({
                                        ...objRegistrationForm,
                                        DestinationCountries: destinationCT,
                                      });
                                      // let obj = Object.assign(
                                      //   {},
                                      //   objRegistrationForm
                                      // );
                                      // let destinationCT =
                                      //   objRegistrationForm.DestinationCountries.map(
                                      //     (val) => {
                                      //       if (val.Id === item.Id) {
                                      //         val.Country = e.target.value;
                                      //       }
                                      //       return val;
                                      //     }
                                      //   );
                                      // obj.DestinationCountries = destinationCT;
                                      // setObjRegistrationForm(obj);
                                    }}
                                  >
                                    <MenuItem
                                      value={
                                        item.Country !== ""
                                          ? item.Country
                                          : "Country"
                                      }
                                    >
                                      Select Country
                                    </MenuItem>
                                    {listShippingCountry &&
                                      listShippingCountry.map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              {/* <TableCell align="center">
                                <FormControl
                                  sx={{ minWidth: "100%" }}
                                  size="small"
                                  disabled={!customerActive}
                                >
                                  <Select
                                    value={
                                      item.LocationType !== ""
                                        ? item.LocationType
                                        : "Select Location Type"
                                    }
                                    onChange={(e) => {
                                      const destinationCT = [
                                        ...objRegistrationForm.DestinationCountries,
                                      ];
                                      destinationCT[index].LocationType =
                                        e.target.value;
                                      setObjRegistrationForm({
                                        ...objRegistrationForm,
                                        DestinationCountries: destinationCT,
                                      });
                                      // let obj = Object.assign(
                                      //   {},
                                      //   objRegistrationForm
                                      // );
                                      // let destinationCT =
                                      //   objRegistrationForm.DestinationCountries.map(
                                      //     (val) => {
                                      //       if (val.Id === item.Id) {
                                      //         val.LocationType = e.target.value;
                                      //       }
                                      //       return val;
                                      //     }
                                      //   );
                                      // obj.DestinationCountries = destinationCT;
                                      // setObjRegistrationForm(obj);
                                    }}
                                  >
                                    <MenuItem
                                      value={
                                        item.LocationType !== ""
                                          ? item.LocationType
                                          : "Select Location Type"
                                      }
                                      disabled
                                    >
                                      Select Location Type
                                    </MenuItem>
                                    {listLocationType &&
                                      listLocationType.map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </FormControl>
                              </TableCell> */}
                              <TableCell align="right" sx={{ width: "50%" }}>
                                <HighlightOffIcon
                                  style={{
                                    cursor: customerActive
                                      ? "pointer"
                                      : "no-drop",
                                  }}
                                  onClick={() => {
                                    if (customerActive) {
                                      let obj = Object.assign(
                                        {},
                                        objRegistrationForm
                                      );
                                      obj.DestinationCountries =
                                        objRegistrationForm.DestinationCountries.filter(
                                          (val, idx) => idx !== index
                                        );
                                      // console.log(obj.DestinationCountries);
                                      // if (
                                      //   obj.DestinationCountries.length === 0
                                      // ) {
                                      //   obj.DestinationCountries = [
                                      //     {
                                      //       Id: "",
                                      //       Country: "",
                                      //       LocationType: "",
                                      //     },
                                      //   ];
                                      // }
                                      setObjRegistrationForm(obj);
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}

                    {objRegistrationForm.DestinationCountries.length === 0 && (
                      <TableRow>
                        {/* <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ width: "5%" }}
                        >
                          1
                        </TableCell> */}
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          sx={{ width: "50%" }}
                        >
                          <FormControl
                            sx={{ minWidth: "100%" }}
                            size="small"
                            disabled={!customerActive}
                          >
                            <Select
                              value=""
                              onChange={(e) => {
                                const destinationCT = [
                                  {
                                    ...objRegistrationForm
                                      .DestinationCountries[0],
                                    Country: e.target.value,
                                  },
                                ];
                                setObjRegistrationForm({
                                  ...objRegistrationForm,
                                  DestinationCountries: destinationCT,
                                });
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Country
                              </MenuItem>
                              {listShippingCountry &&
                                listShippingCountry.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        {/* <TableCell align="center">
                          <FormControl
                            sx={{ minWidth: "100%" }}
                            size="small"
                            disabled={!customerActive}
                          >
                            <Select
                              value=""
                              onChange={(e) => {
                                const destinationCT = [
                                  {
                                    ...objRegistrationForm
                                      .DestinationCountries[0],
                                    Country: e.target.value,
                                  },
                                ];
                                setObjRegistrationForm({
                                  ...objRegistrationForm,
                                  DestinationCountries: destinationCT,
                                });
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Location Type
                              </MenuItem>
                              {listLocationType &&
                                listLocationType.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </TableCell> */}
                        <TableCell
                          align="right"
                          sx={{ width: "50%" }}
                        ></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* add row */}
              {customerActive ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  {objRegistrationForm.DestinationCountries?.length > 0 &&
                    objRegistrationForm.DestinationCountries.map(
                      (item, index) => {
                        return (
                          <div key={index}>
                            {objRegistrationForm.DestinationCountries.length -
                              1 ===
                            index ? (
                              <>
                                {objRegistrationForm.DestinationCountries
                                  .length === 1 ? (
                                  <>
                                    <Box
                                      sx={{
                                        ml: 1,
                                        height: 35,
                                        visibility: "hidden",
                                      }}
                                    />
                                    <AddCircleOutlineIcon
                                      color="success"
                                      sx={{
                                        cursor: "pointer",
                                        ml: 1,
                                        height: 55,
                                      }}
                                      onClick={() => {
                                        let obj = Object.assign(
                                          {},
                                          objRegistrationForm
                                        );
                                        if (
                                          obj.DestinationCountries[
                                            obj.DestinationCountries.length - 1
                                          ].Country !== "" &&
                                          obj.DestinationCountries[
                                            obj.DestinationCountries.length - 1
                                          ].LocationType !== ""
                                        ) {
                                          obj.DestinationCountries.push({
                                            Id: "",
                                            Country: "",
                                            LocationType: "",
                                          });
                                          setObjRegistrationForm(obj);
                                        }
                                      }}
                                    />
                                  </>
                                ) : (
                                  <AddCircleOutlineIcon
                                    key={index}
                                    color="success"
                                    sx={{
                                      cursor: "pointer",
                                      ml: 1,
                                      height: 55,
                                    }}
                                    onClick={() => {
                                      let obj = Object.assign(
                                        {},
                                        objRegistrationForm
                                      );
                                      if (
                                        obj.DestinationCountries[
                                          obj.DestinationCountries.length - 1
                                        ].Country !== "" &&
                                        obj.DestinationCountries[
                                          obj.DestinationCountries.length - 1
                                        ].LocationType !== ""
                                      ) {
                                        obj.DestinationCountries.push({
                                          Id: "",
                                          Country: "",
                                          LocationType: "",
                                        });
                                        setObjRegistrationForm(obj);
                                      }
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {index === 0 ? (
                                  <Box
                                    sx={{
                                      ml: 1,
                                      height: 85,
                                      visibility: "hidden",
                                    }}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      ml: 1,
                                      height: 55,
                                      visibility: "hidden",
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        );
                      }
                    )}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {/* row 4  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              p: 1,
              m: 1,
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">
              {/* {customerActive && <abbr className="slds-required">*</abbr>} */}
              Have you purchased this product from another source? If yes,
              please specify where. (If no, leave blank)
              <Tooltip
                title="(For Customer) If a customer buys a product from more than one company, use a semicolon (;) before inputting the next company."
                placement="top"
              >
                <ErrorIcon className="help-text" sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <TextInput
              placeholder="(For Customer) Which company do customers purchase this product from?"
              value={objRegistrationForm.PurchaseProductFromCompany}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.PurchaseProductFromCompany = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={!customerActive}
            />
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {/* {supplierActive && <abbr className="slds-required">*</abbr>} */}
              Have you supply this product to others?
              <Tooltip
                title="(For Supplier) If a supplier sells a product from more than one company, use a semicolon(;) before inputting the next company."
                placement="top"
              >
                <ErrorIcon className="help-text" sx={{ color: "#595959" }} />
              </Tooltip>
            </Typography>
            <TextInput
              placeholder="(For Supplier) Which company do you supply this product to? (If any)"
              value={objRegistrationForm.SupplyProductToCompany}
              inputProps={{ maxLength: 160 }}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.SupplyProductToCompany = e.target.value;
                setObjRegistrationForm(obj);
              }}
              disabled={!supplierActive}
            />
          </Box>
        </Box>
        {/* row 5 checkbox tank storage */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {customerActive && <abbr className="slds-required">*</abbr>}
              Customer’s Facility (Tank and Storage)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControl
                id="CustomersFacilityTankAndStorage"
                className="facility"
                value={objRegistrationForm.CustomersFacilityTankAndStorage}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.CustomersFacilityTankAndStorage = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              >
                <RadioGroup
                  row
                  value={objRegistrationForm.CustomersFacilityTankAndStorage}
                >
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Own storage facility"
                    control={<Radio />}
                    label="Own storage facility"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent storage facility for one-year contract or more"
                    control={<Radio />}
                    label="Rent storage facility for one-year contract or more"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent storage facility less than one-year contract"
                    control={<Radio />}
                    label="Rent storage facility less than one-year contract"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="No storage facility"
                    control={<Radio />}
                    label="No storage facility"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {supplierActive && <abbr className="slds-required">*</abbr>}
              Supplier's Facility (Tank and Storage)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControl
                className="facility"
                value={objRegistrationForm.SuppliersFacilityTankAndStorage}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.SuppliersFacilityTankAndStorage = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              >
                <RadioGroup
                  row
                  value={objRegistrationForm.SuppliersFacilityTankAndStorage}
                >
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Own storage facility"
                    control={<Radio />}
                    label="Own storage facility"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent storage facility for one-year contract or more"
                    control={<Radio />}
                    label="Rent storage facility for one-year contract or more"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent storage facility less than one-year contract"
                    control={<Radio />}
                    label="Rent storage facility less than one-year contract"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="No storage facility"
                    control={<Radio />}
                    label="No storage facility"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {customerActive && <abbr className="slds-required">*</abbr>}
              Customer’s Facility (Transportation)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControl
                className="facility"
                value={objRegistrationForm.CustomersFacilityTransportation}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.CustomersFacilityTransportation = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                disabled={!customerActive}
              >
                <RadioGroup
                  row
                  value={objRegistrationForm.CustomersFacilityTransportation}
                >
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Own transportation"
                    control={<Radio />}
                    label="Own transportation"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent transportation for one-year contract or more"
                    control={<Radio />}
                    label="Rent transportation for one-year contract or more"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent transportation less than one-year contract"
                    control={<Radio />}
                    label="Rent transportation less than one-year contract"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="No transportation"
                    control={<Radio />}
                    label="No transportation"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {supplierActive && <abbr className="slds-required">*</abbr>}
              Supplier Facility (Transportation)
            </Typography>
            <Box sx={{ pl: 1, ml: 1, width: "100%" }}>
              <FormControl
                className="facility"
                value={objRegistrationForm.SuppliersFacilityTransportation}
                onChange={(e) => {
                  let obj = Object.assign({}, objRegistrationForm);
                  obj.SuppliersFacilityTransportation = e.target.value;
                  setObjRegistrationForm(obj);
                }}
                disabled={!supplierActive}
              >
                <RadioGroup
                  row
                  value={objRegistrationForm.SuppliersFacilityTransportation}
                >
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Own transportation"
                    control={<Radio />}
                    label="Own transportation"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent transportation for one-year contract or more"
                    control={<Radio />}
                    label="Rent transportation for one-year contract or more"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="Rent transportation less than one-year contract"
                    control={<Radio />}
                    label="Rent transportation less than one-year contract"
                  />
                  <FormControlLabel
                    sx={{ width: 150 }}
                    value="No transportation"
                    control={<Radio />}
                    label="No transportation"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </CardContent>

      {/* Product Type Of Interest */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1">
            ESG and Compliance
          </Typography>
        </Box>
        {/* row */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              <b>Enviorment: </b> Does your company have Environment policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.EnvironmentPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.EnvironmentPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.EnvironmentPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  sx={{ width: 150 }}
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              <b>Social Responsibility: </b>Does your company have Social
              Responsibility policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.SocialResponsibilityPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.SocialResponsibilityPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.SocialResponsibilityPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              <b>Governance: </b>Does your company have Corporate Governance
              policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.CorporateGovernancePolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.CorporateGovernancePolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.CorporateGovernancePolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              <b>Occupational Health and Safety: </b>Does your company have
              Occupational Health and Safety policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.OccupationalHealthAndSafetyPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.OccupationalHealthAndSafetyPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.OccupationalHealthAndSafetyPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              <b>Human Rights: </b>Does your company have Human Rights policy?
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.HumanRightsPolicy}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.HumanRightsPolicy = e.target.value;
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                row
                value={objRegistrationForm.HumanRightsPolicy}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {/* Has 1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Has your company or any of its affiliates, or any current
              director, officer, or key employee ever been charged with, or
              investigated for fraud, misrepresentation, corruption, bribery,
              money laundering, forced labour, environmental issues or other
              related activities during the past five years?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.ViolatingAntiMoneyLaunderingLaws}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.ViolatingAntiMoneyLaunderingLaws = e.target.value;
                if (obj.ViolatingAntiMoneyLaunderingLaws === "No") {
                  obj.ReasonOfViolatingAntiMoneyLaunderingLaws = "";
                }
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                id="ViolatingAntiMoneyLaunderingLaws"
                row
                value={objRegistrationForm.ViolatingAntiMoneyLaunderingLaws}
                name="radio-group-transection-cc1"
                // sx={{ justifyContent: "right" }}
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            id="ReasonOfViolatingAntiMoneyLaunderingLaws"
            sx={{ width: "95%" }}
            placeholder="Reason Of Violating AntiMoney Laundering Laws"
            value={objRegistrationForm.ReasonOfViolatingAntiMoneyLaunderingLaws}
            inputProps={{ maxLength: 255 }}
            className={
              objRegistrationForm.ViolatingAntiMoneyLaunderingLaws === "Yes"
                ? "field-required"
                : ""
            }
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfViolatingAntiMoneyLaunderingLaws = e.target.value;
              setObjRegistrationForm(obj);
            }}
            disabled={
              objRegistrationForm.ViolatingAntiMoneyLaunderingLaws !== "Yes"
            }
          />
        </Box>
        {/* USD 2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {customerActive && <abbr className="slds-required">*</abbr>}
              (For Customer) Has your company ever had any default payment over
              the amount of USD 50,000 in the last 3 years?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.PaymentOverTheAmountOfUSD}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.PaymentOverTheAmountOfUSD = e.target.value;
                if (obj.PaymentOverTheAmountOfUSD === "No") {
                  obj.ReasonOfPaymentOverTheAmountOfUSD = "";
                }
                setObjRegistrationForm(obj);
              }}
              disabled={!customerActive}
            >
              <RadioGroup
                id="PaymentOverTheAmountOfUSD"
                row
                value={objRegistrationForm.PaymentOverTheAmountOfUSD}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            id="ReasonOfPaymentOverTheAmountOfUSD"
            sx={{ width: "95%" }}
            placeholder="Reason Of Payment Over The Amount Of USD"
            value={objRegistrationForm.ReasonOfPaymentOverTheAmountOfUSD}
            inputProps={{ maxLength: 255 }}
            className={
              customerActive &&
              objRegistrationForm.PaymentOverTheAmountOfUSD === "Yes"
                ? "field-required"
                : ""
            }
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfPaymentOverTheAmountOfUSD = e.target.value;
              setObjRegistrationForm(obj);
            }}
            disabled={
              !customerActive ||
              objRegistrationForm.PaymentOverTheAmountOfUSD !== "Yes"
            }
          />
        </Box>
        {/* Failed 1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              {supplierActive && <abbr className="slds-required">*</abbr>}
              (For Supplier) Has your company ever failed to supply under any
              agreement in the last 3 years? (Please specify the reason of the
              default)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.FailedSupplyAgreement}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.FailedSupplyAgreement = e.target.value;
                if (obj.FailedSupplyAgreement === "No") {
                  obj.ReasonOfFailedSupplyAgreement = "";
                }
                setObjRegistrationForm(obj);
              }}
              disabled={!supplierActive}
            >
              <RadioGroup
                id="FailedSupplyAgreement"
                row
                value={objRegistrationForm.FailedSupplyAgreement}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            id="ReasonOfFailedSupplyAgreement"
            sx={{ width: "95%" }}
            placeholder="Reason Of Failed Supply Agreement"
            value={objRegistrationForm.ReasonOfFailedSupplyAgreement}
            inputProps={{ maxLength: 255 }}
            className={
              supplierActive &&
              objRegistrationForm.FailedSupplyAgreement === "Yes"
                ? "field-required"
                : ""
            }
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfFailedSupplyAgreement = e.target.value;
              setObjRegistrationForm(obj);
            }}
            disabled={
              !supplierActive ||
              objRegistrationForm.FailedSupplyAgreement !== "Yes"
            }
          />
        </Box>
        {/* Failed 1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              Does your company have any offices, transactions, investments,
              activities or planned activities in sanctioned targets or any
              transactions, investments, activities or planned activities with
              (or in) any person or entity located in sanctioned targets or with
              (or in) any entity owned or controlled by any entity located in
              sanctioned targets*?
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: 10 }}>
              *A sanctioned target means any party that is a target of U.S., EU,
              UK or UN sanctions or to your knowledge, any other sanctions
              authority relevant to your company such as SDN list (Please
              specify the reason of the default)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            <Typography variant="subtitle1">&nbsp;</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl
              value={objRegistrationForm.HaveTagetsInSanction}
              onChange={(e) => {
                let obj = Object.assign({}, objRegistrationForm);
                obj.HaveTagetsInSanction = e.target.value;
                if (obj.HaveTagetsInSanction === "No") {
                  obj.ReasonOfHaveTagetsInSanction = "";
                }
                setObjRegistrationForm(obj);
              }}
            >
              <RadioGroup
                id="HaveTagetsInSanction"
                row
                value={objRegistrationForm.HaveTagetsInSanction}
                name="radio-group-transection-cc1"
              >
                <FormControlLabel
                  value="Yes"
                  sx={{ width: 150 }}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 10, fontWeight: "bold" }}
          >
            (Please specify the reason of the default)
          </Typography>
          <TextInput
            id="ReasonOfHaveTagetsInSanction"
            sx={{ width: "95%" }}
            placeholder="Reason Of Have Targets in Sanction"
            value={objRegistrationForm.ReasonOfHaveTagetsInSanction}
            inputProps={{ maxLength: 255 }}
            className={
              objRegistrationForm.HaveTagetsInSanction === "Yes"
                ? "field-required"
                : ""
            }
            onChange={(e) => {
              let obj = Object.assign({}, objRegistrationForm);
              obj.ReasonOfHaveTagetsInSanction = e.target.value;
              setObjRegistrationForm(obj);
            }}
            disabled={objRegistrationForm.HaveTagetsInSanction !== "Yes"}
          />
        </Box>
      </CardContent>

      {/* visible submit update btn */}
      <button
        id="check-form-data-register"
        style={{
          visibility: "hidden",
        }}
        onClick={() => {
          let checksRequired = isValidate();
          if (checksRequired.active) {
            setRequired({
              fields: checksRequired.fields,
              active: checksRequired.active,
            });
          } else {
            props.formDataSave(objRegistrationForm);
          }
        }}
      >
        submit
      </button>
      <button
        id="check-form-data-register-save-draft"
        style={{
          visibility: "hidden",
        }}
        onClick={() => {
          props.formDataDraft(objRegistrationForm);
        }}
      >
        update
      </button>

      <Snackbar
        style={{ width: "50%" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={required.active}
        autoHideDuration={6000}
        onClose={() => {
          setRequired({
            fields: "",
            active: false,
          });
        }}
      >
        <Alert
          onClose={() => {
            setRequired({
              fields: "",
              active: false,
            });
          }}
          variant="filled"
          severity="warning"
        >
          Required Fields <b> {required.fields}</b>
        </Alert>
      </Snackbar>
    </Card>
  );
}
