import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

export default function KYC(props) {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Box sx={{ width: "100%", p: 1 }}>
            <Box sx={{ backgroundColor: "#dbd9d9" }}>
              <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                Question List of Petrochemical Products
              </Typography>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                1. KYC Questions
              </Typography>
              <FormControl sx={{ ml: 2 }}>
                <RadioGroup defaultValue="female" name="radio-group-kyc1">
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                2. KYC Questions
              </Typography>
              <FormControl sx={{ ml: 2 }}>
                <RadioGroup defaultValue="female" name="radio-group-kyc2">
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ width: "100%", p: 1 }}>
            <Box sx={{ backgroundColor: "#dbd9d9" }}>
              <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
                Question List of Lube Base Products
              </Typography>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                1. KYC Questions
              </Typography>
              <FormControl sx={{ ml: 2 }}>
                <RadioGroup defaultValue="female" name="radio-group-kyc3">
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ p: 1, m: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                2. KYC Questions
              </Typography>
              <FormControl sx={{ ml: 2 }}>
                <RadioGroup defaultValue="female" name="radio-group-kyc4">
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
