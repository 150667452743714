import * as React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

export default function IdentificationForm(props) {
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    props.submitForm(data);
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Card sx={{ width: "50%" }}>
        <CardContent>
          <Box sx={{ backgroundColor: "#d8dfe8", m: 1 }}>
            <Typography
              sx={{ m: 1, fontWeight: "bold" }}
              variant="subtitle1"
              gutterBottom
            >
              Identification
            </Typography>
          </Box>
        </CardContent>
        <CardContent sx={{ textIndent: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            <form
              className="form-login"
              style={{
                width: "95%",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className="slds-form-element"
                style={{
                  width: "95%",
                }}
              >
                <label htmlFor="requestNumber" style={{ fontWeight: "bold" }}>
                  Request Number
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="requestNumber"
                    name="requestNumber"
                    placeholder="Request Number"
                    className="slds-input"
                    required
                    {...register("requestNumber")}
                  />
                </div>
              </div>
              <br />
              <div
                className="slds-form-element"
                style={{
                  width: "95%",
                }}
              >
                <label htmlFor="customerNumber" style={{ fontWeight: "bold" }}>
                  Customer/Supplier Register Number
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="customerNumber"
                    name="customerNumber"
                    placeholder="Customer/Supplier Register Number"
                    className="slds-input"
                    required
                    {...register("customerNumber")}
                  />
                </div>
              </div>
              <div>
                <br />
                <button
                  className="slds-button slds-button_brand slds-size_1-of-1"
                  style={{
                    backgroundColor: "#c20acc",
                    borderColor: "#c20acc",
                    margin: 20,
                    width: "95%",
                    height: 40,
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
