import * as React from "react";
import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack
} from "@mui/material";
import ExtensionStepper from "../components/Extension/ExtensionStepper";
import ExtensionForm from "../components/Extension/ExtensionForm";
import RequiredDocument from "../components/Extension/RequiredDocument";
import ModalExtensionForm from "../components/Extension/Modal/ModalExtensionForm";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "../styles/Extension.css";

import {
  AccountAPI,
  ExtensionFormAPI,
  SaleForceUploadFile,
  SharePointFolder,
  RequestFormAPI,
} from "../services/apigateway";
import helper from "../services/helper";

// const steps = ["Extension Form", "Document Submission"];
const steps = ["Extension Form"];

export default function ExtensionScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(location.state?.extension?.Step || 0);
  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [showTableFirstYear, setShowTableFirstYear] = React.useState(false);
  const [showTableSecondYear, setShowTableSecondYear] = React.useState(false);
  const [showTableThirdYear, setShowTableThirdYear] = React.useState(false);
  const [showTableCOI, setShowTableCOI] = React.useState(false);
  const [showTableMOA, setShowTableMOA] = React.useState(false);
  const [showTableVAT, setShowTableVAT] = React.useState(false);
  const [showTableShareholder, setShowTableShareholder] = React.useState(false);
  const [showTableMoE, setShowTableMoE] = React.useState(false);
  const [showTableOther, setShowTableOther] = React.useState(false);

  const [pathsSharePoint, setPathsSharePoint] = React.useState([]);
  React.useEffect(() => {
    setLoading(true);

    // Call your RequestForm function here
    const fetchData = async () => {
      try {
        const userInfo = helper.Auth.getTokenData();
        if (userInfo.accounts[0]) {
          const response = await SharePointFolder.getPathByAccount(
            userInfo.accounts[0].Id
          );
          const filesSharePoint = response.responseData;

          const fin = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Financial"
          );
          const coi = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_COI"
          );
          const moa = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_MOA"
          );
          const shr = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_List_Sharehold"
          );
          const vat = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_VAT_Reg"
          );
          const moe = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Reg_by_MoE"
          );
          const oth = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Others"
          );

          setPathsSharePoint({
            Account_Common_CTR_Financial: fin.Path || "",
            Account_Common_CTR_COI: coi.Path || "",
            Account_Common_CTR_MOA: moa.Path || "",
            Account_Common_CTR_List_Sharehold: shr.Path || "",
            Account_Common_CTR_VAT_Reg: vat.Path || "",
            Account_Common_CTR_Reg_by_MoE: moe.Path || "",
            Account_Common_CTR_Others: oth.Path || "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const uploadFileToSaleForceAttachment = async (file) => {
    try {
      let CustomerId = formData.CustomerId;
      const uploadResponse = await SaleForceUploadFile.uploadFile(CustomerId, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToFinancial = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/01 Financial Statement";
      const namefolder = pathsSharePoint.Account_Common_CTR_Financial;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToCOI = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/02 COI";
      const namefolder = pathsSharePoint.Account_Common_CTR_COI;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToMOA = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/03 MOA";
      const namefolder = pathsSharePoint.Account_Common_CTR_MOA;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToVAT = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/05 VAT Registration";
      const namefolder = pathsSharePoint.Account_Common_CTR_VAT_Reg;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToShareholders = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/04 List of Shareholder";
      const namefolder = pathsSharePoint.Account_Common_CTR_List_Sharehold;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadToFileToMoE = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/06 Registration by MoE";
      const namefolder = pathsSharePoint.Account_Common_CTR_Reg_by_MoE;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadToFileToOther = async (file) => {
    try {
      // const namefolder = "Shared Documents/Account/Moke/Common/02 CTR/07 Other Registration Files";
      const namefolder = pathsSharePoint.Account_Common_CTR_Others;
      const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
        file,
      ]);
      console.log("Upload response:", uploadResponse);
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFiles = async () => {
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FinancialSecond = document.getElementById("FinancialSecond");
      const FinancialThird = document.getElementById("FinancialThird");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");
      const FileMoE = document.getElementById("FileMoE");
      const FileOther = document.getElementById("FileOther2");

      const selectedFilesFirst = FinancialFirst.files[0];
      const selectedFilesSecond = FinancialSecond.files[0];
      const selectedFilesThird = FinancialThird.files[0];
      const selectedFilesCOI = FileCOI.files[0];
      const selectedFilesMOA = FileMOA.files[0];
      const selectedFilesVAT = FileVAT.files[0];
      const selectedFileMoE = FileMoE.files[0];
      const selectedFileShareholder = FileShareholder.files[0];
      const selectedFileOther = FileOther.files;

      if (FinancialFirst.files[0]) {
        let typeFilesFS1 = FinancialFirst.files[0].name.split(".").pop();
        const selectedFilesFS1_SF = new File(
          [FinancialFirst.files[0]],
          "FS1." + typeFilesFS1,
          { type: FinancialFirst.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS1_SF);
      }

      if (selectedFilesSecond.files[0]) {
        let typeFilesFS2 = selectedFilesSecond.files[0].name.split(".").pop();
        const selectedFilesFS2_SF = new File(
          [selectedFilesSecond.files[0]],
          "FS2." + typeFilesFS2,
          { type: selectedFilesSecond.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS2_SF);
      }

      if (selectedFilesThird.files[0]) {
        let typeFilesFS3 = selectedFilesThird.files[0].name.split(".").pop();
        const selectedFilesFS3_SF = new File(
          [selectedFilesThird.files[0]],
          "FS3." + typeFilesFS3,
          { type: selectedFilesThird.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS3_SF);
      }

      if (FileCOI.files[0]) {
        let typeFilesCOI = FileCOI.files[0].name.split(".").pop();
        const selectedFilesCOI_SF = new File(
          [FileCOI.files[0]],
          "COI." + typeFilesCOI,
          { type: FileCOI.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesCOI_SF);
      }

      if (FileMOA.files[0]) {
        let typeFilesMOA = FileMOA.files[0].name.split(".").pop();
        const selectedFilesMOA_SF = new File(
          [FileMOA.files[0]],
          "MOA." + typeFilesMOA,
          { type: FileMOA.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesMOA_SF);
      }

      if (FileVAT.files[0]) {
        let typeFilesVAT = FileVAT.files[0].name.split(".").pop();

        const selectedFilesVAT_SF = new File(
          [FileVAT.files[0]],
          "VAT." + typeFilesVAT,
          { type: FileVAT.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesVAT_SF);
      }

      if (FileMoE.files[0]) {
        let typeFilesMoE = FileMoE.files[0].name.split(".").pop();
        const selectedFileMoE_SF = new File(
          [FileMoE.files[0]],
          "MOE." + typeFilesMoE,
          { type: FileMoE.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFileMoE_SF);
      }

      await uploadFileToFinancial(selectedFilesFirst);
      await uploadFileToFinancial(selectedFilesSecond);
      await uploadFileToFinancial(selectedFilesThird);
      await uploadFileToCOI(selectedFilesCOI);
      await uploadFileToMOA(selectedFilesMOA);
      await uploadFileToShareholders(selectedFileShareholder);
      await uploadFileToVAT(selectedFilesVAT);
      await uploadToFileToMoE(selectedFileMoE);
      for (const file of selectedFileOther) {
        await uploadToFileToOther(file);
      }

      FinancialFirst.value = "";
      FinancialSecond.value = "";
      FinancialThird.value = "";
      FileCOI.value = "";
      FileMOA.value = "";
      FileVAT.value = "";
      FileShareholder.value = "";
      FileMoE.value = "";
      FileOther.value = "";

      setShowTableFirstYear(false);
      setShowTableSecondYear(false);
      setShowTableThirdYear(false);
      setShowTableCOI(false);
      setShowTableMOA(false);
      setShowTableVAT(false);
      setShowTableShareholder(false);
      setShowTableMoE(false);
      setShowTableOther(false);
    } catch (error) {
      console.error("Error in handling file upload:", error);
    }
  };

  const updateFiles = async () => {
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FinancialSecond = document.getElementById("FinancialSecond");
      const FinancialThird = document.getElementById("FinancialThird");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");
      const FileMoE = document.getElementById("FileMoE");
      const FileOther = document.getElementById("FileOther2");

      const selectedFilesFirst = FinancialFirst.files[0];
      const selectedFilesSecond = FinancialSecond.files[0];
      const selectedFilesThird = FinancialThird.files[0];
      const selectedFilesCOI = FileCOI.files[0];
      const selectedFilesMOA = FileMOA.files[0];
      const selectedFilesVAT = FileVAT.files[0];
      const selectedFileShareholder = FileShareholder.files[0];
      const selectedFileMoE = FileMoE.files[0];
      const selectedFileOther = FileOther.files;

      if (FinancialFirst.files[0]) {
        let typeFilesFS1 = FinancialFirst.files[0].name.split(".").pop();
        const selectedFilesFS1_SF = new File(
          [FinancialFirst.files[0]],
          "FS1." + typeFilesFS1,
          { type: FinancialFirst.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS1_SF);
      }

      if (selectedFilesSecond.files[0]) {
        let typeFilesFS2 = selectedFilesSecond.files[0].name.split(".").pop();
        const selectedFilesFS2_SF = new File(
          [selectedFilesSecond.files[0]],
          "FS2." + typeFilesFS2,
          { type: selectedFilesSecond.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS2_SF);
      }

      if (selectedFilesThird.files[0]) {
        let typeFilesFS3 = selectedFilesThird.files[0].name.split(".").pop();
        const selectedFilesFS3_SF = new File(
          [selectedFilesThird.files[0]],
          "FS3." + typeFilesFS3,
          { type: selectedFilesThird.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesFS3_SF);
      }

      if (FileCOI.files[0]) {
        let typeFilesCOI = FileCOI.files[0].name.split(".").pop();
        const selectedFilesCOI_SF = new File(
          [FileCOI.files[0]],
          "COI." + typeFilesCOI,
          { type: FileCOI.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesCOI_SF);
      }

      if (FileMOA.files[0]) {
        let typeFilesMOA = FileMOA.files[0].name.split(".").pop();
        const selectedFilesMOA_SF = new File(
          [FileMOA.files[0]],
          "MOA." + typeFilesMOA,
          { type: FileMOA.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesMOA_SF);
      }

      if (FileVAT.files[0]) {
        let typeFilesVAT = FileVAT.files[0].name.split(".").pop();

        const selectedFilesVAT_SF = new File(
          [FileVAT.files[0]],
          "VAT." + typeFilesVAT,
          { type: FileVAT.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFilesVAT_SF);
      }

      if (FileMoE.files[0]) {
        let typeFilesMoE = FileMoE.files[0].name.split(".").pop();
        const selectedFileMoE_SF = new File(
          [FileMoE.files[0]],
          "MOE." + typeFilesMoE,
          { type: FileMoE.files[0].type }
        );
        await uploadFileToSaleForceAttachment(selectedFileMoE_SF);
      }

      await uploadFileToFinancial(selectedFilesFirst);
      await uploadFileToFinancial(selectedFilesSecond);
      await uploadFileToFinancial(selectedFilesThird);
      await uploadFileToCOI(selectedFilesCOI);
      await uploadFileToMOA(selectedFilesMOA);
      await uploadFileToShareholders(selectedFileShareholder);
      await uploadFileToVAT(selectedFilesVAT);
      await uploadToFileToMoE(selectedFileMoE);

      for (const file of selectedFileOther) {
        await uploadToFileToOther(file);
      }

      FinancialFirst.value = "";
      FinancialSecond.value = "";
      FinancialThird.value = "";
      FileCOI.value = "";
      FileMOA.value = "";
      FileVAT.value = "";
      FileShareholder.value = "";
      FileMoE.value = "";
      FileOther.value = "";

      setShowTableFirstYear(false);
      setShowTableSecondYear(false);
      setShowTableThirdYear(false);
      setShowTableCOI(false);
      setShowTableMOA(false);
      setShowTableVAT(false);
      setShowTableShareholder(false);
      setShowTableMoE(false);
      setShowTableOther(false);
    } catch (error) {
      console.error("Error in handling file upload:", error);
    }
  };

  const renderFormPreScreening = (page) => {
    switch (page) {
      case 0:
        return (
          <ExtensionForm
            account={account}
            formData={formData}
            formDataSave={(data) => submitRequestFormByIds(data)}
            formDataDraft={(data) => updateRequestFormByIds(data)}
            formId={id}
            loading={(e) => setLoading(e)}
            onChange={handleChange}
          />
        );
      case 1:
        return (
          <RequiredDocument
            formData={formData}
            formId={formData.RequestFormHeaderId}
            formDataSave={(data) => submitRequiredDocument(data)}
            formDataDraft={(data) => updateRequiredDocument(data)}
            account={account}
            showTableFirstYear={showTableFirstYear}
            setShowTableFirstYear={setShowTableFirstYear}
            showTableSecondYear={showTableSecondYear}
            setShowTableSecondYear={setShowTableSecondYear}
            showTableThirdYear={showTableThirdYear}
            setShowTableThirdYear={setShowTableThirdYear}
            showTableCOI={showTableCOI}
            setShowTableCOI={setShowTableCOI}
            showTableMOA={showTableMOA}
            setShowTableMOA={setShowTableMOA}
            showTableVAT={showTableVAT}
            setShowTableVAT={setShowTableVAT}
            showTableShareholder={showTableShareholder}
            setShowTableShareholder={setShowTableShareholder}
            showTableMoE={showTableMoE}
            setShowTableMoE={setShowTableMoE}
            showTableOther={showTableOther}
            setShowTableOther={setShowTableOther}
            onChange={handleChange}
          />
        );
      default:
        return <></>;
    }
  };

  const { accountId, id } = useParams();
  const [account, setAccount] = React.useState({});
  const [formData, setFormData] = React.useState({
    RequestFormHeaderId: "",
    CustomerId: "",
    RegistrationNumber: "",
    CompanyNameEN: "",
    CompanyNameLocal: "",
    CompanyNameLanguage: "",
    CompanyNameFormer: "",
    CustomerType: "",
    SupplierType: "",
    BusinessType: "",
    BusinessTypeOthers: "",
    LegalEntityType: "",
    LegalEntityTypeOthers: "",
    IncorporationDate: "",
    YearsOfExperience: "",
    TAXNumber: "",
    Website: "",
    MinistryOfEnergyRegistrationNo: "",
    HouseNo: "",
    Building: "",
    Room: "",
    Floor: "",
    Street: "",
    SubDistrict: "",
    District: "",
    City: "",
    Country: "",
    PostalCode: "",
    LocationURL: "",
    Latitude: "",
    Longitude: "",
    MailingIsSameAsRegistered: "",
    MailingHouseNo: "",
    MailingBuilding: "",
    MailingRoom: "",
    MailingFloor: "",
    MailingStreet: "",
    MailingSubDistrict: "",
    MailingDistrict: "",
    MailingCity: "",
    MailingCountry: "",
    MailingPostalCode: "",
    ContactSalutation: "",
    ContactNickName: "",
    ContactFirstName: "",
    ContactLastName: "",
    ContactPosition: "",
    ContactEmail: "",
    ContactMobileCountryCode: "",
    ContactMobileNumber: "",
    ContactPhoneCountryCode: "",
    ContactPhoneNumber: "",
    ContactFaxCountryCode: "",
    ContactFaxNumber: "",
    InterestedPetroleumAsCustomer: false,
    InterestedPetrochemicalAsCustomer: false,
    InterestedLubeBaseAsCustomer: false,
    InterestedLABIXAsCustomer: false,
    InterestedCrudeAsCustomer: false,
    InterestedOtherAsCustomer: false,
    InterestedLubeBaseAsCustomerDesc: "",
    InterestedLABIXAsCustomerDesc: "",
    InterestedOtherAsCustomerDesc: "",
    InterestedCrudeAsSupplier: false,
    InterestedB100EthanolAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplier: false,
    InterestedLongResidueAsSupplier: false,
    InterestedNormalParaffinAsSupplier: false,
    InterestedOtherAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplierDesc: "",
    InterestedOtherAsSupplierDesc: "",
    EstimatedMonthlyPurchaseQuantity: "",
    EstimatedMonthlyPurchaseUnit: "",
    StartTradingDate: "",
    DestinationCountries: [],
    RequestItems: [],
    Shareholders: [],
    Documents: {
      FinancialStatement: {
        FirstYear: "",
        SecondYear: "",
        ThirdYear: "",
      },
      CertificateOfIncorporation: "",
      MemorandumOfAssociation: "",
      CertificateOfVATRegistration: "",
      ListOfShareholders: "",
      RegistrationIssuedByMinistryOfEnergy: "",
      Others: [],
    },
    PurchaseProductFromCompany: "",
    SupplyProductToCompany: "",
    OwnStorageAsCustomer: false,
    RentStorageMoreThanOneYearAsCustomer: false,
    RentStorageLessThanOneYearAsCustomer: false,
    NoStorageAsCustomer: false,
    OwnTransportAsCustomer: false,
    RentTransportMoreThanOneYearAsCustomer: false,
    RentTransportLessThanOneYearAsCustomer: false,
    NoTransportAsCustomer: false,
    OwnStorageAsSupplier: false,
    RentStorageMoreThanOneYearAsSupplier: false,
    RentStorageLessThanOneYearAsSupplier: false,
    NoStorageAsSupplier: false,
    OwnTransportAsSupplier: false,
    RentTransportMoreThanOneYearAsSupplier: false,
    RentTransportLessThanOneYearAsSupplier: false,
    NoTransportAsSupplier: false,
    EnvironmentPolicy: "",
    SocialResponsibilityPolicy: "",
    CorporateGovernancePolicy: "",
    OccupationalHealthAndSafetyPolicy: "",
    HumanRightsPolicy: "",
    ViolatingAntiMoneyLaunderingLaws: "",
    ReasonOfViolatingAntiMoneyLaunderingLaws: "",
    PaymentOverTheAmountOfUSD: "",
    ReasonOfPaymentOverTheAmountOfUSD: "",
    FailedSupplyAgreement: "",
    ReasonOfFailedSupplyAgreement: "",
    HaveTagetsInSanction: "",
    ReasonOfHaveTagetsInSanction: "",
    CEOFirstName: "",
    CEOLastName: "",
    ImmidiateParentCompany: "",
    UltimateParentCompany: "",
    MainService: "",
    OtherMainService: "",
    MainProduct: "",
    OtherMainProduct: "",
    CompanyLatestCreditRating: "",
  });

  React.useEffect(() => {
    const loadAccount = AccountAPI.findAll();
    const loadRequest = RequestFormAPI.findAll();

    if (id) {
      setLoading(true);
      ExtensionFormAPI.getById(id).then((request) => {
        setFormData(request);
        return request;
      }).finally(() => {
        setLoading(false);
      });
    } else {
      loadRequest.then((requests) => {
        const request = requests.find((item) => {
          return item.CustomerId === accountId && item.RequestType === "Extend" && item.Status === "Draft";
        });

        if (request) {
          navigate(`/extend/edit/${request.Id}`);
        }

        loadAccount.then((accounts) => {
          const account = accounts.find((item) => {
            return item.CustomerId === accountId;
          });
  
          setAccount(account);
  
          account.InterestedCrudeAsCustomer = false;
          account.InterestedPetroleumAsCustomer = false;
          account.InterestedPetrochemicalAsCustomer = false;
          account.InterestedLABIXAsCustomer = false;
          account.InterestedLABIXAsCustomerDesc = "";
          account.InterestedLubeBaseAsCustomer = false;
          account.InterestedLubeBaseAsCustomerDesc = "";
          account.InterestedOtherAsCustomer = false;
          account.InterestedOtherAsCustomerDesc = "";
          account.InterestedCrudeAsSupplier = false;
          account.InterestedB100EthanolAsSupplier = false;
          account.InterestedPetroleumAndComponentAsSupplier = false;
          account.InterestedPetroleumAndComponentAsSupplierDesc = "";
          account.InterestedLongResidueAsSupplier = false;
          account.InterestedNormalParaffinAsSupplier = false;
          account.InterestedOtherAsSupplier = false;
          account.InterestedOtherAsSupplierDesc = "";
  
          setFormData({
            ...account,
          });
          return account;
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [accountId, id, navigate]);

  function handleChange(e, change) {
    setFormData({ ...formData, ...change });
  }

  const updateRequestFormByIds = async (data) => {
    let dataUpdate = data;    
      if (document.getElementById("extension-action").dataset.action === "Next") {
        Swal.fire({
          title: "Do you want to proceed?",
          icon: "question",
          position: "center",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          cancelButtonColor: "red",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const res = await ExtensionFormAPI.update(dataUpdate);
            console.log("updateRequestForm :: ", res);
            if (res.error_description) {
              const res = await ExtensionFormAPI.create(dataUpdate);
              console.log("create form :: ExtensionFormAPI for create => ", res);
              if (res) {
                setFormData(res);
                Swal.fire({
                  title: "Saved!",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setActiveStep(1);
                navigate(`/extend/${res.RequestFormHeaderId}`);
              }
            } else {
              setFormData(res);
              Swal.fire({
                title: "Saved!",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              setActiveStep(1);
            }
            setLoading(false);
          }
          setLoading(false);
        });
      } else {
        Swal.fire({
          title: "Do you want to save draft form?",
          icon: "question",
          position: "center",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          cancelButtonColor: "red",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const res = await ExtensionFormAPI.update(dataUpdate);
            console.log("updateRequestForm :: ", res);
            if (res.error_description) {
              setLoading(true);
              const res = await ExtensionFormAPI.create(dataUpdate);
              console.log("create form :: ExtensionFormAPI for create => ", res);
              if (res) {
                setFormData(res);
                if (res.error_description) {
                  Swal.fire({
                    title: res.error_description,
                    icon: "error",
                    showConfirmButton: true,
                  });
                } else {
                  Swal.fire({
                    title: "Saved!",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  navigate("/main");
                }
              }
            } else {
              setFormData(res);
              if (res.error_description) {
                Swal.fire({
                  title: res.error_description,
                  icon: "error",
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  title: "Saved!",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
            setLoading(false);
          }
          setLoading(false);
        });
      }
  };

  const submitRequestFormByIds = async (data) => {
    let dataFinal = data;
    if (dataFinal) {
        Swal.fire({
          title: "Do you want to submit form?",
          icon: "question",
          position: "center",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          cancelButtonColor: "red",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const res = await ExtensionFormAPI.submit(dataFinal);
            if (res) {
              console.log("submitRequestForm :: ", res);
              setLoading(false);

              if (res.error_description) {
                Swal.fire({
                  title: res.error_description,
                  icon: "error",
                  showConfirmButton: true,
                });
              } else {
                setFormData(res);
                // setRequestId(res.RequestFormHeaderId);
                // setActiveStep(activeStep + 1);
                setRegistrationNumber(res.RegistrationNumber);
                setOpenModal(true);

                // navigate("/main");
              }
            }
          }
          setLoading(false);
        });
        setLoading(false);
    } else {
      document.getElementById("check-form-data-register").click();
    }
  };

  let formSubmitted = false;
  const submitRequiredDocument = async (data) => {
    setLoading(true);
    let dataFinal = data;

    if (dataFinal && !formSubmitted) {
      const res = await ExtensionFormAPI.submit(dataFinal);
      if (res) {
        setLoading(false);

        // Set the flag to true after successful submission
        formSubmitted = true;
      }
      setLoading(false);
    }

    if (!formSubmitted) {
      document.getElementById("check-form-data-register").click();
    }
  };

  const handleUploadFiles = async function (e) {
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");

      if (
        (
          FinancialFirst.files.length > 0 ||
          formData.Documents?.FinancialStatement.FirstYear
        ) && (
          FileCOI.files.length > 0 ||
          formData.Documents?.CertificateOfIncorporation
        ) && (
          FileMOA.files.length > 0 ||
          formData.Documents?.MemorandumOfAssociation
        ) && (
          FileVAT.files.length > 0 ||
          formData.Documents?.CertificateOfVATRegistration
        ) && (
          FileShareholder.files.length > 0 ||
          formData.Documents?.ListOfShareholders
        )
      ) {
        Swal.fire({
          title: "Do you want to submit form?",
          icon: "question",
          position: "center",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          cancelButtonColor: "red",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            await uploadFiles();
            document.getElementById("check-form-data-register").click();
          }
        });
      }
    } catch (error) {
      console.error("Error in handling form submission:", error);
    }
  };

  let formUpdateFiles = false;
  const updateRequiredDocument = async (data) => {
    let dataUpdate = data;
    if (dataUpdate && !formUpdateFiles) {
      const res = await ExtensionFormAPI.update(dataUpdate);
      if (!res.error_description) {
        setLoading(false);

        // Set the flag to true after successful submission
        formUpdateFiles = true;
      } else {
        console.error(res.error_description);
      }
    }

    if (!formUpdateFiles) {
      document
        .getElementById("check-form-data-register-save-draft")
        .click();
    }
  };

  const handleUpdateFiles = async function (e) {
    try {
      const FinancialFirst = document.getElementById("FinancialFirst");
      const FileCOI = document.getElementById("FileCOI");
      const FileMOA = document.getElementById("FileMOA");
      const FileVAT = document.getElementById("FileVAT");
      const FileShareholder = document.getElementById("FileShareholder");

      if (
        (
          FinancialFirst.files.length > 0 ||
          formData.Documents?.FinancialStatement.FirstYear
        ) && (
          FileCOI.files.length > 0 ||
          formData.Documents?.CertificateOfIncorporation
        ) && (
          FileMOA.files.length > 0 ||
          formData.Documents?.MemorandumOfAssociation
        ) && (
          FileVAT.files.length > 0 ||
          formData.Documents?.CertificateOfVATRegistration
        ) && (
          FileShareholder.files.length > 0 ||
          formData.Documents?.ListOfShareholders
        )
      ) {
        Swal.fire({
          title: "Do you want to save draft form?",
          icon: "question",
          position: "center",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          cancelButtonColor: "red",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            await updateFiles();
            document
              .getElementById("check-form-data-register-save-draft")
              .click();
            setLoading(false);

            Swal.fire({
              title: "Saved!",
              icon: "success",
              showConfirmButton: false,
              timer: 1200,
            });
          }
        });
      }
    } catch (error) {
      console.error("Error in handling form submission:", error);
    }
  };

  return (
    <div class="ExtensionScreen">
      <div id="extension-action" data-action="" ></div>
      <Stack sx={{ width: "100%", marginTop: 5 }} spacing={4}>
        <Box sx={{ pl: 25, pr: 25 }}>
          <ExtensionStepper steps={steps} activeStep={activeStep} />
        </Box>
        <Box>{renderFormPreScreening(activeStep)}</Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: 2
              }}
              open={true}
            >
              <span
                style={{
                  justifyContent: "center",
                  position: "fixed",
                  top: "40%",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    m: 2,
                    justifyContent: "center",
                    display: "flex"
                  }}
                >
                  <CircularProgress
                    style={{
                      width: 100,
                      height: 100,
                      color: "rgba(184,0,200,1)",
                    }}
                  />
                </Box>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <label style={{ fontSize: 22, fontWeight: "bold" }}>
                    {" "}
                    Please wait...
                  </label>
                </Box>
              </span>
            </Backdrop>
          )}
          <Button
            sx={{ p: 1, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => {
              Swal.fire({
                title: "Do you want to leave this page?",
                icon: "question",
                position: "center",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/main");
                }
              });
            }}
          >
            Cancel
          </Button>
          {activeStep >= 0 ? (
            <Button
              sx={{ p: 1, m: 1 }}
              variant="outlined"
              disabled={loading}
              onClick={() => {
                document.getElementById("check-form-data-register-save-draft").click();
              }}
            >
              Save
            </Button>
          ) : null}
          <Button
            sx={{ p: 1, m: 1 }}
            variant="contained"
            disabled={loading}
            onClick={() => {
              document.getElementById("check-form-data-register").click();
            }}
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Stack>

      <ModalExtensionForm
        request={registrationNumber}
        open={openModal}
        onHide={() => setOpenModal(false)}
      />
    </div>
  );
}
